import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { Button, Heading, Link, Table, TableRow, Icons } from '@skf/ferris';
import { GetEntityUrl, GetTranslationIdForEntityCategory, GetTraslationIdForEntityCategoryPlural } from 'utils';
import { RelationGearbox } from 'domain/relationships/ObjectTurbineShareDetails';
import { useNavigate, useParams } from 'react-router';
import _ from 'lodash';
import { fromGearboxId } from '../../../../domain/shared/ObjectId';
import { stateToColor, stateToStyle } from '../entity-state';
import { SetNumberFieldErrors, SetRequiredError } from '../utils/validation/Validation.utility';
import GearboxDialog from './dialog/GearboxDialog';
import { Gearbox } from 'domain/index';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store';
import { setGearbox } from 'features/edit/edit-gearbox/store/gearbox-slice';
import { EntityState } from '../entity-state';
import GearboxTable from './table/GearboxTable';
import WindTurbineDialog from '../wind-turbine/dialog/WindTurbineDialog';

const GearboxParts = ({ relations, editMode, entityId, onGearboxChange }: Props) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { changesetId } = useParams();
  const [formIsOpen, setFormIsOpen] = useState<boolean>(false);
  const [gearboxToEdit, setGearboxToEdit] = useState<RelationGearbox>({} as RelationGearbox);
  const [indexToEdit, setIndexToEdit] = useState<number | null>(null);
  const [formError, setFormError] = useState<Map<string, string>>(new Map());
  const dispatch = useDispatch<AppDispatch>();

  const handleFormChange = (value: RelationGearbox) => {
    setGearboxToEdit((prev) => {
      return {
        ...prev,
        turbineId: entityId,
        objectId: value.objectId,
        gearboxName: value.gearbox.gearboxName,
        manufacturer: { manufacturerName: value.gearbox.manufacturer.manufacturerName },
        remark: value.remark,
        marketShare: value.marketShare,
        gearbox: value.gearbox
      };
    });
    // setFormError((prev) => {
    //     let errors = SetNumberFieldErrors(value.marketShare, 'marketShare', Number.MAX_SAFE_INTEGER, prev, intl);
    //     errors = SetRequiredError(value.objectId, 'objectId', errors, intl);
    //     return errors;
    // })
  };

  const getMetadata = (gearboxToEdit: RelationGearbox) => {
    if (gearboxToEdit.metadata) {
      if (gearboxToEdit.metadata.state === EntityState.NOT_MODIFIED) {
        return { state: EntityState.MODIFIED_UNSAVED };
      } else if (gearboxToEdit.metadata.state === EntityState.MODIFIED) {
        return { state: EntityState.MODIFIED_UNSAVED };
      } else if (gearboxToEdit.metadata.state === EntityState.NEW) {
        return { state: EntityState.MODIFIED_UNSAVED };
      } else {
        return gearboxToEdit.metadata;
      }
    } else {
      return { state: EntityState.NEW_UNSAVED };
    }
  };

  return (
    <>
      <Heading as="h2">{`${intl.formatMessage({ id: GetTranslationIdForEntityCategory('Gearbox') })}`}</Heading>
      <GearboxTable
        editMode={editMode}
        onDeleteGearbox={(gearbox: RelationGearbox, index: number) => onGearboxChange(gearbox, index, 'Delete')}
        onEditGearbox={(gearbox: RelationGearbox, index: number) => {
          setGearboxToEdit(gearbox);
          setIndexToEdit(index);
          setFormIsOpen(true);
        }}
        gearboxes={relations}
      />

      {editMode && (
        <div className="w-full flex justify-end">
          <Button
            className="ml-auto mr-0"
            feType="secondary"
            feIcon={{ feIcon: Icons.Plus, position: 'left' }}
            onClick={() => {
              setGearboxToEdit({ gearbox: { manufacturer: {} } } as RelationGearbox);
              setIndexToEdit(null);
              setFormIsOpen(true);
              setFormError(SetRequiredError(undefined, 'objectId', new Map(), intl));
            }}
          >
            {intl.formatMessage({ id: 'windTurbine.button.add' })}
          </Button>
        </div>
      )}
      <GearboxDialog
        gearboxToEdit={gearboxToEdit}
        isOpen={formIsOpen}
        onFormChange={handleFormChange}
        formError={formError}
        onSave={() => {
          onGearboxChange(
            {
              ...gearboxToEdit,
              metadata: getMetadata(gearboxToEdit)
            },
            indexToEdit,
            indexToEdit !== null ? 'Edit' : 'Add'
          );
          setFormIsOpen(false);
        }}
        onCancel={() => {
          setFormIsOpen(false);
          setGearboxToEdit({ gearbox: { manufacturer: {} } } as RelationGearbox);
          setFormError(new Map());
          setIndexToEdit(null);
        }}
      />
    </>
  );
};

interface Props {
  relations: RelationGearbox[];
  editMode: boolean;
  entityId?: any;
  onGearboxChange?: any;
  //   onGearboxChange: any;
}

export default GearboxParts;
