import { RecordCategory } from 'domain/index';
import { ObjectType } from '../../../domain/shared/ObjectId';

export const getObjectTypeFromRecordCategory = (category: RecordCategory | ObjectType): { objectType: ObjectType; productCategory: string | undefined } => {
  return {
    objectType: getObjectType(category),
    productCategory: getProductCategoryFromRecordCategory(category)
  };
};

export const getObjectType = (category: RecordCategory | ObjectType): ObjectType => {
  if (getProductCategoryFromRecordCategory(category)) {
    return ObjectType.PRODUCT;
  } else {
    return category as ObjectType;
  }
};

const getProductCategoryFromRecordCategory = (category: RecordCategory | ObjectType): string | undefined => {
  switch (category) {
    case RecordCategory.DriveBearing:
    case RecordCategory.Bearing:
    case RecordCategory.RotorBearing:
    case RecordCategory.TowerBearing:
      return 'Bearing';
    case RecordCategory.DriveGrease:
    case RecordCategory.Grease:
    case RecordCategory.RotorGrease:
    case RecordCategory.TowerGrease:
      return 'Grease';
    case RecordCategory.DriveHousing:
      return 'Housing';
    case RecordCategory.GeneratorLubricationSystem:
    case RecordCategory.DriveLubricationSystem:
    case RecordCategory.RotorLubricationSystem:
    case RecordCategory.TowerLubricationSystem:
      return 'Lubrication System';
    case RecordCategory.DriveSeal:
    case RecordCategory.RotorSeal:
    case RecordCategory.Seal:
    case RecordCategory.TowerSeal:
      return 'Seal';
    case RecordCategory.GearboxCoupling:
      return 'Coupling';
    default:
      return undefined;
  }
};
