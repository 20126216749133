import { Loader } from '@skf/ferris';
import { ChangesetIsActive } from 'utils/index';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch, getChangesets, useAppSelector } from 'store/index';
import MainNavigation from './MainNavigation';

const TicketNavigation = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { changesets, loading, error } = useAppSelector((state) => state.changesetData);

  useEffect(() => {
    dispatch(getChangesets(true));
  }, [dispatch]);

  if (error) {
    return <p>Failed to load changesets, please try again</p>;
  } else if (loading) {
    return <Loader />;
  }

  return <MainNavigation changesets={changesets.filter((x) => ChangesetIsActive(x.status))}></MainNavigation>;
};

export default TicketNavigation;
