export enum EntityState {
  MODIFIED = 'MODIFIED',
  NOT_MODIFIED = 'NOT_MODIFIED',
  NEW = 'NEW',
  DELETED = 'DELETED',

  // Internal states to the client
  NEW_UNSAVED = 'NEW_UNSAVED',
  MODIFIED_UNSAVED = 'MODIFIED_UNSAVED',
  DELETED_UNSAVED = 'DELETED_UNSAVED'
}

const stateAsUnsaved = (state: EntityState): EntityState => {
  switch (state) {
    case EntityState.MODIFIED_UNSAVED:
    case EntityState.NOT_MODIFIED:
    case EntityState.MODIFIED:
      return EntityState.MODIFIED_UNSAVED;
    case EntityState.NEW_UNSAVED:
    case EntityState.NEW:
      return EntityState.NEW_UNSAVED;
    case EntityState.DELETED_UNSAVED:
    case EntityState.DELETED:
      return EntityState.DELETED_UNSAVED;
    default:
      return state;
  }
};

const stateAsSaved = (state: EntityState): EntityState => {
  switch (state) {
    case EntityState.MODIFIED_UNSAVED:
      return EntityState.MODIFIED;
    case EntityState.NEW_UNSAVED:
      return EntityState.NEW;
    case EntityState.DELETED_UNSAVED:
      return EntityState.DELETED;
    default:
      return state;
  }
};

const stateToColor = (state: EntityState | undefined, defaultColor: string = ''): string => {
  switch (state) {
    case EntityState.NEW:
      return 'bg-green-base';
    case EntityState.NEW_UNSAVED:
      return 'bg-green-base opacity-50';
    case EntityState.MODIFIED:
      return 'bg-yellow-base';
    case EntityState.MODIFIED_UNSAVED:
      return 'bg-yellow-base opacity-50';
    case EntityState.DELETED:
      return 'bg-red-base';
    case EntityState.DELETED_UNSAVED:
      return 'bg-red-base opacity-50';
    case EntityState.NOT_MODIFIED:
    default:
      return defaultColor;
  }
};

const isDeleted = (state?: EntityState) => (state ? [EntityState.DELETED_UNSAVED, EntityState.DELETED].includes(state) : false);

const stateToStyle = (tdStyle: string, state?: EntityState) => `${tdStyle} ${isDeleted(state) ? 'opacity-30' : ''}`;

export { stateToColor, stateAsSaved, stateAsUnsaved, isDeleted, stateToStyle };
