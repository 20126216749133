import { Icon, Icons, IconSizes } from '@skf/ferris';
import { useState } from 'react';

type FilterCollapseProps = {
  label: string;
  defaultExpanded?: boolean;
  children: React.ReactNode;
  hide?: boolean;
};

const FilterCollapse = ({ label, defaultExpanded, children, hide }: FilterCollapseProps) => {
  const [isExpanded, setExpanded] = useState(defaultExpanded ?? false);

  return !hide ? (
    <div className="border-b-2 ">
      <h3 className="flex font-bold flex-row w-full h-10 items-center justify-between cursor-pointer" onClick={() => setExpanded(!isExpanded)}>
        <div>{label}</div>
        <Icon feIcon={isExpanded ? Icons.CaretUp : Icons.CaretDown} feSize={IconSizes.Lg} />
      </h3>
      {isExpanded ? <div className="p-2">{children}</div> : null}
    </div>
  ) : null;
};

export default FilterCollapse;
