import { MultiChoiceFilter } from 'features/search/domain/SearchQuery';
import { MultiChoiceFacet } from 'features/search/domain/SearchResult';
import { useFilterLabel } from 'features/search/hooks/useFilterLabel';
import useValueTranslation from 'features/search/hooks/useValueTranslation';
import { setKeywordValue, removeKeywordValue } from 'features/search/store';
import { useAppDispatch } from 'store';
import { SearchFacetProps } from '../SearchFilter';
import FilterCollapse from './FilterCollapse';
import SwitchFilter from './SwitchFilter';

const SearchMultiValueAndFacet = ({ facet }: SearchFacetProps<MultiChoiceFacet, MultiChoiceFilter>) => {
  const dispatch = useAppDispatch();
  const label = useFilterLabel(facet.field);
  const translateValue = useValueTranslation(facet.field);
  const values = facet.values.map((v) => ({ ...v, label: translateValue(v.value) }));

  const onSelectKeyword = (value: string, checked: boolean) => {
    if (checked) {
      dispatch(setKeywordValue({ facet, value }));
    } else {
      dispatch(removeKeywordValue({ facet, value }));
    }
  };

  return (
    <FilterCollapse hide={facet.values.length === 0} label={label}>
      <SwitchFilter values={values} isSelected={(value) => facet.selected.includes(value)} onSelect={onSelectKeyword} />
    </FilterCollapse>
  );
};

export default SearchMultiValueAndFacet;
