import axios, { AxiosResponse } from 'axios';

const client = axios.create({
  baseURL: process.env.REACT_APP_API,
  headers: {
    'x-api-key': process.env.REACT_APP_API_KEY ?? ''
  }
});

export const WakeUpComponentAPI = (): Promise<AxiosResponse<void>> => client.options(`components`, {});

export const WakeUpChangesetAPI = (): Promise<AxiosResponse<void>> => client.options(`changesets`, {});
