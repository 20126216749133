import { useIntl } from 'react-intl';

export const useFilterLabel = (field: string): string => {
  const intl = useIntl();

  return intl.formatMessage({ id: `search.filter.${field}.header` });
};

export const useGetFilterLabel = (): ((field: string) => string) => {
  const intl = useIntl();

  return (field: string) => intl.formatMessage({ id: `search.filter.${field}.header` });
};

export const useBoolFilterValueFilter = () => {
  const intl = useIntl();

  return (field: string, value: boolean | undefined): string => intl.formatMessage({ id: `search.filter.${field}.values.${value === undefined ? 'undefined' : value.toString()}` });
};
