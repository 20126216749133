import { Card, Heading, Loader, Spacer, Tabs, useToast } from '@skf/ferris';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { AppDispatch, useAppSelector } from 'store/index';
import { GetEntityUrl, GetTranslationIdForEntityCategory, ObjectComparison, ProductPartsComparison, TurbineShareDetailsComparison } from 'utils/index';
import { Gearbox, PositionsCategory, ProductCategory, RecordCategory, RelationshipProduct, RelationTurbine, State } from 'domain/index';
import {
  ExistsInChangesetInfo,
  FormActions,
  getChangesetDetails,
  GetHasChangesOnParts,
  getRelatedComponents,
  Header,
  ListOfParts,
  LogPosts,
  RemovedOverlay,
  resetChangesetDetailsState,
  resetPositionsState,
  resetProductParts,
  resetProductPartsState,
  SetNumberFieldErrors,
  setProductParts,
  setProductPartsMetadataStateAsSaved,
  SetRequiredError,
  SetStringMaxErrors,
  Sketch,
  WindTurbine
} from '../shared/index';
import { deleteGearbox, editInTicket, getGearbox, resetGearboxState, resetWarning, saveChanges, setEditMode, setGearbox } from './store/gearbox-slice';
import GearboxForm from './form/GearboxForm';
import { getLog, resetLogState } from '../shared';
import { getRelatedTurbines, resetRelationTurbinesState, resetTurbines, setTurbines } from '../shared/store/relation-turbines';
import _ from 'lodash';
import EntityStateIndicator from '../shared/entity-state/EntityStateIndicator';
import { fromGearboxId } from '../../../domain/shared/ObjectId';
import EditWarnings from '../shared/edit-errors/EditWarnings';

const EditGearbox = ({ newMode, setLoadForms }: Props) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams();
  const gearboxId = Number(id);
  const { changesetId } = useParams();
  const [searchParams] = useSearchParams();
  const { gearbox, gearboxOriginal, eTag, editMode, loading, initError, error, warnings } = useAppSelector((state) => state.editGearboxData);
  const { loading: loadingLogPosts, logPosts, logPostsAreInitialized, error: errorLogPosts } = useAppSelector((state) => state.logData);
  const { changesetDetails, error: errorChangesetDetails } = useAppSelector((state) => state.changesetDetailsData);
  const { loading: loadingProductParts, productParts, productPartsOriginal, productPartsAreInitialized, error: errorProductParts } = useAppSelector((state) => state.relationshipProductsData);
  const { loading: loadingTurbines, turbines, turbinesOriginal, turbinesAreInitialized, error: errorTurbines } = useAppSelector((state) => state.relationTurbinesData);
  const changesets = useAppSelector((state) => state.changesetData.changesets);
  const [formError, setFormError] = useState<Map<string, string>>(new Map());
  const currentEtag = useAppSelector((state) => state.changesetData.currentETag) || '';

  useEffect(() => {
    dispatch(resetRelationTurbinesState());
    dispatch(resetGearboxState());
    dispatch(resetLogState());
    dispatch(resetProductPartsState());
    dispatch(resetPositionsState());
    updateChangesetDetails(changesetId);
    if (!newMode) {
      dispatch(getRelatedTurbines({ type: 'Gearbox', id: gearboxId, changesetId }));
      dispatch(getGearbox({ gearboxId, changesetId: changesetId }));
    }
  }, [gearboxId, changesetId]);

  useEffect(() => {
    if (newMode) {
      dispatch(setEditMode({ editMode: true }));
    } else {
      dispatch(setEditMode({ editMode: !!searchParams.get('editMode') }));
    }
  }, [searchParams]);

  useEffect(() => {
    if (!!error) handleError(error);
    if (!!errorLogPosts) handleError(errorLogPosts);
    if (!!errorChangesetDetails) handleError(errorChangesetDetails);
    if (!!errorProductParts) handleError(errorProductParts);
    if (!!errorTurbines) handleError(errorTurbines);
  }, [error, errorLogPosts, errorProductParts, errorProductParts, errorTurbines]);

  const handleError = (message: string) => {
    addToast({ children: message, feSeverity: 'error' });
    console.log(message);
  };

  if (loading.gearbox) {
    return <Loader className="mr-auto ml-auto" />;
  } else if (initError || !gearbox) {
    return <p className="p-5">{intl.formatMessage({ id: 'editTurbinePage.error' })}</p>;
  }

  const isAutoCommit = (): boolean => (newMode ? false : !changesetId);

  const handleGearboxFormChange = (value: Gearbox) => {
    setFormError((prev) => SetRequiredError(value.gearboxName, 'gearboxName', prev, intl));
    setFormError((prev) => SetRequiredError(value.nominalPower, 'nominalPower', prev, intl));
    setFormError((prev) => SetRequiredError(value.gearboxType, 'gearboxType', prev, intl));
    setFormError((prev) => SetStringMaxErrors(value.remark, 'remark', 500, prev, intl));
    setFormError((prev) => SetRequiredError(value.manufacturerId, 'manufacturerName', prev, intl));

    setFormError((prev) => SetNumberFieldErrors(value.nominalPower, 'nominalPower', Number.MAX_SAFE_INTEGER, prev, intl));
    setFormError((prev) => SetNumberFieldErrors(value.numberOfGearSteps, 'numberOfGearSteps', Number.MAX_SAFE_INTEGER, prev, intl));
    setFormError((prev) => SetNumberFieldErrors(value.inputTorque, 'inputTorque', Number.MAX_SAFE_INTEGER, prev, intl));
    setFormError((prev) => SetNumberFieldErrors(value.gearboxRatio, 'gearboxRatio', Number.MAX_SAFE_INTEGER, prev, intl));
    setFormError((prev) => SetNumberFieldErrors(value.inputTurningSpeed, 'inputTurningSpeed', Number.MAX_SAFE_INTEGER, prev, intl));

    dispatch(setGearbox({ gearbox: value }));
  };

  const onTicketActionClick = async (changesetId: string, action: 'edit' | 'open') => {
    let currentChangesetId: string | undefined = changesetId;
    if (action === 'edit') {
      currentChangesetId = await dispatch(editInTicket({ entityId: gearbox.gearboxId, gearboxName: gearbox.gearboxName ?? '', changesetId, etag: '' })).unwrap();
    }
    navigate(GetEntityUrl(fromGearboxId(gearbox.gearboxId), currentChangesetId, action === 'edit'));
  };

  const onSave = async (event: any) => {
    const gearboxDiff = ObjectComparison(gearbox, gearboxOriginal);
    const [addTurbineDetails, updateTurbineDetails, removeTurbineDetails] = TurbineShareDetailsComparison(turbines, turbinesOriginal);
    const [addObjectDetails, updateObjectDetails, removeObjectDetails] = ProductPartsComparison(productParts, productPartsOriginal);
    const response = await dispatch(
      saveChanges({
        entityId: gearbox.gearboxId,
        changesetId,
        gearboxDiff,
        additionalChanges: { addObjectDetails, updateObjectDetails, removeObjectDetails, addTurbineDetails, updateTurbineDetails, removeTurbineDetails },
        isAutoCommit: isAutoCommit(),
        etag: newMode && changesetId ? currentEtag : eTag,
        newMode
      })
    ).unwrap();
    dispatch(setProductPartsMetadataStateAsSaved());
    if (response?.status === 'ok') {
      addToast({ children: 'Gearbox is saved successfully', feSeverity: 'success' });
      const redirectUrl = isAutoCommit() ? `/gearbox/${response.entityId}` : `/gearbox/${response.entityId}/${response.changesetId}`;
      navigate(redirectUrl);
      dispatch(getRelatedTurbines({ type: 'Gearbox', id: response.entityId, changesetId }));
    }
  };

  const onClose = (event: any) => {
    navigate(GetEntityUrl(fromGearboxId(gearbox.gearboxId)));
  };

  const updateChangesetDetails = (changesetId: string | undefined) => {
    if (changesetId) {
      dispatch(getChangesetDetails({ changesetId }));
    } else {
      dispatch(resetChangesetDetailsState());
    }
  };

  const onDelete = () => dispatch(deleteGearbox({ changesetId, gearboxId: gearbox.gearboxId, eTag }));

  const onCancelAdd = () => {
    setLoadForms(false);
  };

  return (
    <>
      <EditWarnings entityId={gearboxId} warnings={warnings} closeWarning={(warning) => dispatch(resetWarning(warning))} />
      <Card feNoPadding>
        <div className="relative">
          <RemovedOverlay show={changesetDetails?.refs.find((x) => x.id === gearbox.gearboxId)?.state === State.DELETED} />
          <div className={!changesetId ? 'p-8' : ''}>
            <Header changesetId={changesetId} objectId={fromGearboxId(gearbox.gearboxId)}></Header>
            <div className={changesetId ? 'p-8' : ''}>
              <div className="flex pl-2">
                <EntityStateIndicator state={gearbox.metadata?.state} />
                <Heading as="h1">
                  {`${intl.formatMessage({ id: GetTranslationIdForEntityCategory('Gearbox') }).toUpperCase()}`} {gearbox.gearboxName ? `- ${gearbox.gearboxName}` : <></>}
                </Heading>
              </div>
              <Spacer />
              <GearboxForm editMode={editMode} formError={formError} gearbox={gearbox} onGearboxChange={handleGearboxFormChange} changesetDetails={changesetDetails} />
              <Tabs
                onClick={(_, index) => {
                  if (index === 0 && !turbinesAreInitialized && !newMode) dispatch(getRelatedTurbines({ type: 'Gearbox', id: gearbox.gearboxId, changesetId }));
                  if (index === 2 && !productPartsAreInitialized && !newMode) dispatch(getRelatedComponents({ type: 'Gearbox', id: gearbox.gearboxId, changesetId }));
                  if (index === 3 && !logPostsAreInitialized && !newMode) dispatch(getLog({ type: 'Gearbox', id: gearbox.gearboxId }));
                }}
                className="mt-2 mb-4"
                feItems={[
                  {
                    children: (
                      <>
                        {loadingTurbines ? (
                          <Loader />
                        ) : (
                          <WindTurbine
                            entityId={gearbox.gearboxId}
                            windTurbines={turbines}
                            editMode={editMode}
                            onTurbineChange={(value: RelationTurbine, index: number, action: 'Add' | 'Edit' | 'Delete') => dispatch(setTurbines({ value, index, action }))}
                          ></WindTurbine>
                        )}
                      </>
                    ),
                    id: '0',
                    label: `${intl.formatMessage({ id: 'editGearbox.tab.windTurbine' }).toUpperCase()}`
                  },
                  {
                    children: <Sketch />,
                    id: '1',
                    label: `${intl.formatMessage({ id: 'editGearbox.tab.sketch' }).toUpperCase()}`
                  },
                  {
                    children: (
                      <>
                        {loadingProductParts ? (
                          <Loader />
                        ) : (
                          <>
                            <ListOfParts
                              entityId={gearbox.gearboxId}
                              changesetDetails={changesetDetails}
                              productCategory={ProductCategory.BEARING}
                              recordCategory={RecordCategory.Bearing}
                              positionCategory={PositionsCategory.GEARBOX}
                              relations={productParts}
                              editMode={editMode}
                              onPartChange={(value: RelationshipProduct, index: number, action: 'Add' | 'Edit' | 'Delete') => dispatch(setProductParts({ value, index, action }))}
                            ></ListOfParts>{' '}
                            <ListOfParts
                              entityId={gearbox.gearboxId}
                              changesetDetails={changesetDetails}
                              productCategory={ProductCategory.SEAL}
                              recordCategory={RecordCategory.Seal}
                              positionCategory={PositionsCategory.GEARBOX}
                              relations={productParts}
                              editMode={editMode}
                              onPartChange={(value: RelationshipProduct, index: number, action: 'Add' | 'Edit' | 'Delete') => dispatch(setProductParts({ value, index, action }))}
                            ></ListOfParts>
                          </>
                        )}
                      </>
                    ),
                    id: '2',
                    label: `${intl.formatMessage({ id: 'editGearbox.tab.listOfParts' }).toUpperCase()}${GetHasChangesOnParts(gearbox.gearboxId, [], changesetDetails) ? ` *` : ''}`
                  },
                  // Currently not fully implemented.
                  // {
                  //   children: (
                  //     <PositionSetup positions={positions} editMode={editMode} onPositionChange={(value: Position, index: number, action: 'Add' | 'Edit' | 'Delete') => dispatch(setPositions({ value, index, action }))}></PositionSetup>
                  //   ),
                  //   id: '3',
                  //   label: `${intl.formatMessage({ id: 'editGearbox.tab.positionsSetup' }).toUpperCase()}`
                  // },
                  {
                    id: '4',
                    children: <LogPosts loading={loadingLogPosts} logPosts={logPosts} />,
                    label: intl.formatMessage({ id: 'log.tab' }).toUpperCase()
                  }
                ]}
                feType="expanded"
              />
              <ExistsInChangesetInfo isEditingInTicket={!!changesetId} changesets={changesets} entityId={gearbox.gearboxId} />
              <FormActions
                entityId={gearbox.gearboxId}
                editMode={editMode}
                formError={formError}
                loading={{ delete: loading.delete, editInTicket: loading.editInTicket, save: loading.save }}
                formHasChanges={!(_.isEqual(gearbox, gearboxOriginal) && _.isEqual(productParts, productPartsOriginal) && _.isEqual(turbines, turbinesOriginal))}
                changesetId={changesetId}
                onTicketActionClick={onTicketActionClick}
                onEditClick={() => dispatch(setEditMode({ editMode: true }))}
                onSaveClick={onSave}
                onCloseChangesetClick={onClose}
                onDeleteClick={onDelete}
                onCancelClick={() => {
                  dispatch(setEditMode({ editMode: false }));
                  dispatch(setGearbox({ gearbox: { ...gearboxOriginal } }));
                  dispatch(resetProductParts());
                  dispatch(resetTurbines());
                  dispatch(resetPositionsState());
                }}
                newMode={newMode}
                onCancelAdd={onCancelAdd}
              />
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

export default EditGearbox;

interface Props {
  newMode?: boolean;
  setLoadForms?: any;
}
