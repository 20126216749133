import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ChangesetDetails } from 'domain/index';
import { GetChangesetDiff } from 'services/api';

export type ChangesetDetailsState = {
  changesetDetails: ChangesetDetails | undefined;
  loading: boolean;
  error: string | undefined;
};

const initialState: ChangesetDetailsState = {
  changesetDetails: undefined,
  loading: false,
  error: undefined
};

export const getChangesetDetails = createAsyncThunk('product/getChangesetDetails', async (options: { changesetId: string }) => {
  return (await GetChangesetDiff(options.changesetId)).data;
});

export const changesetDetailsSlice = createSlice({
  name: 'changesetDetails',
  initialState: initialState,
  reducers: {
    resetChangesetDetailsState: (state: ChangesetDetailsState) => {
      state.changesetDetails = undefined;
      state.loading = false;
      state.error = undefined;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getChangesetDetails.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getChangesetDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.changesetDetails = action.payload;
        state.error = undefined;
      })
      .addCase(getChangesetDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

export const { resetChangesetDetailsState } = changesetDetailsSlice.actions;
export default changesetDetailsSlice.reducer;
