import { Icons, Select, SelectItem } from '@skf/ferris';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { DesignationItem } from './DesignationComboBox';
import { DesignationInputProps } from './domain/DesignationInputProps';
import { getObjectTypeFromRecordCategory } from './domain/ObjectType';
import { SuggestHit } from './domain/SuggestHit';
import useSuggestSearch from './hooks/useSuggestSearch';

const createSelectItem = (hit: SuggestHit): SelectItem<number> => {
  return {
    label: hit.name,
    value: hit.id
  };
};

const createAdditionalSelectItem = (item: DesignationItem): SelectItem<number> => {
  return {
    label: item.name,
    value: item.id,
    feIcon: Icons.InfoCircleFilled
  };
};

const DesignationSelect = ({ recordCategory, label, value, onChange, additionalItems = [], hint, severity }: DesignationInputProps) => {
  const { objectType, productCategory } = getObjectTypeFromRecordCategory(recordCategory);
  const suggest = useSuggestSearch(objectType, productCategory);
  const intl = useIntl();

  const additionalSelectItems = useMemo(() => additionalItems?.map(createAdditionalSelectItem) ?? [], [additionalItems]);

  const [items, setItems] = useState<SelectItem<number>[]>(additionalSelectItems);

  const getItemsFromGroups = (items: SelectItem<number>[]) => {
    if (items.every((i) => i.items)) {
      return items.reduce((allitems, item) => [...allitems, ...(item.items as SelectItem<number>[])], [] as SelectItem<number>[]);
    } else {
      return items;
    }
  };

  const onSelectChange = (value: number) => {
    const existing = getItemsFromGroups(items).find((i) => i.value === value);
    if (existing) {
      onChange({ id: value, name: existing.label! });
    } else {
      onChange(null);
    }
  };

  useEffect(() => {
    suggest('')
      .then((result) => {
        const items = result.map((s) => createSelectItem(s as SuggestHit));
        if (additionalSelectItems.length > 0) {
          setItems([
            {
              label: intl.formatMessage({ id: 'search.designation.from.changeset' }),
              items: [...additionalSelectItems]
            },
            {
              label: intl.formatMessage({ id: 'search.designation.from.database' }),
              items: items.filter((i) => !additionalSelectItems.some((ai) => ai.value === i.value))
            }
          ]);
        } else {
          setItems(items);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [suggest, additionalSelectItems, intl]);

  return <Select<number> feLabel={label} required={true} feItems={items} value={value?.id} feSize="md" multiple={false} onChange={onSelectChange} feHint={hint} feSeverity={severity} />;
};

export default DesignationSelect;
