import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RelationshipGenerator } from 'domain/index';
import { GetRelatedGeneratorsForComponent } from 'services/api';

export type RelationshipGeneratorsState = {
  generators: RelationshipGenerator[];
  generatorsAreInitialized: boolean;
  loading: boolean;
  error: string | undefined;
};

const initialState: RelationshipGeneratorsState = {
  generators: [],
  generatorsAreInitialized: false,
  loading: false,
  error: undefined
};

export const getRelatedGeneratorsForComponent = createAsyncThunk('generators/getRelatedGeneratorsForComponent', async (options: { id: number; changesetId?: string }) => {
  return (await GetRelatedGeneratorsForComponent(options.id, options.changesetId)).data;
});

export const relationshipGeneratorsSlice = createSlice({
  name: 'generators',
  initialState: initialState,
  reducers: {
    resetGeneratorsState: (state: RelationshipGeneratorsState) => {
      state.generators = [];
      state.generatorsAreInitialized = false;
      state.loading = false;
      state.error = undefined;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getRelatedGeneratorsForComponent.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getRelatedGeneratorsForComponent.fulfilled, (state, action) => {
        state.loading = false;
        state.generators = action.payload;
        state.generatorsAreInitialized = true;
        state.error = undefined;
      })
      .addCase(getRelatedGeneratorsForComponent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

export const { resetGeneratorsState } = relationshipGeneratorsSlice.actions;
export default relationshipGeneratorsSlice.reducer;
