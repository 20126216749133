import { Spacer, Time } from '@skf/ferris';
import { ObjectDetailsDifferenceRemoved, ObjectDetailsDifferenceUpdated } from 'domain/index';
import { useIntl } from 'react-intl';
import { GetNameFromEmail, GetTraslationIdForField } from 'utils';
import ChangeLog from './ChangeLog';
import ChangeLogValueItem from './ChangeLogValueItem';
import React from 'react';

const ObjectDetailsItem = ({ added, updated, removed, type }: Props) => {
  const intl = useIntl();

  const getDisplayName = (objectDetails: ObjectDetailsDifferenceUpdated | ObjectDetailsDifferenceRemoved): string => `${objectDetails.recordCategory} ${objectDetails.name}`;

  return (
    <>
      {added?.map((x) => (
        <div className="pb-2 flex">
          <div className="whitespace-nowrap pr-4">
            <Time className="pr-2" dateTime={added[0].properties[0].modifiedAt} feFormat="date" />
            {GetNameFromEmail(added[0].properties[0].modifiedBy)}
          </div>
          <div>
            <p>
              {intl.formatMessage({ id: 'changelog.added' })}: {getDisplayName(x)}
            </p>
            {x.properties.map((y) => (
              <>
                <span className="font-bold">{`${intl.formatMessage({ id: `${GetTraslationIdForField('part', y.name)}` })}: `}</span>
                {y.newValue}
                <br />
              </>
            ))}
          </div>
        </div>
      ))}
      {!!removed?.length && (
        <>
          {removed.map((x) => (
            <ChangeLog key={x.name} modifiedAt={x.modifiedAt} modifiedBy={x.modifiedBy} category={type} name="objectDetails">
              <p>
                {intl.formatMessage({ id: 'changelog.removed' })}: <span>{getDisplayName(x)}</span>
              </p>
              <Spacer />
            </ChangeLog>
          ))}
        </>
      )}
      {!!updated?.length && (
        <div>
          {updated.map((x) => (
            <div key={x.name}>
              {x.properties.map((y) => (
                <ChangeLog key={y.name} modifiedAt={y.modifiedAt} modifiedBy={y.modifiedBy} category={type} name="objectDetails">
                  <p>
                    {intl.formatMessage({ id: 'changelog.updated' })}: <span>{getDisplayName(x)}</span>
                  </p>
                  <span className="font-bold">{`${intl.formatMessage({ id: `${GetTraslationIdForField('part', y.name)}` })}: `}</span>
                  <ChangeLogValueItem name={y.name} newValue={y.newValue} oldValue={y.oldValue} />
                  <Spacer />
                </ChangeLog>
              ))}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

type Props = {
  added?: ObjectDetailsDifferenceUpdated[];
  updated?: ObjectDetailsDifferenceUpdated[];
  removed?: ObjectDetailsDifferenceRemoved[];
  type: string;
};

export default ObjectDetailsItem;
