import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LogPost } from 'domain/index';
import { GetLogForGearbox, GetLogForGenerator, GetLogForProduct, GetLogForTurbine } from 'services/api';

export type LogState = {
  logPosts: LogPost[];
  logPostsAreInitialized: boolean;
  loading: boolean;
  error: string | undefined;
};

const initialState: LogState = {
  logPosts: [],
  logPostsAreInitialized: false,
  loading: false,
  error: undefined
};

export const getLog = createAsyncThunk('product/getLog', async (options: { type: 'Product' | 'Gearbox' | 'Generator' | 'Turbine'; id: number }) => {
  if (options.type === 'Product') {
    return (await GetLogForProduct(options.id)).data;
  }
  if (options.type === 'Gearbox') {
    return (await GetLogForGearbox(options.id)).data;
  }
  if (options.type === 'Generator') {
    return (await GetLogForGenerator(options.id)).data;
  }
  if (options.type === 'Turbine') {
    return (await GetLogForTurbine(options.id)).data;
  }
  return [];
});

export const logSlice = createSlice({
  name: 'log',
  initialState: initialState,
  reducers: {
    resetLogState: (state: LogState) => {
      state.logPosts = [];
      state.logPostsAreInitialized = false;
      state.loading = false;
      state.error = undefined;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getLog.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getLog.fulfilled, (state, action) => {
        state.loading = false;
        state.logPosts = action.payload;
        state.logPostsAreInitialized = true;
        state.error = undefined;
      })
      .addCase(getLog.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

export const { resetLogState } = logSlice.actions;
export default logSlice.reducer;
