import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RelationGenerator } from 'domain/index';
import { GetRelatedGeneratorsForTurbine } from 'services/api';
import { UpdateListByAction } from 'features/edit/shared';

export type RelationGeneratorsState = {
  generators: RelationGenerator[];
  generatorsOriginal: RelationGenerator[];
  generatorsAreInitialized: boolean;
  loading: boolean;
  error: string | undefined;
};

const initialState: RelationGeneratorsState = {
  generators: [],
  generatorsOriginal: [],
  generatorsAreInitialized: false,
  loading: false,
  error: undefined
};

export const getRelatedGeneratorsForTurbine = createAsyncThunk('turbine/getRelatedGeneratorsForTurbine', async (options: { turbineId: number; changesetId?: string }) => {
  return (await GetRelatedGeneratorsForTurbine(options.turbineId, options.changesetId)).data;
});

export const relationGeneratorsSlice = createSlice({
  name: 'relationGeneratorsState',
  initialState: initialState,
  reducers: {
    resetRelationGeneratorsStateState: (state: RelationGeneratorsState) => {
      state.generators = [];
      state.generatorsOriginal = {} as RelationGenerator[];
      state.generatorsAreInitialized = false;
      state.loading = false;
      state.error = undefined;
    },
    resetGenerators: (state: RelationGeneratorsState) => {
      state.generators = state.generatorsOriginal;
    },
    resetGeneratorsOriginal: (state: RelationGeneratorsState) => {
      state.generatorsOriginal = state.generators;
    },
    setGenerators: (state: RelationGeneratorsState, action: PayloadAction<{ value: RelationGenerator; index: number; action: 'Add' | 'Edit' | 'Delete' }>) => {
      state.generators = UpdateListByAction([...state.generators], action.payload.value, action.payload.index, action.payload.action);
    },
    setGeneratorsOriginal: (state: RelationGeneratorsState, action: PayloadAction<{ generators: RelationGenerator[] }>) => {
      state.generatorsOriginal = action.payload.generators;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getRelatedGeneratorsForTurbine.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getRelatedGeneratorsForTurbine.fulfilled, (state, action) => {
        state.loading = false;
        state.generators = action.payload;
        state.generatorsAreInitialized = true;
        state.error = undefined;
      })
      .addCase(getRelatedGeneratorsForTurbine.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

export const { resetRelationGeneratorsStateState, resetGenerators, resetGeneratorsOriginal, setGenerators, setGeneratorsOriginal } = relationGeneratorsSlice.actions;
export default relationGeneratorsSlice.reducer;
