import { Icons } from '@skf/ferris';

export type ComboBoxItem<T> = {
  item: T;
  disabled?: boolean;
  label: string;
  value: string;
  feIcon?: Icons;
};

export const getComboBoxItem = <T>(item: T, label: string, value: string): ComboBoxItem<T> => {
  return {
    item,
    label,
    value
  } as ComboBoxItem<T>;
};
