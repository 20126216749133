import { Spacer, TextField } from '@skf/ferris';
import { BaseSeverity } from '@skf/ferris/dist/esm/common/types/severity';
import { Grease, ChangesetDetails } from 'domain/index';
import { useIntl } from 'react-intl';
import { GetHint, GetSeverity } from '../../shared/index';

const GreaseForm = ({ grease, editMode, onGreaseChange, formError, changesetDetails }: Props) => {
  const intl = useIntl();
  const onGreaseNlgiClassChange = (event: any, value: string) => {
    onGreaseChange({ ...grease, greaseNlgiClass: value });
  };
  const onGreaseTypeViscChange = (event: any, value: string) => {
    onGreaseChange({ ...grease, greaseTypeVisc: value });
  };
  const onGreaseSoapChange = (event: any, value: string) => {
    onGreaseChange({ ...grease, greaseSoap: value });
  };
  const onGreaseSafetyDataChange = (event: any, value: string) => {
    onGreaseChange({ ...grease, greaseSafetyData: value });
  };

  const getSeverity = (fieldName: string): BaseSeverity | undefined => {
    return GetSeverity(grease.productId, formError, fieldName, changesetDetails);
  };

  const getHint = (fieldName: string): string | undefined => {
    return GetHint(grease.productId, formError, fieldName, changesetDetails, intl);
  };

  return (
    <div className="flex flex-wrap">
      <div className="w-1/3 pl-2 pr-2">
        <TextField
          disabled={!editMode}
          onChange={onGreaseNlgiClassChange}
          value={grease.greaseNlgiClass}
          feLabel={intl.formatMessage({ id: 'grease.greaseNlgiClass' })}
          feHint={getHint('greaseNlgiClass')}
          feSeverity={getSeverity('greaseNlgiClass')}
        />
        <Spacer />
      </div>
      <div className="w-1/3 pl-2 pr-2">
        <TextField
          disabled={!editMode}
          onChange={onGreaseTypeViscChange}
          value={grease.greaseTypeVisc}
          feLabel={intl.formatMessage({ id: 'grease.greaseTypeVisc' })}
          feHint={getHint('greaseTypeVisc')}
          feSeverity={getSeverity('greaseTypeVisc')}
        />
        <Spacer />
      </div>
      <div className="w-1/3 pl-2 pr-2">
        <TextField disabled={!editMode} onChange={onGreaseSoapChange} value={grease.greaseSoap} feLabel={intl.formatMessage({ id: 'grease.greaseSoap' })} feHint={getHint('greaseSoap')} feSeverity={getSeverity('greaseSoap')} />
        <Spacer />
      </div>
      <div className="w-2/3 pl-2 pr-2">
        <TextField
          disabled={!editMode}
          onChange={onGreaseSafetyDataChange}
          value={grease.greaseSafetyData}
          feLabel={intl.formatMessage({ id: 'grease.greaseSafetyData' })}
          feHint={getHint('greaseSafetyData')}
          feSeverity={getSeverity('greaseSafetyData')}
        />
        <Spacer />
      </div>
    </div>
  );
};

interface Props {
  grease: Grease;
  editMode: boolean;
  onGreaseChange: any;
  formError: Map<string, string>;
  changesetDetails?: ChangesetDetails;
}

export default GreaseForm;
