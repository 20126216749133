import { useIntl } from 'react-intl';
import { NumberField, Spacer, TextAreaField, TextField } from '@skf/ferris';
import { RelationTurbine, RelationTurbineNullable } from 'domain/index';
import DesignationComboBox, { DesignationItem } from 'features/search/DesignationComboBox';
import { ObjectType } from '../../../../../domain/shared/ObjectId';
import { useAppSelector } from 'store';

const WindTurbineForm = ({ turbine, onFormChange: onPartChange, formError }: Props) => {
  const intl = useIntl();

  const turbineChanges = useAppSelector((state) => state.changesetDetailsData.changesetDetails?.refs.filter((ref) => ref.type === ObjectType.TURBINE));

  const additionalItems = turbineChanges?.map(
    (change) =>
      ({
        id: change.id,
        name: change.name,
        manufacturerName: '-'
      } as DesignationItem)
  );

  const onTurbineIdChange = (item: DesignationItem | null) => {
    if (item) {
      onPartChange({
        ...turbine,
        turbineId: item.id,
        turbineName: item.name,
        manufacturer: { manufacturerName: item.manufacturerName },
        turbine: {
          turbineName: item.name,
          manufacturer: { manufacturerName: item.manufacturerName }
        }
      });
    } else {
      onPartChange({
        ...turbine,
        turbineId: null,
        turbineName: null,
        manufacturer: null,
        turbine: {
          turbineName: null,
          manufacturer: { manufacturerName: null }
        }
      });
    }
  };
  const onManufacturerChange = (event: any, value: string) => {
    //onPartChange({ ...turbine, manufacturer: { manufacturerName: value } });
  };
  const onMarketShareChange = (event: any, value: number) => {
    onPartChange({ ...turbine, marketShare: value });
  };
  const onRemarkChange = (event: any, value: string) => {
    onPartChange({ ...turbine, remark: value });
  };

  const getSeverity = (fieldName: string): 'error' | 'info' | 'success' | 'warning' | undefined => {
    if (formError && formError.get(fieldName)) {
      return 'error';
    }
  };

  const getHint = (fieldName: string): string | undefined => {
    if (formError && formError.get(fieldName)) {
      return formError.get(fieldName);
    }
  };

  return (
    <div className="flex flex-wrap">
      <div className="w-1/2 pl-2 pr-2">
        <DesignationComboBox
          label={intl.formatMessage({ id: 'windTurbine.Id' })}
          value={turbine.turbineId ? { id: turbine.turbineId, name: turbine.turbine?.turbineName ?? '', manufacturerName: turbine.turbine?.manufacturer.manufacturerName ?? '' } : undefined}
          recordCategory={ObjectType.TURBINE}
          onChange={onTurbineIdChange}
          additionalItems={additionalItems}
          severity={getSeverity('turbineId')}
          hint={getHint('turbineId')}
        />
        <Spacer />
      </div>
      <div className="w-1/2 pl-2 pr-2">
        <TextField disabled onChange={onManufacturerChange} value={turbine.turbine?.manufacturer.manufacturerName ?? ''} feLabel={intl.formatMessage({ id: 'windTurbine.manufacturer' })} />
        <Spacer />
      </div>
      <div className="w-1/2 pl-2 pr-2">
        <NumberField min={0} feHint={getHint('marketShare')} feSeverity={getSeverity('marketShare')} onChange={onMarketShareChange} value={turbine.marketShare} feLabel={intl.formatMessage({ id: 'windTurbine.marketShare' })} />
        <Spacer />
      </div>
      <div className="w-full pl-2 pr-2">
        <TextAreaField rows={5} onChange={onRemarkChange} value={turbine.remark} feLabel={intl.formatMessage({ id: 'windTurbine.remark' })} />
        <Spacer />
      </div>
    </div>
  );
};

interface Props {
  turbine: RelationTurbineNullable;
  onFormChange: any;
  formError?: Map<string, string>;
}

export default WindTurbineForm;
