import { useIntl } from 'react-intl';

const translationList = ['empty', 'Product'];

const useValueTranslation = (field: string) => {
  const intl = useIntl();

  return (value: string, innerfield = field) => {
    if (!translationList.includes(value)) return value;
    return intl.formatMessage({ id: `search.filter.${innerfield}.values.${value}` });
  };
};

export default useValueTranslation;
