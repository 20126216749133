import { Icons, Tag } from '@skf/ferris';
import { useAppDispatch, useAppSelector } from 'store';
import { BoolFilter, DateFilter, Filter, MultiChoiceFilter, ValueFilter } from '../domain/SearchQuery';
import { Facet, FacetType } from '../domain/SearchResult';
import { useBoolFilterValueFilter, useGetFilterLabel } from '../hooks/useFilterLabel';
import { removeKeywordValue, resetDateValue, setBoolValue } from '../store';

const SearchChips = () => {
  const activeFilters = useAppSelector((state) => state.searchData.query.filters);
  const dispatch = useAppDispatch();
  const getLabel = useGetFilterLabel();
  const getFilterLabel = useBoolFilterValueFilter();

  const getChip = (field: string, value: string, onRemove: () => void) => (
    <Tag
      className="mr-2 mt-2"
      feRemoveButton={{
        onClick: () => onRemove()
      }}
      key={field + value}
      feIcon={Icons.Filter}
    >
      {`${getLabel(field)}:${value}`}
    </Tag>
  );

  const getChips = (filter: Filter, facet: Facet): JSX.Element[] => {
    if (facet.type === FacetType.ValueFilter) {
      return [
        getChip(filter.field, (filter as ValueFilter).value, () => {
          dispatch(removeKeywordValue({ facet, value: (filter as ValueFilter).value }));
        })
      ];
    } else if (facet.type === FacetType.MultiChoiceAndFilter || facet.type === FacetType.MultiChoiceOrFilter) {
      return (filter as MultiChoiceFilter).values.map((v) =>
        getChip(filter.field, v, () => {
          dispatch(removeKeywordValue({ facet, value: v }));
        })
      );
    } else if (facet.type === FacetType.DateFilter) {
      return [
        getChip(filter.field, (filter as DateFilter).from, () => {
          dispatch(resetDateValue({ facet }));
        }),
        getChip(filter.field, (filter as DateFilter).to, () => {
          dispatch(resetDateValue({ facet }));
        })
      ];
    } else if (facet.type === FacetType.BoolFilter) {
      return [
        getChip(filter.field, getFilterLabel(filter.field, (filter as BoolFilter).flag), () => {
          dispatch(setBoolValue({ facet }));
        })
      ];
    } else {
      return [<></>];
    }
  };

  return <div className="mt-3">{activeFilters.flatMap((f, i) => getChips(f.filter, f.facet))}</div>;
};

export default SearchChips;
