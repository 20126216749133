import { ChangesetDetails, DifferenceType, ProductCategory, StockingPointDifference } from 'domain/index';
import { GetChangeOnField } from 'features/edit/shared';
import _ from 'lodash';

const BEARING_DETAIL_FIELDS = ['innerDiameter', 'outerDiameter', 'width', 'weight', 'dynamicCapacity', 'staticCapacity', 'numberOfRe', 'cageMaterial'] as string[];
const GREASE_DETAIL_FIELDS = ['greaseNlgiClass', 'greaseTypeVisc', 'greaseSoap', 'greaseSafetyData'];
const LUBRICATIONSYSTEM_DETAIL_FIELDS = ['weight', 'lubSSeriesRetro', 'lubSNoOfLubePoints', 'lubSPump', 'lubSSystemType', 'lubSGrease', 'lubSMeteringDevice', 'lubSPinion', 'lubSDocs', 'lubSPrimaryFeeder', 'lubSSecondaryFeeder'];
const LUBRICATOR_DETAIL_FIELDS = ['lubVolumeCapacity', 'lubMaxOperPressure', 'lubMaxFeedLineLen', 'lubDriveMechanism', 'lubTempRange', 'lubPumpableGreases'];
const SEAL_DETAIL_FIELDS = ['innerDiameter', 'outerDiameter', 'width', 'weight', 'material'];

export const GetHasChangesInDetails = (productId: number, productCategory: ProductCategory, changesetDetails?: ChangesetDetails): boolean => {
  let fieldNames = [] as string[];
  switch (productCategory) {
    case ProductCategory.BEARING:
      fieldNames = BEARING_DETAIL_FIELDS;
      break;
    case ProductCategory.GREASE:
      fieldNames = GREASE_DETAIL_FIELDS;
      break;
    case ProductCategory.LUBRICATION_SYSTEM:
      fieldNames = LUBRICATIONSYSTEM_DETAIL_FIELDS;
      break;
    case ProductCategory.LUBRICATOR:
      fieldNames = LUBRICATOR_DETAIL_FIELDS;
      break;
    case ProductCategory.SEAL:
      fieldNames = SEAL_DETAIL_FIELDS;
      break;
    default:
      break;
  }
  return _.some(fieldNames, (fieldName) => GetChangeOnField(productId, fieldName, changesetDetails));
};

export const GetHasChangesOnStockingPoint = (productId: number, changesetDetails?: ChangesetDetails): boolean => {
  const [added, removed] = GetChangesOnStockingPoint(productId, changesetDetails);
  return added.length > 0 || removed.length > 0;
};

export const GetChangesOnStockingPoint = (productId: number, changesetDetails?: ChangesetDetails) => {
  if (!!changesetDetails) {
    const difference = changesetDetails.differences.find((x) => x.ref.id === productId && x.type === DifferenceType.STOCKING_POINTS_UPDATED) as StockingPointDifference;
    if (!!difference && difference.added && difference.removed) {
      return [difference.added, difference.removed];
    }
  }
  return [[] as string[], [] as string[]];
};
