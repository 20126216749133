import { useState } from 'react';

const useHistoricQueries = (queryKey: string, storedNumber: number): [string[], (query: string) => void] => {
  const [storedValue, setStoredValue] = useState<string[]>(() => {
    if (typeof window === 'undefined') {
      return [];
    }
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(queryKey);
      // Parse stored json or if none return initialValue
      return item ? (JSON.parse(item) as string[]) : [];
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return [];
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (query: string | null) => {
    if (query) {
      try {
        if (!storedValue.includes(query)) {
          const values = [query, ...storedValue.slice(0, storedNumber - 1)];
          // Save state
          setStoredValue(values);
          // Save to local storage
          if (typeof window !== 'undefined') {
            window.localStorage.setItem(queryKey, JSON.stringify(values));
          }
        }
      } catch (error) {
        // A more advanced implementation would handle the error case
        console.log(error);
      }
    }
  };
  return [storedValue, setValue];
};

export default useHistoricQueries;
