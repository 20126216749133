import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StockingPoint } from 'domain/index';
import { GetStockingPoints, GetStockingPointsFromChangeset } from 'services/api';

export type StockingPointsState = {
  stockingPoints: StockingPoint[];
  stockingPointsOriginal: StockingPoint[];
  stockingPointsAreInitialized: boolean;
  loading: boolean;
  error: string | undefined;
};

const initialState: StockingPointsState = {
  stockingPoints: [],
  stockingPointsOriginal: [],
  stockingPointsAreInitialized: false,
  loading: false,
  error: undefined
};

export const getStockingPoints = createAsyncThunk('product/getStockingPoints', async (options: { productId: number; changesetId: string | undefined }) => {
  if (!!options.changesetId) {
    return (await GetStockingPointsFromChangeset(options.changesetId, options.productId)).data;
  }
  return (await GetStockingPoints(options.productId)).data;
});

export const stockingPointsSlice = createSlice({
  name: 'stockingPoint',
  initialState: initialState,
  reducers: {
    resetStockingPointsState: (state: StockingPointsState) => {
      state.stockingPoints = [];
      state.stockingPointsOriginal = [];
      state.stockingPointsAreInitialized = false;
      state.loading = false;
      state.error = undefined;
    },
    setStockingPoints: (state: StockingPointsState, action: PayloadAction<{ value: StockingPoint; checked: boolean }>) => {
      state.stockingPoints = action.payload.checked ? state.stockingPoints.concat(action.payload.value) : state.stockingPoints.filter((x) => x.warehouseId !== action.payload.value.warehouseId);
    },
    resetStockingPoints: (state: StockingPointsState) => {
      state.stockingPoints = state.stockingPointsOriginal;
    },
    resetStockingPointsOriginal: (state: StockingPointsState) => {
      state.stockingPointsOriginal = state.stockingPoints;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getStockingPoints.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getStockingPoints.fulfilled, (state, action) => {
        state.stockingPoints = action.payload;
        state.stockingPointsOriginal = action.payload;
        state.loading = false;
      })
      .addCase(getStockingPoints.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

export const { resetStockingPointsState, setStockingPoints, resetStockingPoints, resetStockingPointsOriginal } = stockingPointsSlice.actions;
export default stockingPointsSlice.reducer;
