import { Button } from '@skf/ferris';
import { Changeset, ChangesetStatus } from 'domain/index';
import { useIntl } from 'react-intl';
import { ChangesetIsActive, IsActiveUserRequest } from 'utils';

const ChangesetActionButtons = ({ changeset, editMode, onComment, onEditModeChange, onReject, onStatusUpdate }: Props) => {
  const intl = useIntl();

  const actionButton = (status: ChangesetStatus): JSX.Element => {
    switch (status) {
      case ChangesetStatus.NEW:
        return <Button onClick={() => onStatusUpdate(ChangesetStatus.IN_PROGRESS)}>{intl.formatMessage({ id: 'changeset.assign.button' })}</Button>;
      case ChangesetStatus.IN_PROGRESS:
        return <Button onClick={() => onStatusUpdate(ChangesetStatus.PENDING_APPROVAL)}>{intl.formatMessage({ id: 'changeset.pendingapproval.button' })}</Button>;
      case ChangesetStatus.PENDING_APPROVAL:
        return <Button onClick={() => onStatusUpdate(ChangesetStatus.COMMITTED)}>{intl.formatMessage({ id: 'changeset.commit.button' })}</Button>;
      default:
        return <></>;
    }
  };

  return (
    <div className="flex justify-end mr-8">
      {ChangesetIsActive(changeset.status) && (
        <>
          {editMode ? (
            <>
              <Button className="mr-2" feType="secondary" onClick={() => onEditModeChange(false)}>
                {intl.formatMessage({ id: 'changeset.info.comment.cancelButton' })}
              </Button>
              <Button className="mr-2" feType="primary" onClick={() => onComment()}>
                {intl.formatMessage({ id: 'changeset.info.comment.updateButton' })}
              </Button>
            </>
          ) : (
            <>
              <Button className="mr-2" feType="secondary" onClick={() => onEditModeChange(true)}>
                {intl.formatMessage({ id: 'changeset.info.comment.editButton' })}
              </Button>
              {IsActiveUserRequest(changeset.status, changeset.userRequest) && (
                <Button className="mr-2" feDestructive onClick={() => onReject()}>
                  {intl.formatMessage({ id: 'changeset.reject.button' })}
                </Button>
              )}
              {actionButton(changeset.status)}
            </>
          )}
        </>
      )}
    </div>
  );
};

interface Props {
  changeset: Changeset;
  editMode: boolean;
  onEditModeChange: any;
  onComment: any;
  onReject: any;
  onStatusUpdate: any;
}

export default ChangesetActionButtons;
