import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from 'store';
import { ActiveSearchQuery, defaultPagination, Pagination, SearchQuery, Sorting } from '../domain/SearchQuery';
import { Facet, SearchResult } from '../domain/SearchResult';
import { getSearchService } from '../services/SearchService';
import { setKeywordValueEffect, removeKeywordValueEffect, setDateValueEffect, setEmptyQueryFiltersFromResultEffect, resetDateValueEffect, setBoolValueEffect } from './filterEffects';
import { resultEffects } from './resultEffects';

export type SearchState = {
  result?: SearchResult;
  loading: boolean;
  error: string | null;
  query: ActiveSearchQuery;
};

const initialState: SearchState = {
  loading: false,
  error: null,
  query: {
    filters: []
  } as ActiveSearchQuery
};

export const performQuery = createAsyncThunk('search/getSearchResult', async (query: SearchQuery, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
  const root = getState() as RootState;
  try {
    if (root.userData.isAuthenticated && root.userData.user) {
      return {
        result: await getSearchService().query(query),
        query: query
      };
    } else {
      throw new Error('User not authenticated');
    }
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw rejectWithValue(error.message);
    } else {
      throw rejectWithValue((error as Error).message);
    }
  }
});

export const searchSlice = createSlice({
  name: 'search',
  initialState: initialState,
  reducers: {
    setKeywordValue: (state: SearchState, action: PayloadAction<{ facet: Facet; value: string }>) => {
      state.query = setKeywordValueEffect(state.query, action.payload.facet, action.payload.value);
    },
    setDateValue: (state: SearchState, action: PayloadAction<{ facet: Facet; from: string; to: string }>) => {
      state.query = setDateValueEffect(state.query, action.payload.facet, action.payload.from, action.payload.to);
    },
    setBoolValue: (state: SearchState, action: PayloadAction<{ facet: Facet; value?: boolean }>) => {
      state.query = setBoolValueEffect(state.query, action.payload.facet, action.payload.value);
    },
    resetDateValue: (state: SearchState, action: PayloadAction<{ facet: Facet }>) => {
      state.query = resetDateValueEffect(state.query, action.payload.facet);
    },
    removeKeywordValue: (state: SearchState, action: PayloadAction<{ facet: Facet; value: string }>) => {
      state.query = removeKeywordValueEffect(state.query, action.payload.facet, action.payload.value);
    },
    resetFilters: (state: SearchState, action: PayloadAction) => {
      state.query.filters = [];
    },
    setQuery: (state: SearchState, action: PayloadAction<{ query: string; exact?: boolean }>) => {
      if (state.query.q !== action.payload.query || state.query.exactMatch !== action.payload.exact) {
        state.query.q = action.payload.query;
        state.query.exactMatch = action.payload.exact;
        state.query.pagination = defaultPagination;
        state.query.isEmpty = false;
      }
    },
    setPagination: (state: SearchState, action: PayloadAction<Pagination>) => {
      state.query.pagination = { skip: action.payload.skip, size: action.payload.size };
      state.query.isEmpty = false;
    },
    setSorting: (state: SearchState, action: PayloadAction<Sorting>) => {
      state.query.sort = { field: action.payload.field, order: action.payload.order };
      state.query.pagination = defaultPagination;
      state.query.isEmpty = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(performQuery.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(performQuery.fulfilled, (state, action: PayloadAction<{ result: SearchResult; query: SearchQuery }>) => {
      state.result = resultEffects(action.payload.result);
      state.query = setEmptyQueryFiltersFromResultEffect(state.query, action.payload.result, action.payload.query);
      state.error = null;
      state.loading = false;
    });
    builder.addCase(performQuery.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
  }
});

export const { setQuery, setPagination, setSorting, setKeywordValue, removeKeywordValue, setDateValue, resetDateValue, setBoolValue, resetFilters } = searchSlice.actions;
export default searchSlice.reducer;
