import { AxiosResponse } from 'axios';
import { Changeset, ChangesetDetails, ChangesetStatus, Gearbox, Generator, Product, StockingPoint, Turbine } from 'domain/index';
import { getAppService } from './service.base';
import { ObjectId, ObjectType } from 'domain/shared/ObjectId';

const PARAMETER_MISSING_ERROR = 'Parameter is missing, parameter name: ';

const apiService = getAppService();

export const GetProductFromChangeset = async (changesetId: string, productId: number): Promise<AxiosResponse<Product>> => {
  return apiService.withGet(`/changesets/${changesetId}/components/${productId}`, 'Something went wrong while fetching product.');
};

export const GetStockingPointsFromChangeset = async (id: string, productId: number): Promise<AxiosResponse<StockingPoint[]>> => {
  return apiService.withGet(`/changesets/${id}/components/${productId}/stocking-points`, 'Something went wrong while fetching stocking points.');
};

export const GetGearboxFromChangeset = async (changesetId: string, gearboxId: number): Promise<AxiosResponse<Gearbox>> => {
  return apiService.withGet(`/changesets/${changesetId}/gearboxes/${gearboxId}`, 'Something went wrong while fetching gearbox.');
};

export const GetGeneratorFromChangeset = async (changesetId: string, generatorId: number): Promise<AxiosResponse<Generator>> => {
  return apiService.withGet(`/changesets/${changesetId}/generators/${generatorId}`, 'Something went wrong while fetching generator.');
};

export const GetTurbineFromChangeset = async (changesetId: string, turbineId: number): Promise<AxiosResponse<Turbine>> => {
  return apiService.withGet(`/changesets/${changesetId}/turbines/${turbineId}`, 'Something went wrong while fetching turbine.');
};

export const GetChangesets = async (): Promise<AxiosResponse<Changeset[]>> => {
  return apiService.withGet(`changesets`, 'Something went wrong while fetching changesets.');
};

export const GetChangeset = async (id: string): Promise<AxiosResponse<Changeset>> => {
  return apiService.withGet(`changesets/${id}`, 'Something went wrong while fetching changeset.');
};

export const GetChangesetDiff = async (id: string | undefined): Promise<AxiosResponse<ChangesetDetails>> => {
  if (!id) throw new Error(`${PARAMETER_MISSING_ERROR} id`);
  return apiService.withGet(`changesets/${id}/diff`, 'Something went wrong while fetching changeset details.');
};

export const PostChangeset = async (): Promise<AxiosResponse<Changeset>> => {
  return apiService.withPost(`changesets`, {}, 'Something went wrong while posting changeset.');
};

export const PostChangesetRef = async (changesetId: string, id: number, name: string, type: string, eTag: string): Promise<AxiosResponse<Changeset>> => {
  return apiService.withPost(`changesets/${changesetId}/refs`, { id, name, type }, 'Something went wrong while posting ref.', { 'If-Match': eTag });
};

export const CreateTurbineInChangeset = async (changesetId: string, data: any, eTag: string): Promise<AxiosResponse<Turbine>> => {
  return apiService.withPost(`changesets/${changesetId}/turbines`, data, 'Something went wrong while creating a new turbine.', { 'If-Match': eTag });
};

export const CreateGearboxInChangeset = async (changesetId: string, data: any, eTag: string): Promise<AxiosResponse<Gearbox>> => {
  return apiService.withPost(`changesets/${changesetId}/gearboxes`, data, 'Something went wrong while creating a new gearbox.', { 'If-Match': eTag });
};

export const CreateGeneratorInChangeset = async (changesetId: string, data: any, eTag: string): Promise<AxiosResponse<Generator>> => {
  return apiService.withPost(`changesets/${changesetId}/generators`, data, 'Something went wrong while creating a new generator.', { 'If-Match': eTag });
};

export const CreateProductInChangeset = async (changesetId: string, data: any, eTag: string): Promise<AxiosResponse<Product>> => {
  return apiService.withPost(`changesets/${changesetId}/components`, data, 'Something went wrong while creating a new product.', { 'If-Match': eTag });
};

export const PostChangesetCommands = async (changesetId: string, objectId: ObjectId, commands: Record<string, unknown>, etag: string, autoCommit: boolean): Promise<{ headers: { etag: string } }> => {
  const getEntityTypeFromType = (type: ObjectType): string => {
    switch (type) {
      case ObjectType.GEARBOX:
        return 'gearboxes';
      case ObjectType.GENERATOR:
        return 'generators';
      case ObjectType.TURBINE:
        return 'turbines';
      case ObjectType.PRODUCT:
        return 'components';
      default:
        throw new Error(`Object type ${type} is not supported when posting changeset commands`);
    }
  };

  if (Object.keys(commands).length > 0) {
    const result = await apiService.withPost(
      `changesets/${changesetId}/${getEntityTypeFromType(objectId.type)}/${objectId.id}/changes${autoCommit ? '?autoCommit=true' : ''}`,
      { ...commands },
      'Something went wrong while posting changes.',
      { 'If-Match': etag }
    );
    return result as unknown as { headers: { etag: string } };
  } else {
    return {
      headers: {
        etag: etag
      }
    };
  }
};

export const PatchChangeset = async (id: string, eTag: string, status?: ChangesetStatus, comment?: string): Promise<AxiosResponse<Changeset>> => {
  return apiService.withPatch(`changesets/${id}`, { status, comment }, 'Something went wrong updating status of changeset.', { 'If-Match': eTag });
};

export const CommitChangeset = async (id: string, eTag: string): Promise<AxiosResponse<Changeset>> => {
  return apiService.withPost(`changesets/${id}/commit`, {}, 'Something went wrong while committing changeset.', { 'If-Match': eTag });
};

export const DeleteProduct = async (changesetId: string, productId: number, eTag: string): Promise<AxiosResponse<string>> => {
  return apiService.withDelete(`changesets/${changesetId}/components/${productId}`, 'Something went wrong while deleting product.', { 'If-Match': eTag });
};

export const DeleteGearbox = async (changesetId: string, gearboxId: number, eTag: string): Promise<AxiosResponse<string>> => {
  return apiService.withDelete(`changesets/${changesetId}/gearboxes/${gearboxId}`, 'Something went wrong while deleting gearbox.', { 'If-Match': eTag });
};

export const DeleteGenerator = async (changesetId: string, generatorId: number, eTag: string): Promise<AxiosResponse<string>> => {
  return apiService.withDelete(`changesets/${changesetId}/generators/${generatorId}`, 'Something went wrong while deleting generator.', { 'If-Match': eTag });
};

export const DeleteTurbine = async (changesetId: string, turbineId: number, eTag: string): Promise<AxiosResponse<string>> => {
  return apiService.withDelete(`changesets/${changesetId}/turbines/${turbineId}`, 'Something went wrong while deleting turbine.', { 'If-Match': eTag });
};

export const DeleteRef = async (changesetId: string, refId: number, eTag: string): Promise<AxiosResponse<string>> => {
  return apiService.withDelete(`changesets/${changesetId}/refs/${refId}`, 'Something went wrong while deleting changeset.', { 'If-Match': eTag });
};

export const DeleteChangeset = async (id: string, eTag: string): Promise<AxiosResponse<string>> => {
  return apiService.withDelete(`changesets/${id}`, 'Something went wrong while deleting changeset.', { 'If-Match': eTag });
};

export const RemoveUserRequestAttachment = async (changesetId: string, attachmentId: string, eTag: string) => {
  return apiService.withDelete(`changesets/${changesetId}/attachments/user-requests/${attachmentId}`, 'Something went wrong while deleting attachment.', { 'If-Match': eTag });
};

export const GetUserRequestAttachmentDownloadUrl = async (changesetId: string, attachmentId: string): Promise<AxiosResponse<{ downloadUrl: string }>> => {
  return apiService.withGet(`changesets/${changesetId}/attachments/user-requests/${attachmentId}/download-url`, 'Something went wrong while fetching attachment download url.');
};
