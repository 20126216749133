import { ReactNode } from 'react';
import { Navigate, useLocation, Location } from 'react-router';
import { useAuth } from './AuthProvider';

export interface AuthNavigationState {
  from: Location;
}

const RequireAuth = ({ children }: { children: ReactNode }) => {
  const auth = useAuth();
  const location = useLocation();
  if (auth.isAuthenticated === null) {
    return null;
  } else if (auth.isAuthenticated === false) {
    return <Navigate to="/login" state={{ from: location } as AuthNavigationState} replace />;
  } else {
    return <>{children}</>;
  }
};

export default RequireAuth;
