import { useNavigate } from 'react-router';
import { IconInteractive, Icons } from '@skf/ferris';

const BackToChangeset = ({ changesetId, className }: Props) => {
  const navigate = useNavigate();
  return (
    <IconInteractive
      aria-label="Go back to ticket"
      as="button"
      className={className}
      title="Go back to ticket"
      feIcon={Icons.ArrowLeft}
      onClick={() => {
        navigate(`/tickets/${changesetId}`);
      }}
    />
  );
};

interface Props {
  changesetId?: string;
  className?: string;
}

export default BackToChangeset;
