import { Changeset, ChangesetStatus, UserRequest } from 'domain/index';

export const ActiveChangesetStatuses = (): ChangesetStatus[] => [ChangesetStatus.NEW, ChangesetStatus.IN_PROGRESS, ChangesetStatus.PENDING_APPROVAL];

export const InactiveChangesetStatuses = (): ChangesetStatus[] => [ChangesetStatus.COMMITTED, ChangesetStatus.REJECTED];

export const ChangesetIsActive = (status: ChangesetStatus): boolean => ActiveChangesetStatuses().includes(status);

export const ChangesetIsInactive = (status: ChangesetStatus): boolean => InactiveChangesetStatuses().includes(status);

export const IsUserRequest = (userRequest?: UserRequest): boolean => !!userRequest;

export const IsActiveUserRequest = (status: ChangesetStatus, userRequest?: UserRequest): boolean => ChangesetIsActive(status) && IsUserRequest(userRequest);

export const GetColorForStatus = (status: ChangesetStatus): string => {
  switch (status) {
    case ChangesetStatus.NEW:
      return 'bg-green-base';
    case ChangesetStatus.IN_PROGRESS:
      return 'bg-blue-base';
    case ChangesetStatus.PENDING_APPROVAL:
      return 'bg-yellow-base';
    case ChangesetStatus.COMMITTED:
      return 'bg-gray-600';
    case ChangesetStatus.REJECTED:
      return 'bg-red-base';
    default:
      return '';
  }
};

export const GetColorBorderForStatus = (status: ChangesetStatus): string => {
  switch (status) {
    case ChangesetStatus.NEW:
      return 'border-green-base';
    case ChangesetStatus.IN_PROGRESS:
      return 'border-blue-base';
    case ChangesetStatus.PENDING_APPROVAL:
      return 'border-yellow-base';
    case ChangesetStatus.COMMITTED:
      return 'border-gray-600';
    case ChangesetStatus.REJECTED:
      return 'border-red-base';
    default:
      return '';
  }
};

export const EntityExistsInAChangeset = (changesets: Changeset[], entityId: number): boolean => {
  return changesets.filter((x) => ChangesetIsActive(x.status)).some((x) => x.refs.some((y) => y.id === entityId));
};
