import { Spacer, NumberField } from '@skf/ferris';
import { BaseSeverity } from '@skf/ferris/dist/esm/common/types/severity';
import { Bearing, ChangesetDetails } from 'domain/index';
import { useIntl } from 'react-intl';
import { GetHint, GetSeverity, SelectWrapper } from '../../shared/index';
import { ObjectType } from '../../../../domain/shared/ObjectId';

const BearingForm = ({ bearing, editMode, onBearingChange, formError, changesetDetails }: Props) => {
  const intl = useIntl();
  const onInnerDiameterChange = (event: any, value: number) => {
    onBearingChange({ ...bearing, innerDiameter: value });
  };
  const onOuterDiameterChange = (event: any, value: number) => {
    onBearingChange({ ...bearing, outerDiameter: value });
  };
  const onWidthChange = (event: any, value: number) => {
    onBearingChange({ ...bearing, width: value });
  };
  const onWeightChange = (event: any, value: number) => {
    onBearingChange({ ...bearing, weight: value });
  };
  const onDynamicCapacityChange = (event: any, value: number) => {
    onBearingChange({ ...bearing, dynamicCapacity: value });
  };
  const onStaticCapacityChange = (event: any, value: number) => {
    onBearingChange({ ...bearing, staticCapacity: value });
  };
  const onNumberOfReChange = (event: any, value: number) => {
    onBearingChange({ ...bearing, numberOfRe: value });
  };
  const onMaterialChange = (value: string) => {
    onBearingChange({ ...bearing, material: value });
  };

  const getSeverity = (fieldName: string): BaseSeverity | undefined => {
    return GetSeverity(bearing.productId, formError, fieldName, changesetDetails);
  };

  const getHint = (fieldName: string): string | undefined => {
    return GetHint(bearing.productId, formError, fieldName, changesetDetails, intl);
  };

  return (
    <div className="flex flex-wrap">
      <div className="w-1/4 pl-2 pr-2">
        <NumberField
          disabled={!editMode}
          onChange={onInnerDiameterChange}
          value={bearing.innerDiameter}
          feLabel={intl.formatMessage({ id: 'bearing.innerDiameter' })}
          feHint={getHint('innerDiameter')}
          feSeverity={getSeverity('innerDiameter')}
          min={0}
        />
        <Spacer />
      </div>
      <div className="w-1/4 pl-2 pr-2">
        <NumberField
          disabled={!editMode}
          onChange={onOuterDiameterChange}
          value={bearing.outerDiameter}
          feLabel={intl.formatMessage({ id: 'bearing.outerDiameter' })}
          feHint={getHint('outerDiameter')}
          feSeverity={getSeverity('outerDiameter')}
          min={0}
        />
        <Spacer />
      </div>
      <div className="w-1/4 pl-2 pr-2">
        <NumberField disabled={!editMode} onChange={onWidthChange} value={bearing.width} feLabel={intl.formatMessage({ id: 'bearing.width' })} feHint={getHint('width')} feSeverity={getSeverity('width')} min={0} />
        <Spacer />
      </div>
      <div className="w-1/4 pl-2 pr-2">
        <NumberField disabled={!editMode} onChange={onWeightChange} value={bearing.weight} feLabel={intl.formatMessage({ id: 'bearing.weight' })} feHint={getHint('weight')} feSeverity={getSeverity('weight')} min={0} />
        <Spacer />
      </div>
      <div className="w-1/4 pl-2 pr-2">
        <NumberField
          disabled={!editMode}
          onChange={onDynamicCapacityChange}
          value={bearing.dynamicCapacity}
          feLabel={intl.formatMessage({ id: 'bearing.dynamicCapacity' })}
          feHint={getHint('dynamicCapacity')}
          feSeverity={getSeverity('dynamicCapacity')}
          min={0}
        />
        <Spacer />
      </div>
      <div className="w-1/4 pl-2 pr-2">
        <NumberField
          disabled={!editMode}
          onChange={onStaticCapacityChange}
          value={bearing.staticCapacity}
          feLabel={intl.formatMessage({ id: 'bearing.staticCapacity' })}
          feHint={getHint('staticCapacity')}
          feSeverity={getSeverity('staticCapacity')}
          min={0}
        />
        <Spacer />
      </div>
      <div className="w-1/4 pl-2 pr-2">
        <NumberField disabled={!editMode} onChange={onNumberOfReChange} value={bearing.numberOfRe} feLabel={intl.formatMessage({ id: 'bearing.numberOfRe' })} feHint={getHint('numberOfRe')} feSeverity={getSeverity('numberOfRe')} min={0} />
        <Spacer />
      </div>
      <div className="w-1/4 pl-2 pr-2">
        <SelectWrapper
          objectType={ObjectType.PRODUCT}
          value={bearing.material}
          fieldName="material"
          label={intl.formatMessage({ id: 'bearing.material' })}
          productCategory="Bearing"
          onValueChange={onMaterialChange}
          editMode={editMode}
          hint={getHint('material')}
          severity={getSeverity('material')}
        />
        <Spacer />
      </div>
    </div>
  );
};

interface Props {
  bearing: Bearing;
  editMode: boolean;
  onBearingChange: any;
  formError: Map<string, string>;
  changesetDetails?: ChangesetDetails;
}

export default BearingForm;
