import { useToast } from '@skf/ferris';
import { useEffect } from 'react';
import { useAppSelector } from 'store';

const useSearchError = () => {
  const error = useAppSelector((state) => state.searchData.error);
  const addToast = useToast().addToast;

  useEffect(() => {
    if (error) {
      addToast({ children: error, feSeverity: 'error' });
    }
  }, []);

  return null;
};

export default useSearchError;
