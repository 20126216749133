import { ActiveSearchQuery, BoolFilter, DateFilter, defaultPagination, MultiChoiceAndFilter, MultiChoiceFilter, MultiChoiceOrFilter, Order, Pagination, SearchQuery, Sorting, ValueFilter } from '../domain/SearchQuery';
import { FacetType } from '../domain/SearchResult';

enum ParamPrefixes {
  valuefilter = 'vf',
  multivalueandfilter = 'mvaf',
  multivalueorfilter = 'mvof',
  boolfilter = 'bf',
  datefilter = 'df',
  pagination = 'p',
  sorting = 's'
}

const commonNames = {
  pagination: {
    skip: ParamPrefixes.pagination + 'skip',
    size: ParamPrefixes.pagination + 'size'
  },
  sorting: {
    field: ParamPrefixes.sorting + 'field',
    order: ParamPrefixes.sorting + 'order'
  },
  query: 'q',
  exact: 'e'
};

export class QueryParamBuilder {
  private params: { field: string; type?: FacetType; value: DateFilter | ValueFilter | MultiChoiceFilter | Pagination | Sorting | BoolFilter | string }[];
  constructor() {
    this.params = [];
  }

  fromQuery(query: ActiveSearchQuery): QueryParamBuilder {
    if (query.q) {
      this.addQuery(query.q);
    }
    if (query.exactMatch) {
      this.addExact(query.exactMatch);
    }
    if (query.pagination) {
      this.setPagination(query.pagination.skip, query.pagination.size);
    }
    if (query.sort) {
      this.setSorting(query.sort.field, query.sort.order);
    }
    for (const filter of query.filters ?? []) {
      if (filter.facet.type === FacetType.DateFilter) {
        this.addDateFilter(filter.filter.field, (filter.filter as DateFilter).from, (filter.filter as DateFilter).to);
      } else if (filter.facet.type === FacetType.ValueFilter) {
        this.addKeywordFilter(filter.filter.field, (filter.filter as ValueFilter).value, filter.facet.type);
      } else if (filter.facet.type === FacetType.MultiChoiceAndFilter) {
        this.params.push({
          field: filter.filter.field,
          type: FacetType.MultiChoiceAndFilter,
          value: {
            field: filter.filter.field,
            values: (filter.filter as MultiChoiceFilter).values
          } as MultiChoiceFilter
        });
      } else if (filter.facet.type === FacetType.MultiChoiceOrFilter) {
        this.params.push({
          field: filter.filter.field,
          type: FacetType.MultiChoiceOrFilter,
          value: {
            field: filter.filter.field,
            values: (filter.filter as MultiChoiceFilter).values
          } as MultiChoiceFilter
        });
      } else if (filter.facet.type === FacetType.BoolFilter) {
        this.params.push({
          field: filter.filter.field,
          type: FacetType.BoolFilter,
          value: {
            field: filter.filter.field,
            flag: (filter.filter as BoolFilter).flag
          } as BoolFilter
        });
      }
    }
    return this;
  }

  fromParams(params: URLSearchParams): QueryParamBuilder {
    if (params.get(commonNames.pagination.size) && params.get(commonNames.pagination.skip)) {
      this.setPagination(parseInt(params.get(commonNames.pagination.skip)!), parseInt(params.get(commonNames.pagination.size)!));
    }
    if (params.get(commonNames.sorting.field) && params.get(commonNames.sorting.order)) {
      this.setSorting(params.get(commonNames.sorting.field)!, params.get(commonNames.sorting.order)! as Order);
    }
    if (params.get(commonNames.query)) {
      this.addQuery(params.get(commonNames.query)!);
    }
    if (params.get(commonNames.exact)) {
      this.addExact(params.get(commonNames.exact) === 'true' ? true : false);
    }

    params.forEach((value: string, key: string) => {
      if (key.startsWith(ParamPrefixes.valuefilter)) {
        this.addKeywordFilter(key.replace(ParamPrefixes.valuefilter, ''), value, FacetType.ValueFilter);
      }
      if (key.startsWith(ParamPrefixes.multivalueorfilter)) {
        this.params.push({
          field: key.replace(ParamPrefixes.multivalueorfilter, ''),
          type: FacetType.MultiChoiceOrFilter,
          value: {
            field: key.replace(ParamPrefixes.multivalueorfilter, ''),
            values: value.split(',')
          } as MultiChoiceOrFilter
        });
      }
      if (key.startsWith(ParamPrefixes.multivalueandfilter)) {
        this.params.push({
          field: key.replace(ParamPrefixes.multivalueandfilter, ''),
          type: FacetType.MultiChoiceAndFilter,
          value: {
            field: key.replace(ParamPrefixes.multivalueandfilter, ''),
            values: value.split(',')
          } as MultiChoiceAndFilter
        });
      }
      if (key.startsWith(ParamPrefixes.datefilter)) {
        this.addDateFilter(key.replace(ParamPrefixes.datefilter, ''), new Date(parseInt(value.split(',')[0])).toISOString(), new Date(parseInt(value.split(',')[1])).toISOString());
      }
      if (key.startsWith(ParamPrefixes.boolfilter)) {
        this.params.push({
          field: key.replace(ParamPrefixes.boolfilter, ''),
          type: FacetType.BoolFilter,
          value: {
            field: key.replace(ParamPrefixes.boolfilter, ''),
            flag: value ? (value === 'true' ? true : false) : undefined
          } as BoolFilter
        });
      }
    });

    return this;
  }

  addKeywordFilter(field: string, value: string, type: FacetType): QueryParamBuilder {
    const existing = this.params.find((f) => f.field === field);
    switch (type) {
      case FacetType.ValueFilter:
        if (existing) {
          (existing.value as ValueFilter).value = value;
        } else {
          this.params.push({
            field,
            type: type,
            value: {
              field,
              value
            } as ValueFilter
          });
        }
        break;
      case FacetType.MultiChoiceAndFilter:
      case FacetType.MultiChoiceOrFilter:
        if (existing) {
          (existing.value as MultiChoiceFilter).values = [...(existing.value as MultiChoiceFilter).values, value];
        } else {
          this.params.push({
            field,
            type: type,
            value: {
              field,
              values: [value]
            } as MultiChoiceFilter
          });
        }
        break;
      default:
        break;
    }
    return this;
  }

  setPagination(skip: number, take: number): QueryParamBuilder {
    const existing = this.params.find((f) => f.field === 'pagination');
    if (existing) {
      (existing.value as Pagination).size = take;
      (existing.value as Pagination).skip = skip;
    } else {
      this.params.push({
        field: 'pagination',
        value: {
          skip: skip,
          size: take
        } as Pagination
      });
    }
    return this;
  }

  addDateFilter(field: string, from: string, to: string): QueryParamBuilder {
    const existing = this.params.find((f) => f.field === field);
    if (existing) {
      (existing.value as DateFilter).from = from;
      (existing.value as DateFilter).to = to;
    } else {
      this.params.push({
        field,
        type: FacetType.DateFilter,
        value: {
          field,
          from,
          to
        } as DateFilter
      });
    }
    return this;
  }

  addQuery(query: string): QueryParamBuilder {
    const existing = this.params.find((f) => f.field === 'query');
    if (existing) {
      existing.value = query;
    } else {
      this.params.push({
        field: 'query',
        value: query
      });
    }
    return this;
  }

  addExact(exact: boolean) {
    const existing = this.params.find((f) => f.field === 'exact');
    if (existing) {
      existing.value = exact.toString();
    } else {
      this.params.push({
        field: 'exact',
        value: exact.toString()
      });
    }
    return this;
  }

  setSorting(field: string, direction: Order): QueryParamBuilder {
    const existing = this.params.find((f) => f.field === 'sorting');
    if (existing) {
      (existing.value as Sorting).field = field;
      (existing.value as Sorting).order = direction;
    } else {
      this.params.push({
        field: 'sorting',
        value: {
          field: field,
          order: direction
        } as Sorting
      });
    }
    return this;
  }

  build(): URLSearchParams {
    const params = new URLSearchParams();
    this.params.forEach((f) => {
      if (f.field === 'pagination') {
        params.set(commonNames.pagination.skip, (f.value as Pagination).skip.toString());
        params.set(commonNames.pagination.size, (f.value as Pagination).size.toString());
      } else if (f.field === 'sorting') {
        params.set(commonNames.sorting.field, (f.value as Sorting).field);
        params.set(commonNames.sorting.order, (f.value as Sorting).order);
      } else if (f.field === 'query') {
        params.set(commonNames.query, f.value as string);
      } else if (f.field === 'exact') {
        params.set(commonNames.exact, f.value.toString());
      } else if (f.type === FacetType.DateFilter) {
        params.set(ParamPrefixes.datefilter + f.field, [new Date((f.value as DateFilter).from).getTime(), new Date((f.value as DateFilter).to).getTime()].join());
      } else if (f.type === FacetType.MultiChoiceAndFilter) {
        if ((f.value as MultiChoiceFilter).values.length > 0) {
          params.set(ParamPrefixes.multivalueandfilter + f.field, (f.value as MultiChoiceFilter).values.join());
        }
      } else if (f.type === FacetType.MultiChoiceOrFilter) {
        if ((f.value as MultiChoiceFilter).values.length > 0) {
          params.set(ParamPrefixes.multivalueorfilter + f.field, (f.value as MultiChoiceFilter).values.join());
        }
      } else if (f.type === FacetType.ValueFilter) {
        params.set(ParamPrefixes.valuefilter + f.field, (f.value as ValueFilter).value);
      } else if (f.type === FacetType.BoolFilter) {
        params.set(ParamPrefixes.boolfilter + f.field, (f.value as BoolFilter).flag?.toString() ?? '');
      }
    });
    return params;
  }

  getQuery(): SearchQuery {
    return {
      pagination: (this.params.find((f) => f.field === 'pagination')?.value as Pagination) ?? defaultPagination,
      filters: this.params.filter((f) => f.field !== 'sorting' && f.field !== 'pagination' && f.field !== 'query' && f.field !== 'exact').map((f) => f.value),
      sort: this.params.find((f) => f.field === 'sorting')?.value as Sorting,
      q: this.params.find((f) => f.field === 'query')?.value ?? '',
      exactMatch: this.params.find((f) => f.field === 'exact')?.value === 'true' ? true : false,
      highlight: true
    } as SearchQuery;
  }
}
