// These are the product categories as per the database - case and spaces included
export enum ProductCategory {
  BEARING = 'Bearing',
  COUPLING = 'Coupling',
  GREASE = 'Grease',
  HOUSING = 'Housing',
  LUBRICATION_SYSTEM = 'Lubrication System',
  LUBRICATOR = 'Lubricator',
  SEAL = 'Seal'
}
