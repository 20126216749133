import { Button, Flexbox, Switch } from '@skf/ferris';
import SearchComboBox from 'components/form/ComboBox';
import { ComboBoxItem, getComboBoxItem } from 'components/form/ComboBox/ComboBoxItem';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from 'store';
import useDebounce from '../hooks/useDebounce';
import useHistoricQueries from '../hooks/useHistoricQueries';
import useSuggestSearch from '../hooks/useSuggestSearch';
import { setQuery as setStateQuery } from './../store/index';
import SearchChips from './SearchChips';

const SearchInput = () => {
  const intl = useIntl();
  const q = useAppSelector((state) => state.searchData.query.q);
  const exactSearch = useAppSelector((state) => state.searchData.query.exactMatch ?? false);
  const dispatch = useAppDispatch();

  const suggest = useSuggestSearch();

  const [query, setQuery] = useState(q ?? '');
  const [exact, setExact] = useState(exactSearch);
  const [searchText, setSearchText] = useState<string | undefined>(q);
  const [selectedItem, setSelectedItem] = useState<ComboBoxItem<string> | null>(q ? { value: q, label: q, item: q } : null);
  const [history, addToHistory] = useHistoricQueries('historicSearches', 8);
  const [items, setItems] = useState<ComboBoxItem<string>[]>(history.map((i) => getComboBoxItem(i, i, i)));
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const debouncedSearchText = useDebounce(searchText, 300);

  const onSearchClick = () => {
    dispatch(setStateQuery({ query, exact }));
    setItems(history.map((i) => getComboBoxItem(i, i, i)));
  };

  useEffect(() => {
    if (debouncedSearchText) {
      setIsLoading(true);
      suggest(debouncedSearchText, exact)
        .then((result) => {
          const items = result.map((s) => getComboBoxItem(s as string, s as string, s as string));
          setItems(items);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsError(true);
          setIsLoading(false);
        });
    }
  }, [debouncedSearchText, suggest, exact]);

  const onItemSelected = (item: ComboBoxItem<string> | null | undefined): void => {
    if (item) {
      setSelectedItem(item);
      setQuery(item?.value);
      dispatch(setStateQuery({ query: item?.value, exact }));
      addToHistory(item?.value);
    }
  };

  const onSearch = (value?: string | undefined) => {
    setSearchText(value);
    if (value !== undefined) {
      setQuery(value);
    }
  };

  const onReset = () => {
    setQuery('');
    setItems(history.map((i) => getComboBoxItem(i, i, i)));
  };

  const onSetExact = (checked: boolean) => {
    setExact(checked);
    dispatch(setStateQuery({ query, exact: checked }));
  };

  return (
    <>
      <Flexbox feJustifyContent="space-between" feAlignItems="flex-end">
        <SearchComboBox<string> className="w-full" items={items} label={'Search'} isError={isError} isLoading={isLoading} onItemSelected={onItemSelected} onSearch={onSearch} onReset={onReset} currentItem={selectedItem} />
        <Button onClick={onSearchClick} className="ml-4">
          {intl.formatMessage({ id: 'search.button' })}
        </Button>
      </Flexbox>
      <Flexbox feJustifyContent="space-between" feAlignItems="flex-end">
        <SearchChips />
        <Switch feLabel={intl.formatMessage({ id: 'search.exact' })} checked={exact} onChange={(_event, checked) => onSetExact(checked)} className="mt-3" />
      </Flexbox>
    </>
  );
};

export default SearchInput;
