import { Spacer } from '@skf/ferris';
import ActiveChangesets from './ActiveChangesets';
import InActiveChangesets from './InActiveChangesets';

const ChangesetListPage = () => {
  return (
    <div>
      <ActiveChangesets />
      <Spacer />
      <InActiveChangesets />
    </div>
  );
};

export default ChangesetListPage;
