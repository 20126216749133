import { Auth } from 'aws-amplify';
import axios, { AxiosInstance, AxiosResponse } from 'axios';

const VERSION_MISMATCH_ERROR = 'Changes to this changeset has been made by another part, please reload the page and try again.';

export const getAppService = (): ApiServiceBase => {
  return new ApiServiceBase(process.env.REACT_APP_API!, process.env.REACT_APP_API_KEY!);
};

export class ApiServiceBase {
  private baseUrl: string;
  private apiKey: string;

  constructor(baseUrl: string, apiKey: string) {
    this.baseUrl = baseUrl;
    this.apiKey = apiKey;
  }
  async getClient(headers: Record<string, string> = {}): Promise<AxiosInstance> {
    const data = await Auth.currentSession();

    return axios.create({
      baseURL: this.baseUrl,
      headers: {
        ...headers,
        'x-api-key': this.apiKey,
        Authorization: data.getIdToken().getJwtToken()
      }
    });
  }

  async withClient<T>(method: (client: AxiosInstance) => Promise<AxiosResponse<T>>, errorMessage: string, headers?: Record<string, string>) {
    try {
      const client = await this.getClient(headers);
      return await method(client);
    } catch (error: any) {
      if (error?.response?.status === 412) {
        throw new Error(`${errorMessage} ${VERSION_MISMATCH_ERROR}`);
      }
      if (error?.response?.status === 409) {
        throw new Error(`${errorMessage} ${error.response.data.message} Please remove the entity that has a conflict and readd it (you need to redo all the changes within the entity) or create a new ticket.`);
      }
      throw new Error(error?.message ?? errorMessage);
    }
  }

  async withGet<T>(path: string, errorMessage: string): Promise<AxiosResponse<T>> {
    return this.withClient<T>((client) => client.get(path), errorMessage);
  }

  async withPost<T>(path: string, data: unknown, errorMessage: string, headers?: Record<string, string>): Promise<AxiosResponse<T>> {
    return this.withClient<T>((client) => client.post(path, data), errorMessage, headers);
  }

  async withPatch<T>(path: string, data: unknown, errorMessage: string, headers?: Record<string, string>): Promise<AxiosResponse<T>> {
    return this.withClient<T>((client) => client.patch(path, data), errorMessage, headers);
  }

  async withDelete<T>(path: string, errorMessage: string, headers?: Record<string, string>): Promise<AxiosResponse<T>> {
    return this.withClient<T>((client) => client.delete(path), errorMessage, headers);
  }
}
