import { useIntl } from 'react-intl';
import { useAppSelector } from 'store';

const Sketch = () => {
  const intl = useIntl();
  const { gearbox } = useAppSelector((state) => state.editGearboxData);

  const getSketch = () => {
    switch (gearbox.gearboxType?.toLocaleLowerCase()) {
      case 'parallel shaft gearbox':
        return require('../../../../assets/sketches/gearboxes/parallell_shaft_gbx_three_stage.jpg');
      case 'full planetary gearbox':
        return require('../../../../assets/sketches/gearboxes/full_planetary_gbx.png');
      case 'hybrid planetary gearbox':
        if (gearbox.gearboxSubType?.toLocaleLowerCase().includes('one planetary stage')) {
          return require('../../../../assets/sketches/gearboxes/hybrid_planetary_gbx_one_planetary_stage.jpg');
        } else if (gearbox.gearboxSubType?.toLocaleLowerCase().includes('two planetary stage')) {
          return require('../../../../assets/sketches/gearboxes/hybrid_planetary_gbx_two_planetary_stage.jpg');
        } else if (gearbox.gearboxSubType?.toLocaleLowerCase().includes('three planetary stage')) {
          return require('../../../../assets/sketches/gearboxes/hybrid_planetary_gbx_three_planetary_stage.jpg');
        } else {
          return '';
        }
      default:
        return '';
    }
  };

  const displaySketch = () => {
    switch (gearbox.gearboxType?.toLocaleLowerCase()) {
      case 'parallel shaft gearbox':
        return true;
      case 'full planetary gearbox':
        return true;
      case 'hybrid planetary gearbox':
        if (gearbox.gearboxSubType && gearbox.gearboxSubType?.toLocaleLowerCase() !== 'differential gearbox') {
          return true;
        } else {
          return false;
        }
      default:
        return false;
    }
  };

  return (
    <div className="flex">
      {displaySketch() ? (
        <div className="m-auto">
          <img src={getSketch()} alt="Gearbox sketch" />
        </div>
      ) : (
        <>{intl.formatMessage({ id: 'editGearbox.tab.noAvailableSketch' })}</>
      )}
    </div>
  );
};

export default Sketch;
