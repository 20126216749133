import { ProductCategory, RecordCategory } from 'domain/index';
import DesignationComboBox, { DesignationItem } from 'features/search/DesignationComboBox';
import DesignationSelect from 'features/search/DesignationSelect';
import { DesignationInputProps } from 'features/search/domain/DesignationInputProps';
import { useAppSelector } from 'store';
import { ObjectType } from '../../../../../../domain/shared/ObjectId';

const DesignationInput = (props: DesignationInputProps) => {
  const productCategory = getProductCategoryFromRecordCategory(props.recordCategory);
  const changes = useAppSelector((state) => state.changesetDetailsData.changesetDetails?.refs.filter((ref) => (Array.isArray(productCategory) ? productCategory.includes(ref.type) : ref.type === (productCategory as unknown as string))));

  let additionalItems = changes?.map(
    (change) =>
      ({
        id: change.id,
        name: change.name,
        manufacturerName: '-' // TODO: Lookup manufacturer name
      } as DesignationItem)
  );

  if (useDesignationDropdown(props.recordCategory)) {
    return <DesignationSelect {...props} additionalItems={additionalItems} />;
  } else {
    return <DesignationComboBox {...props} additionalItems={additionalItems} />;
  }
};

export default DesignationInput;

const useDesignationDropdown = (recordCategory: RecordCategory | ObjectType): boolean => {
  switch (recordCategory) {
    case RecordCategory.DriveBearing:
    case RecordCategory.Bearing:
    case RecordCategory.RotorBearing:
    case RecordCategory.TowerBearing:
    case RecordCategory.DriveSeal:
    case RecordCategory.RotorSeal:
    case RecordCategory.Seal:
    case RecordCategory.TowerSeal:
      return false;
    default:
      return true;
  }
};

const getProductCategoryFromRecordCategory = (recordCategory: RecordCategory | ObjectType): ProductCategory | string[] | undefined => {
  switch (recordCategory) {
    case RecordCategory.DriveBearing:
    case RecordCategory.Bearing:
    case RecordCategory.RotorBearing:
    case RecordCategory.TowerBearing:
      return ProductCategory.BEARING;
    case RecordCategory.DriveGrease:
    case RecordCategory.Grease:
    case RecordCategory.RotorGrease:
    case RecordCategory.TowerGrease:
      return ProductCategory.GREASE;
    case RecordCategory.DriveHousing:
      return ProductCategory.HOUSING;
    case RecordCategory.GeneratorLubricationSystem:
    case RecordCategory.DriveLubricationSystem:
    case RecordCategory.RotorLubricationSystem:
    case RecordCategory.TowerLubricationSystem:
      return [ProductCategory.LUBRICATION_SYSTEM, 'Lubrication System'];
    case RecordCategory.DriveSeal:
    case RecordCategory.RotorSeal:
    case RecordCategory.Seal:
    case RecordCategory.TowerSeal:
      return ProductCategory.SEAL;
    case RecordCategory.GearboxCoupling:
      return ProductCategory.COUPLING;
    default:
      return undefined;
  }
};
