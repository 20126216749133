import { ActiveSearchQuery, BoolFilter, DateFilter, defaultPagination, Filter, MultiChoiceFilter, SearchQuery, ValueFilter } from '../domain/SearchQuery';
import { BoolFacet, DateFacet, Facet, FacetType, MultiChoiceFacet, SearchResult, ValueFacet } from '../domain/SearchResult';

export const setEmptyQueryFiltersFromResultEffect = (query: ActiveSearchQuery, result: SearchResult, resultQuery: SearchQuery): ActiveSearchQuery => {
  if (query.filters.length === 0) {
    let filters = [] as { filter: Filter; facet: Facet }[];
    resultQuery.filters?.forEach((f) => {
      const existing = getExistingFilter(query, f.field);
      if (!existing) {
        const facet = result.facets.find((facet) => facet.field === f.field);
        if (facet) {
          filters = [...filters, ...setFilter(query, facet, f).filters];
        }
      }
    });
    return {
      ...query,
      filters
    };
  } else {
    return query;
  }
};

export const setBoolValueEffect = (query: ActiveSearchQuery, facet: Facet, value?: boolean): ActiveSearchQuery => {
  const existing = getExistingFilter<BoolFilter, BoolFacet>(query, facet.field);
  if (value === undefined) {
    if (existing) {
      return removeFilter(query, facet);
    } else {
      return query;
    }
  } else {
    return setFilter(query, facet, {
      field: facet.field,
      flag: value
    } as BoolFilter);
  }
};

export const setKeywordValueEffect = (query: ActiveSearchQuery, facet: Facet, value: string): ActiveSearchQuery => {
  if (facet.type === FacetType.ValueFilter && facet.field === 'objectType') {
    return setAndClearValueKeywordValue(query, facet, value);
  } else if (facet.type === FacetType.ValueFilter) {
    return setValueKeywordValue(query, facet, value);
  } else if (facet.type === FacetType.MultiChoiceAndFilter || facet.type === FacetType.MultiChoiceOrFilter) {
    return setMultiValueKeywordValue(query, facet, value);
  } else {
    return query;
  }
};

export const removeKeywordValueEffect = (query: ActiveSearchQuery, facet: Facet, value: string): ActiveSearchQuery => {
  if (facet.type === FacetType.ValueFilter && facet.field === 'objectType') {
    return clearFilters(query);
  } else if (facet.type === FacetType.ValueFilter) {
    return removeFilter(query, facet);
  } else if (facet.type === FacetType.MultiChoiceAndFilter || facet.type === FacetType.MultiChoiceOrFilter) {
    return removeMultiValueKeywordValue(query, facet as MultiChoiceFacet, value);
  } else {
    return query;
  }
};

export const setAndClearValueKeywordValue = (query: ActiveSearchQuery, facet: Facet, value: string): ActiveSearchQuery => {
  const existing = getExistingFilter<ValueFilter, ValueFacet>(query, facet.field);
  if (existing) {
    return setAndClearFilter(query, existing.facet, {
      field: facet.field,
      value: value
    } as ValueFilter);
  } else {
    return setAndClearFilter(query, facet, {
      field: facet.field,
      value: value
    } as ValueFilter);
  }
};

export const setValueKeywordValue = (query: ActiveSearchQuery, facet: Facet, value: string): ActiveSearchQuery => {
  const existing = getExistingFilter<ValueFilter, ValueFacet>(query, facet.field);
  if (existing) {
    return setFilter(query, existing.facet, {
      field: facet.field,
      value: value
    } as ValueFilter);
  } else {
    return setFilter(query, facet, {
      field: facet.field,
      value: value
    } as ValueFilter);
  }
};

export const setMultiValueKeywordValue = (query: ActiveSearchQuery, facet: Facet, value: string): ActiveSearchQuery => {
  const existing = getExistingFilter<MultiChoiceFilter, MultiChoiceFacet>(query, facet.field);
  if (existing) {
    const newValues = [...existing.filter.values, value];
    return setFilter(query, existing.facet, {
      field: facet.field,
      values: newValues
    } as MultiChoiceFilter);
  } else {
    return setFilter(query, facet, {
      field: facet.field,
      values: [value]
    } as MultiChoiceFilter);
  }
};

export const removeMultiValueKeywordValue = (query: ActiveSearchQuery, facet: MultiChoiceFacet, value: string): ActiveSearchQuery => {
  const existing = getExistingFilter<MultiChoiceFilter, MultiChoiceFacet>(query, facet.field);
  if (existing) {
    const newValues = existing.filter.values.filter((v) => v !== value);
    if (newValues.length > 0) {
      return setFilter(query, facet, {
        field: facet.field,
        values: newValues
      } as MultiChoiceFilter);
    } else {
      return removeFilter(query, facet);
    }
  } else {
    return query;
  }
};

export const setDateValueEffect = (query: ActiveSearchQuery, facet: Facet, from: string, to: string): ActiveSearchQuery => {
  const existing = getExistingFilter<DateFilter, DateFacet>(query, facet.field);
  if (existing) {
    return setFilter(query, existing.facet, {
      field: facet.field,
      from,
      to
    } as DateFilter);
  } else {
    return setFilter(query, facet, {
      field: facet.field,
      from,
      to
    } as DateFilter);
  }
};

export const resetDateValueEffect = (query: ActiveSearchQuery, facet: Facet): ActiveSearchQuery => {
  return removeFilter(query, facet);
};

export const setFilter = (query: ActiveSearchQuery, facet: Facet, filter: Filter): ActiveSearchQuery => {
  return {
    ...query,
    filters: [...query.filters.filter((f) => f.facet.field !== facet.field), { facet, filter }],
    pagination: defaultPagination,
    isEmpty: false
  };
};

export const setAndClearFilter = (query: ActiveSearchQuery, facet: Facet, filter: Filter): ActiveSearchQuery => {
  return {
    ...query,
    filters: [{ facet, filter }],
    pagination: defaultPagination,
    isEmpty: false
  };
};

export const removeFilter = (query: ActiveSearchQuery, facet: Facet): ActiveSearchQuery => {
  return {
    ...query,
    filters: query.filters.filter((f) => f.facet.field !== facet.field),
    isEmpty: true,
    pagination: defaultPagination
  };
};

export const clearFilters = (query: ActiveSearchQuery): ActiveSearchQuery => {
  return {
    ...query,
    filters: [],
    isEmpty: true,
    pagination: defaultPagination
  };
};

export const getExistingFilter = <TFilter, TFacet>(query: ActiveSearchQuery, field: string): { facet: TFacet; filter: TFilter } | undefined => {
  return query.filters.find((f) => f.facet.field === field) as unknown as { facet: TFacet; filter: TFilter };
};
