import ChangesetListBox from './box/ChangesetListBox';
import { useIntl } from 'react-intl';
import { GetTraslationIdForChangesetStatus } from 'utils';
import { useEffect, useState } from 'react';
import { getChangesetSearchService } from '../services/ChangesetSearchService';
import { ChangesetSearchResult, convertToChangesets } from '../domain/ChangesetSearchResult';
import { ChangesetStatus } from 'domain/changeset/ChangesetStatus';
import { ChangesetSearchQuery, Order, Sorting } from '../domain/ChangesetSearchQuery';
import useDebounce from 'features/search/hooks/useDebounce';

const pageSize = 20;
const defaultQuery: ChangesetSearchQuery = { pagination: { size: pageSize, skip: 0 }, sort: { field: 'createdAt', order: Order.DESC } };

const InActiveChangesets = () => {
  const intl = useIntl();

  const ALL_STATUSES = 'ALL_STATUSES';
  const [searchQuery, setSearchQuery] = useState<ChangesetSearchQuery>(defaultQuery);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [searchResult, setSearchResult] = useState<ChangesetSearchResult | null>(null);
  const [searchText, setSearchText] = useState('');
  const debouncedSearchText = useDebounce(searchText, 500);
  const [statusFilters, setStatusFilters] = useState<{ label: string; value: string }[]>([]);

  useEffect(() => {
    if (debouncedSearchText) {
      setSearchQuery({ ...searchQuery, q: debouncedSearchText });
    } else if (!debouncedSearchText) {
      setSearchQuery({ ...searchQuery, q: undefined });
    }
  }, [debouncedSearchText]);

  useEffect(() => {
    let isSubscribed = true;

    setLoading(true);

    const service = getChangesetSearchService();

    const fetch = async () => {
      const result = await service.query(searchQuery);
      if (isSubscribed) {
        setSearchResult(result);
        if (statusFilters.length === 0) {
          const statusFilterOptions = result.statusFacets.values.map((s) => ({ label: `${intl.formatMessage({ id: GetTraslationIdForChangesetStatus(s.value) })} (${s.count}) `, value: s.value.toString() }));
          statusFilterOptions.unshift({ label: `${intl.formatMessage({ id: GetTraslationIdForChangesetStatus(ALL_STATUSES) })}`, value: ALL_STATUSES });
          setStatusFilters(statusFilterOptions);
        }
        setLoading(false);
      }
    };

    fetch().catch((err) => setError(err));

    return () => {
      isSubscribed = false;
    };
  }, [searchQuery]);

  const getSort = (field: string): Sorting => {
    if (field === searchQuery.sort?.field) {
      return { field, order: searchQuery.sort.order === Order.DESC ? Order.ASC : Order.DESC };
    } else {
      return { field, order: Order.DESC };
    }
  };

  return error ? (
    <p>Failed to load changesets, please try again</p>
  ) : (
    <ChangesetListBox
      loading={loading}
      changesets={convertToChangesets(searchResult?.hits ?? [])}
      heading={intl.formatMessage({ id: 'changeset.list.header.closed' })}
      statuses={statusFilters}
      status={searchQuery.status ?? ALL_STATUSES}
      numberOfChangesets={searchResult?.total ?? 0}
      query={searchText}
      onChangeStatus={(status) => setSearchQuery({ ...searchQuery, status: status !== ALL_STATUSES ? (status as ChangesetStatus) : undefined })}
      onSearch={(query) => setSearchText(query)}
      onSort={(field) => setSearchQuery({ ...searchQuery, sort: getSort(field) })}
      sortDirection={searchQuery.sort?.order === Order.DESC ? Order.ASC : Order.DESC}
      sortField={searchQuery.sort?.field}
      pagination={{
        pageSize: pageSize,
        totalSize: searchResult?.total ?? 0,
        currentPage: (searchQuery.pagination?.skip ?? 0) / pageSize,
        setCurrentPage: (page) => setSearchQuery({ ...searchQuery, pagination: { skip: page * pageSize, size: pageSize } })
      }}
    />
  );
};

export default InActiveChangesets;
