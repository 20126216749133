import { SearchQuery } from '../domain/SearchQuery';
import { SearchResult } from '../domain/SearchResult';
import { SuggestHit } from '../domain/SuggestHit';
import { ObjectType } from '../../../domain/shared/ObjectId';
import { ApiServiceBase } from 'services/api/service.base';

export const getSearchService = (): SearchService => {
  return new SearchService(process.env.REACT_APP_SEARCH_API!, process.env.REACT_APP_SEARCH_API_KEY!);
};
export class SearchService extends ApiServiceBase {
  async query(query: SearchQuery): Promise<SearchResult> {
    const client = await this.getClient();
    const response = await client.post('query', query);
    return response.data as SearchResult;
  }

  async suggest(query: string, exact?: boolean): Promise<string[]> {
    const client = await this.getClient();
    const response = await client.get(`suggest?query=${query}${exact ? '&exact=true' : ''}`);
    return response.data as string[];
  }

  async suggestWithType(query: string, objectType: ObjectType, productCategory?: string): Promise<SuggestHit[]> {
    const client = await this.getClient();
    let url = `suggest/${objectType}?query=${query}`;
    if (productCategory) {
      url += `&productCategory=${productCategory}`;
    }
    const response = await client.get(url);
    return response.data as SuggestHit[];
  }
}
