import React, { useCallback, useEffect } from 'react';
import { ReactNode, useState } from 'react';
import { AuthContextType } from './AuthContextType';
import { Amplify, Auth } from 'aws-amplify';
import { createUser, User } from './User';
import CognitoUserData from './CognitoUserData';
import { useDispatch } from 'react-redux';
import { setIsUserAuthenticated, setUserSignedOut } from 'store/index';
const AuthContext = React.createContext<AuthContextType>(null!);

const cognitoConfig = {
  userPoolWebClientId: process.env.REACT_APP_COGNITO_POOLWEBCLIENTID,
  userPoolId: process.env.REACT_APP_COGNITO_POOLID,
  region: process.env.REACT_APP_COGNITO_REGION
};

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const dispatch = useDispatch();

  let [user, setUser] = useState<User | null>(null);
  let [isAuthenticated, setIsAuthenticated] = useState<any>(null);

  const setupUserSignedIn = useCallback(
    (user: CognitoUserData) => {
      const userData = createUser(user);
      setUser(userData);
      setIsAuthenticated(true);
      dispatch(setIsUserAuthenticated(userData));
    },
    [dispatch]
  );

  const setupUserNotSignedIn = useCallback(() => {
    setUser(null);
    setIsAuthenticated(false);
    dispatch(setUserSignedOut());
  }, [dispatch]);

  useEffect(() => {
    Amplify.configure(cognitoConfig);
    Auth.currentAuthenticatedUser().then(setupUserSignedIn).catch(setupUserNotSignedIn);
  }, [setupUserSignedIn, setupUserNotSignedIn]);

  const signin = (username: string, password: string, callbackSuccess: () => void, callbackFailure: (message: string) => void) => {
    Auth.signIn(username, password)
      .then((user: CognitoUserData) => {
        setupUserSignedIn(user);
        callbackSuccess();
      })
      .catch((error) => {
        if (error.message) {
          callbackFailure(error.message);
        } else {
          callbackFailure('Unknown error');
        }
      });
  };

  const signout = (callback: VoidFunction) => {
    Auth.signOut().then(() => {
      setUser(null);
      setIsAuthenticated(false);
      callback();
    });
  };

  return <AuthContext.Provider value={{ user, isAuthenticated, signin, signout }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return React.useContext(AuthContext);
};

export default AuthProvider;
