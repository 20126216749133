import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from 'features/authentication/User';

export type UserState = {
  user: User | null;
  isAuthenticated: boolean;
};

const initialState: UserState = {
  user: null,
  isAuthenticated: false
};

export const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    setIsUserAuthenticated: (state: UserState, action: PayloadAction<User>) => {
      state.isAuthenticated = true;
      state.user = action.payload;
    },
    setUserSignedOut: (state: UserState, action: PayloadAction) => {
      state.isAuthenticated = false;
      state.user = null;
    }
  }
});

export const { setIsUserAuthenticated, setUserSignedOut } = userSlice.actions;
export default userSlice.reducer;
