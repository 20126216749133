import { Spacer, TextAreaField } from '@skf/ferris';
import { BaseSeverity } from '@skf/ferris/dist/esm/common/types/severity';
import { ChangesetDetails, Turbine } from 'domain/index';
import { GetHint, GetSeverity } from 'features/edit/shared/utils/form/Form.utility';
import { useIntl } from 'react-intl';

const DescriptionForm = ({ turbine, editMode, onTurbineChange, formError, changesetDetails }: ProductProps) => {
  const intl = useIntl();
  const onDescriptionChange = (event: any, value: string) => {
    onTurbineChange({ ...turbine, description: value });
  };
  const onDetailsChange = (event: any, value: string) => {
    onTurbineChange({ ...turbine, details: value });
  };

  const getSeverity = (fieldName: string): BaseSeverity | undefined => {
    return GetSeverity(turbine.turbineId, formError, fieldName, changesetDetails);
  };

  const getHint = (fieldName: string): string | undefined => {
    return GetHint(turbine.turbineId, formError, fieldName, changesetDetails, intl);
  };

  return (
    <div className="flex flex-wrap ">
      <div className="w-full pl-2 pr-2">
        <TextAreaField disabled={!editMode} onChange={onDescriptionChange} value={turbine.description} feLabel={intl.formatMessage({ id: 'turbine.description' })} feHint={getHint('description')} feSeverity={getSeverity('description')} />
        <Spacer />
      </div>
      <div className="w-full pl-2 pr-2">
        <TextAreaField disabled={!editMode} onChange={onDetailsChange} value={turbine.details} feLabel={intl.formatMessage({ id: 'turbine.details' })} feHint={getHint('details')} feSeverity={getSeverity('details')} />
        <Spacer />
      </div>
    </div>
  );
};

export default DescriptionForm;

interface ProductProps {
  turbine: Turbine;
  editMode: boolean;
  onTurbineChange: any;
  formError: Map<string, string>;
  changesetDetails?: ChangesetDetails;
}
