import ComboBox from 'components/form/ComboBox';
import { ComboBoxItem } from 'components/form/ComboBox/ComboBoxItem';
import { Positions } from 'domain/positions/Positions';
import { PositionsCategory } from 'domain/positions/PositionsCategory';
import { GetPositionListByCategory } from 'features/edit/shared';
import { useMemo, useState } from 'react';

const getComboBoxItem = (position: Positions): ComboBoxItem<Positions> => {
  return {
    item: position,
    label: position,
    value: position
  };
};

const PositionSelect = ({ label, hint, onChange, value, positionCategory, severity }: PositionSelectProps) => {
  let additionalItems = useMemo(() => GetPositionListByCategory(positionCategory).map((p) => getComboBoxItem(p)), [positionCategory]);
  const [items, setItems] = useState<ComboBoxItem<Positions>[]>(additionalItems);
  const [selectedItem, setSelectedItem] = useState<ComboBoxItem<Positions> | null>(value ? getComboBoxItem(value) : null);

  const onItemSelected = (item: ComboBoxItem<Positions> | null | undefined): void => {
    if (item) {
      setSelectedItem(item);
      onChange(item.value);
    }
  };

  const onSearch = (value?: string) => {
    if (value) {
      setItems(
        GetPositionListByCategory(positionCategory)
          .filter((p) => p.split('-').join('').toLocaleLowerCase().startsWith(value.split('-').join('').toLocaleLowerCase()))
          .map((p) => getComboBoxItem(p))
      );
    } else {
      setItems(additionalItems);
    }
  };

  const onReset = () => {
    onChange(null);
    setItems(additionalItems);
  };

  return <ComboBox<Positions> className="w-full" items={items} label={label} hint={hint} isError={severity === 'error'} isLoading={false} onItemSelected={onItemSelected} onSearch={onSearch} onReset={onReset} currentItem={selectedItem} />;
};

type PositionSelectProps = {
  label: string;
  hint?: string;
  severity?: 'error' | 'info' | 'success' | 'warning';
  value?: Positions;
  positionCategory: PositionsCategory;
  onChange: (positionCode: string | null) => void;
};

export default PositionSelect;
