import { useIntl } from 'react-intl';
import { Checkbox, Dialog, IconInteractive, Icons, NumberField, Select, Spacer, TextAreaField, TextField } from '@skf/ferris';
import { ChangesetDetails, Positions, PositionsCategory, RecordCategory, RelationshipProduct } from 'domain/index';
import { useState } from 'react';
import StatusHelper from './status-helper/StatusHelper';
import { GetHintForPart, GetSeverityForPart } from '../../utils/form/Form.utility';
import { BaseSeverity } from '@skf/ferris/dist/esm/common/types/severity';
import DesignationInput from './designation-input/DesignationInput';
import { DesignationItem } from 'features/search/DesignationComboBox';
import PositionSelect from './position-select/PositionSelect';

const STATUS_OPTIONS = ['OE Certified', 'SKF Verified', 'Technical Equivalent', 'No secured SKF Alternative'];
const LABEL_OPTIONS = ['O&M Recommended', 'OEM', 'Upgrade', 'Alternative', 'Re-engineered', 'Competitor'];

const PartForm = ({ entityId, changesetDetails, recordCategory, part, positionCategory, onFormChange: onPartChange, formError }: Props) => {
  const intl = useIntl();
  const [statusHelperIsOpen, setStatusHelperIsOpen] = useState(false);

  const onPositionCodeChange = (value: string | null) => {
    if (value) {
      onPartChange({ ...part, positionCode: value });
    }
  };
  const onDesignationChange = (value: { id: number; name: string; manufacturerName?: string } | null) => {
    if (value) {
      onPartChange({ ...part, productId: value.id, product: { designation: value.name, manufacturer: value.manufacturerName } });
    } else {
      onPartChange({ ...part, productId: null, product: null });
    }
  };
  const onQuantityChange = (event: any, value: number) => {
    onPartChange({ ...part, quantity: value });
  };
  const onStatusChange = (value: string) => {
    onPartChange({ ...part, status: value });
  };
  const onLabelChange = (value: string) => {
    onPartChange({ ...part, label: value });
  };
  const onUpgradeFlagChange = (event: any, checked: boolean) => {
    onPartChange({ ...part, upgradeFlag: checked ? 1 : 0 });
  };
  const onRemarkChange = (event: any, value: string) => {
    onPartChange({ ...part, remark: value });
  };

  const getSeverity = (fieldName: string): BaseSeverity | undefined => {
    return GetSeverityForPart(formError, fieldName);
  };

  const getHint = (fieldName: string): string | undefined => {
    return GetHintForPart(entityId, part.recordId, recordCategory, fieldName, changesetDetails, intl, formError);
  };

  const getDesignationItem = (): DesignationItem | undefined => {
    if (part && part.productId && part.product?.designation) {
      return {
        id: part.productId,
        name: part.product.designation,
        manufacturerName: part.product.manufacturer
      };
    }
  };

  return (
    <div className="">
      <div className="flex flex-wrap ">
        <div className="w-1/2 pl-2 pr-2">
          <PositionSelect
            positionCategory={positionCategory}
            label={intl.formatMessage({ id: 'part.positionCode' })}
            value={part.positionCode as Positions}
            onChange={onPositionCodeChange}
            hint={getHint('positionCode')}
            severity={getSeverity('positionCode')}
          />
          <Spacer />
        </div>
        <div className="w-1/2 pl-2 pr-2">
          <DesignationInput recordCategory={recordCategory} label={intl.formatMessage({ id: 'part.designation' })} value={getDesignationItem()} hint={getHint('designation')} onChange={onDesignationChange} />
          <Spacer />
        </div>
        <div className="w-1/2 pl-2 pr-2">
          <TextField disabled value={part.product?.manufacturer} feLabel={intl.formatMessage({ id: 'part.manufacturerName' })} feHint={getHint('manufacturerName')} feSeverity={getSeverity('manufacturerName')} />
          <Spacer />
        </div>
        <div className="w-1/2 pl-2 pr-2">
          <NumberField onChange={onQuantityChange} value={part.quantity} feLabel={intl.formatMessage({ id: 'part.quantity' })} feHint={getHint('quantity')} feSeverity={getSeverity('quantity')} />
          <Spacer />
        </div>
        <div className="w-1/2 pl-2 pr-2">
          <div className="mb-2 flex justify-between">
            <Select
              className="flex-1"
              feLabel={intl.formatMessage({ id: 'part.status' })}
              feItems={STATUS_OPTIONS.map((x) => {
                return { label: x, value: x };
              })}
              multiple={false}
              value={part.status}
              onChange={onStatusChange}
              feHint={getHint('status')}
              feSeverity={getSeverity('status')}
            />
            <IconInteractive className="flex-none" as="button" aria-label="Help" feIcon={Icons.UnknownCircle} onClick={() => setStatusHelperIsOpen(true)}></IconInteractive>
          </div>
          <Spacer />
        </div>
        <div className="w-1/2 pl-2 pr-2">
          <Select
            className="mb-4"
            feLabel={intl.formatMessage({ id: 'part.label' })}
            feItems={LABEL_OPTIONS.map((x) => {
              return { label: x, value: x };
            })}
            multiple={false}
            value={part.label}
            onChange={onLabelChange}
            feHint={getHint('label')}
            feSeverity={getSeverity('label')}
          />
          <Spacer />
        </div>
        <div className="w-1/2 pl-2 pr-2">
          <Checkbox className="mb-4" onChange={onUpgradeFlagChange} checked={part.upgradeFlag === 1} feLabel={intl.formatMessage({ id: 'part.upgradeFlag' })} />
          <Spacer />
        </div>
        <div className="w-full pl-2 pr-2">
          <TextAreaField rows={5} onChange={onRemarkChange} value={part.remark} feLabel={intl.formatMessage({ id: 'part.remark' })} feHint={getHint('remark')} feSeverity={getSeverity('remark')} />
          <Spacer />
        </div>
      </div>
      <Dialog cssWidth={'700px'} feOnClose={() => setStatusHelperIsOpen(false)} feTitle={intl.formatMessage({ id: 'part.status.helpText.heading' })} open={statusHelperIsOpen}>
        <StatusHelper />
      </Dialog>
    </div>
  );
};

interface Props {
  entityId: number;
  changesetDetails: ChangesetDetails | undefined;
  recordCategory: RecordCategory;
  part: RelationshipProduct;
  positionCategory: PositionsCategory;
  onFormChange: any;
  formError?: any;
}

export default PartForm;
