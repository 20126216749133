import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Changeset, ChangesetDetails, ChangesetStatus } from 'domain/index';
import { CommitChangeset, DeleteChangeset, DeleteRef, GetChangesetDiff, GetChangesets, PatchChangeset, PostChangeset, RemoveUserRequestAttachment } from 'services/api/changeset.service';
import _ from 'lodash';
import { RootState } from 'store/store';

export type ChangesetState = {
  changesets: Array<Changeset>;
  loading: boolean;
  addLoading: boolean;
  editLoading: boolean;
  editError?: string;
  error: string | undefined;
  currentETag?: string;
  currentChangeset?: ChangesetDetails;
};

const initialState: ChangesetState = {
  changesets: [],
  loading: false,
  addLoading: false,
  editLoading: false,
  error: undefined
};

export const getChangesets = createAsyncThunk('changeset/getChangesets', async (skipLoading: boolean = false) => {
  return (await GetChangesets()).data;
});

export const addChangeset = createAsyncThunk('changeset/addChangeset', async (callback: (changesetId: string) => void) => {
  const newChangeset = (await PostChangeset()).data;
  callback(newChangeset.changesetId);
  return newChangeset;
});

export const loadChangeset = createAsyncThunk('changeset/loadChangeset', async ({ changesetId }: { changesetId: string }) => {
  const response = await GetChangesetDiff(changesetId);
  return { changeset: response.data, eTag: response.headers.etag };
});

export const updateChangesetComment = createAsyncThunk('changeset/updateChangesetComment', async ({ changesetId, comment }: { changesetId: string; comment: string }, options) => {
  const eTag = (options.getState() as RootState).changesetData.currentETag ?? '';
  const response = await PatchChangeset(changesetId, eTag, undefined, comment);
  return { changesetId, comment, eTag: response.headers.etag };
});

export const updateChangesetStatus = createAsyncThunk('changeset/updateChangesetStatus', async ({ changesetId, status }: { changesetId: string; status: ChangesetStatus }, options) => {
  const eTag = (options.getState() as RootState).changesetData.currentETag ?? '';
  if (status === ChangesetStatus.COMMITTED) {
    const response = await CommitChangeset(changesetId, eTag);
    return { changesetId, status, eTag: response.headers.etag };
  } else {
    const response = await PatchChangeset(changesetId, eTag, status, undefined);
    return { changesetId, status, eTag: response.headers.etag };
  }
});

export const deleteChangesetRef = createAsyncThunk('changeset/deleteChangesetRef', async ({ changesetId, refId }: { changesetId: string; refId: number }, options) => {
  const eTag = (options.getState() as RootState).changesetData.currentETag ?? '';
  const response = await DeleteRef(changesetId, refId, eTag);
  return { changesetId, refId, eTag: response.headers.etag };
});

export const deleteChangesetAttachement = createAsyncThunk('changeset/deleteChangesetAttachement', async ({ changesetId, attachmentId }: { changesetId: string; attachmentId: string }, options) => {
  const eTag = (options.getState() as RootState).changesetData.currentETag ?? '';
  const response = await RemoveUserRequestAttachment(changesetId, attachmentId, eTag);
  return { changesetId, attachmentId, eTag: response.headers.etag };
});

export const deleteChangeset = createAsyncThunk('changeset/deleteChangeset', async ({ changesetId, callback }: { changesetId: string; callback: () => void }, options) => {
  const eTag = (options.getState() as RootState).changesetData.currentETag ?? '';
  await DeleteChangeset(changesetId, eTag);
  callback();
  return { changesetId };
});

export const changesetSlice = createSlice({
  name: 'changeset',
  initialState: initialState,
  reducers: {
    setChangesets: (state: ChangesetState, action: PayloadAction<{ changesets: Changeset[] }>) => {
      state.changesets = _.orderBy(action.payload.changesets, 'createdAt', 'desc');
    },
    setLoading: (state: ChangesetState, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setETag: (state: ChangesetState, action: PayloadAction<string>) => {
      state.currentETag = action.payload;
    },
    dismissEditError: (state: ChangesetState, action: PayloadAction) => {
      state.editError = undefined;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getChangesets.pending, (state, action) => {
        if (!action.meta.arg) {
          state.loading = true;
        }
      })
      .addCase(getChangesets.fulfilled, (state, action) => {
        state.changesets = _.orderBy(action.payload, 'createdAt', 'desc');
        state.loading = false;
        state.error = undefined;
      })
      .addCase(getChangesets.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        console.log(action.error.message);
      })
      .addCase(addChangeset.pending, (state, action) => {
        state.addLoading = true;
      })
      .addCase(addChangeset.fulfilled, (state, action) => {
        state.changesets = _.orderBy([...state.changesets, action.payload], 'createdAt', 'desc');
        state.addLoading = false;
        state.error = undefined;
      })
      .addCase(addChangeset.rejected, (state, action) => {
        state.addLoading = false;
        state.error = action.error.message;
        console.log(action.error.message);
      })
      .addCase(updateChangesetComment.pending, (state, action) => {
        state.editLoading = true;
      })
      .addCase(updateChangesetComment.fulfilled, (state, action) => {
        state.currentETag = action.payload.eTag;

        state.changesets = state.changesets.map((c) => (c.changesetId === action.payload.changesetId ? { ...c, comment: action.payload.comment } : c));
        console.log(action.payload.comment);
        if (state.currentChangeset) {
          state.currentChangeset = { ...state.currentChangeset, comment: action.payload.comment };
        }
        state.editLoading = false;
        state.editError = undefined;
      })
      .addCase(updateChangesetComment.rejected, (state, action) => {
        state.editLoading = false;
        state.editError = action.error.message;
        console.log(action.error.message);
      })
      .addCase(updateChangesetStatus.pending, (state, action) => {
        state.editLoading = true;
      })
      .addCase(updateChangesetStatus.fulfilled, (state, action) => {
        state.currentETag = action.payload.eTag;
        state.changesets = state.changesets.map((c) => (c.changesetId === action.payload.changesetId ? { ...c, status: action.payload.status } : c));
        if (state.currentChangeset) {
          state.currentChangeset = { ...state.currentChangeset, status: action.payload.status };
        }
        state.editLoading = false;
        state.editError = undefined;
      })
      .addCase(updateChangesetStatus.rejected, (state, action) => {
        state.editLoading = false;
        state.editError = action.error.message;
        console.log(action.error.message);
      })
      .addCase(loadChangeset.pending, (state, action) => {
        state.editLoading = true;
      })
      .addCase(loadChangeset.fulfilled, (state, action) => {
        state.currentETag = action.payload.eTag;
        state.currentChangeset = action.payload.changeset;
        state.editLoading = false;
        state.editError = undefined;
      })
      .addCase(loadChangeset.rejected, (state, action) => {
        state.editLoading = false;
        state.editError = action.error.message;
        console.log(action.error.message);
      })
      .addCase(deleteChangesetRef.pending, (state, action) => {
        state.editLoading = true;
      })
      .addCase(deleteChangesetRef.fulfilled, (state, action) => {
        state.currentETag = action.payload.eTag;
        state.changesets = state.changesets.map((c) =>
          c.changesetId === action.payload.changesetId
            ? {
                ...c,
                refs: c.refs.filter((r) => r.id !== action.payload.refId)
              }
            : c
        );
        if (state.currentChangeset) {
          state.currentChangeset = { ...state.currentChangeset, refs: state.currentChangeset.refs.filter((r) => r.id !== action.payload.refId) };
        }
        state.editLoading = false;
        state.editError = undefined;
      })
      .addCase(deleteChangesetRef.rejected, (state, action) => {
        state.editLoading = false;
        state.editError = action.error.message;
        console.log(action.error.message);
      })
      .addCase(deleteChangesetAttachement.pending, (state, action) => {
        state.editLoading = true;
      })
      .addCase(deleteChangesetAttachement.fulfilled, (state, action) => {
        state.currentETag = action.payload.eTag;
        state.changesets = state.changesets.map((c) =>
          c.changesetId === action.payload.changesetId
            ? {
                ...c,
                userRequest: c.userRequest
                  ? {
                      ...c.userRequest,
                      attachments: c.userRequest?.attachments?.filter((a) => a.attachmentId !== action.payload.attachmentId)
                    }
                  : undefined
              }
            : c
        );
        if (state.currentChangeset) {
          state.currentChangeset = {
            ...state.currentChangeset,
            userRequest: state.currentChangeset.userRequest
              ? {
                  ...state.currentChangeset.userRequest,
                  attachments: state.currentChangeset.userRequest?.attachments?.filter((a) => a.attachmentId !== action.payload.attachmentId)
                }
              : undefined
          };
        }
        state.editLoading = false;
        state.editError = undefined;
      })
      .addCase(deleteChangesetAttachement.rejected, (state, action) => {
        state.editLoading = false;
        state.editError = action.error.message;
        console.log(action.error.message);
      })
      .addCase(deleteChangeset.pending, (state, action) => {
        state.editLoading = true;
      })
      .addCase(deleteChangeset.fulfilled, (state, action) => {
        state.currentETag = undefined;
        state.changesets = state.changesets.filter((c) => c.changesetId !== action.payload.changesetId);
        state.currentChangeset = undefined;
        state.editLoading = false;
        state.editError = undefined;
      })
      .addCase(deleteChangeset.rejected, (state, action) => {
        state.editLoading = false;
        state.editError = action.error.message;
        console.log(action.error.message);
      });
  }
});

export const { setChangesets, setLoading, setETag, dismissEditError } = changesetSlice.actions;
export default changesetSlice.reducer;
