import RequireAuth from 'features/authentication/RequireAuth';
import { ReactNode } from 'react';
import PageLayout from './PageLayout';

const TwoColumnLayout = ({ left, right, isSecure }: { left: ReactNode; right: ReactNode; isSecure?: boolean }) => {
  const renderLayout = () => {
    return (
      <PageLayout>
        <div className="flex">
          <div className="w-1/4 pr-5">{left}</div>
          <div className="w-3/4">{right}</div>
        </div>
      </PageLayout>
    );
  };

  return isSecure ? <RequireAuth>{renderLayout()}</RequireAuth> : renderLayout();
};

export default TwoColumnLayout;
