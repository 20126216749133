export enum RecordCategory {
  Bearing = 'Bearing',
  Grease = 'Grease',
  Seal = 'Seal',
  DriveBearing = 'DriveBearing',
  DriveGrease = 'DriveGrease',
  DriveHousing = 'DriveHousing',
  DriveLubricationSystem = 'DriveLubricationSystem',
  DriveSeal = 'DriveSeal',
  GearboxCoupling = 'GearboxCoupling',
  GeneratorLubricationSystem = 'GeneratorLubricationSystem',
  RotorBearing = 'RotorBearing',
  RotorGrease = 'RotorGrease',
  RotorLubricationSystem = 'RotorLubricationSystem',
  RotorSeal = 'RotorSeal',
  TowerBearing = 'TowerBearing',
  TowerGrease = 'TowerGrease',
  TowerLubricationSystem = 'TowerLubricationSystem',
  TowerSeal = 'TowerSeal'
}
