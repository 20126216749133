import { AxiosResponse } from 'axios';
import { Gearbox, Generator, LogPost, Product, SelectOptions, StockingPoint, Turbine } from 'domain/index';
import { ObjectType } from '../../domain/shared/ObjectId';
import { getAppService } from './service.base';

const apiService = getAppService();

export const GetProduct = async (id: number): Promise<AxiosResponse<Product>> => {
  return apiService.withGet(`components/${id}`, 'Something went wrong while fetching product.');
};

export const GetStockingPoints = async (id: number): Promise<AxiosResponse<StockingPoint[]>> => {
  return apiService.withGet(`/components/${id}/stocking-points/`, 'Something went wrong while fetching stocking points.');
};

export const GetSelectOptions = async (fieldName: string, objectType: ObjectType, options: { productCategory?: string; gearboxType?: string }): Promise<AxiosResponse<SelectOptions[]>> => {
  const isProduct = objectType === ObjectType.PRODUCT;
  const entityType = isProduct ? 'component' : objectType.toLowerCase();
  return apiService.withGet(
    `metadata/${entityType}/${fieldName}/options${isProduct ? `?productCategory=${encodeURIComponent(options.productCategory ?? '')}` : ''}${fieldName === 'gearboxSubType' ? `?gearboxType=${options.gearboxType}` : ''}`,
    'Something went wrong while fetching select options.'
  );
};

export const GetStockingPointOptions = async (): Promise<AxiosResponse<StockingPoint[]>> => {
  return apiService.withGet(`/stocking-points`, 'Something went wrong while fetching stocking point options.');
};

export const GetLogForProduct = async (id: number): Promise<AxiosResponse<LogPost[]>> => {
  return apiService.withGet(`components/${id}/log`, 'Something went wrong while fetching log for product.');
};

export const GetGenerator = async (id: number): Promise<AxiosResponse<Generator>> => {
  return apiService.withGet(`generators/${id}`, 'Something went wrong while fetching generator.');
};

export const GetLogForGenerator = async (id: number): Promise<AxiosResponse<LogPost[]>> => {
  return apiService.withGet(`generators/${id}/log`, 'Something went wrong while fetching log for generator.');
};

export const GetGearbox = async (id: number): Promise<AxiosResponse<Gearbox>> => {
  return apiService.withGet(`gearboxes/${id}`, 'Something went wrong while fetching gearbox.');
};

export const GetLogForGearbox = async (id: number): Promise<AxiosResponse<LogPost[]>> => {
  return apiService.withGet(`gearboxes/${id}/log`, 'Something went wrong while fetching log for gearbox.');
};

export const GetTurbine = async (id: number): Promise<AxiosResponse<Turbine>> => {
  return apiService.withGet(`turbines/${id}`, 'Something went wrong while fetching turbine.');
};

export const GetLogForTurbine = async (id: number): Promise<AxiosResponse<LogPost[]>> => {
  return apiService.withGet(`turbines/${id}/log`, 'Something went wrong while fetching log for turbine.');
};
