import { NumberField, Spacer, TextAreaField, TextField, Checkbox } from '@skf/ferris';
import { BaseSeverity } from '@skf/ferris/dist/esm/common/types/severity';
import { ChangesetDetails, Generator } from 'domain/index';
import SelectWrapper from 'features/edit/shared/select-wrapper/SelectWrapper';
import { GetHint, GetSeverity } from 'features/edit/shared/utils/form/Form.utility';
import { useIntl } from 'react-intl';
import { ObjectType } from '../../../../domain/shared/ObjectId';
import ManufacturerSelect from 'features/edit/shared/manufacturer-select/ManufacturerSelect';

const GeneratorForm = ({ generator, editMode, onGeneratorChange, formError, changesetDetails }: Props) => {
  const intl = useIntl();
  const onGeneratorNameChange = (event: any, value: string) => {
    onGeneratorChange({ ...generator, generatorName: value });
  };
  const onManufacturerChange = (value: number, label: string) => {
    onGeneratorChange({ ...generator, manufacturerId: value, manufacturer: { manufacturerName: label } });
  };
  const onGeneratorTypeChange = (value: string) => {
    onGeneratorChange({ ...generator, generatorType: value });
  };
  const onPowerChange = (event: any, value: number) => {
    onGeneratorChange({ ...generator, power: value });
  };
  const onGridFrequencyChange = (event: any, value: number) => {
    onGeneratorChange({ ...generator, gridFrequency: value });
  };
  const onNominalRpmLowChange = (event: any, value: number | undefined) => {
    onGeneratorChange({ ...generator, nominalRpmLow: value });
  };
  const onNominalRpmHighChange = (event: any, value: number | undefined) => {
    onGeneratorChange({ ...generator, nominalRpmHigh: value });
  };
  const onIsolationTypeChange = (value: string) => {
    onGeneratorChange({ ...generator, isolationType: value });
  };
  const onFrequencyConverterChange = (value: string) => {
    onGeneratorChange({ ...generator, frequencyConverter: value });
  };
  const onRemarkChange = (event: any, value: string) => {
    onGeneratorChange({ ...generator, remark: value });
  };
  const onWebsiteLinkChange = (event: any, value: string) => {
    onGeneratorChange({ ...generator, websiteLink: value });
  };
  const onCompleteFlagChange = (event: any, value: boolean) => {
    if (value === true) {
      onGeneratorChange({ ...generator, completeFlag: 1 });
    } else {
      onGeneratorChange({ ...generator, completeFlag: 0 });
    }
  };
  const onDataApprovalChange = (event: any, value: boolean) => {
    if (value === true) {
      onGeneratorChange({ ...generator, dataApproval: 1 });
    } else {
      onGeneratorChange({ ...generator, dataApproval: 0 });
    }
  };
  const getSeverity = (fieldName: string): BaseSeverity | undefined => {
    return GetSeverity(generator.generatorId, formError, fieldName, changesetDetails);
  };
  const getHint = (fieldName: string): string | undefined => {
    return GetHint(generator.generatorId, formError, fieldName, changesetDetails, intl);
  };

  return (
    <div className="flex flex-wrap ">
      <div className="w-1/4 pl-2 pr-2">
        <TextField
          required
          disabled={!editMode}
          onChange={onGeneratorNameChange}
          value={generator.generatorName}
          feLabel={intl.formatMessage({ id: 'generator.generatorName' })}
          feHint={getHint('generatorName')}
          feSeverity={getSeverity('generatorName')}
        />
        <Spacer />
      </div>
      <div className="w-1/4 pl-2 pr-2">
        <ManufacturerSelect
          objectType={ObjectType.GENERATOR}
          labelId="generator.manufacturerId"
          required
          onChange={onManufacturerChange}
          editMode={editMode}
          initialValue={generator.manufacturer?.manufacturerName}
          value={generator.manufacturerId}
          hint={getHint('manufacturerName')}
          severity={getSeverity('manufacturerName')}
        />
        <Spacer />
      </div>
      <div className="w-1/4 pl-2 pr-2">
        <SelectWrapper
          objectType={ObjectType.GENERATOR}
          required
          value={generator?.generatorType}
          fieldName="generatorType"
          label={intl.formatMessage({ id: 'generator.generatorType' })}
          productCategory={'Generator'}
          onValueChange={onGeneratorTypeChange}
          editMode={editMode}
          hint={getHint('generatorType')}
          severity={getSeverity('generatorType')}
        />
        <Spacer />
      </div>
      <div className="w-1/4 pl-2 pr-2">
        <NumberField disabled={!editMode} required onChange={onPowerChange} value={generator.power} feLabel={intl.formatMessage({ id: 'generator.power' })} feHint={getHint('power')} feSeverity={getSeverity('power')} min={0} />
        <Spacer />
      </div>
      <div className="w-1/4 pl-2 pr-2">
        <NumberField
          disabled={!editMode}
          onChange={onGridFrequencyChange}
          value={generator.gridFrequency}
          feLabel={intl.formatMessage({ id: 'generator.gridFrequency' })}
          feHint={getHint('gridFrequency')}
          feSeverity={getSeverity('gridFrequency')}
          min={0}
        />
        <Spacer />
      </div>
      <div className="flex w-1/2 pl-2 pr-2">
        <div className="w-1/2 pr-4">
          <NumberField
            disabled={!editMode}
            onChange={onNominalRpmLowChange}
            value={generator.nominalRpmLow}
            feLabel={intl.formatMessage({ id: 'generator.nominalRpmLow' })}
            feHint={getHint('nominalRpmLow')}
            feSeverity={getSeverity('nominalRpmLow')}
            min={0}
          />
          <Spacer />
        </div>
        <div className="w-1/2">
          <NumberField
            disabled={!editMode}
            onChange={onNominalRpmHighChange}
            value={generator.nominalRpmHigh}
            feLabel={intl.formatMessage({ id: 'generator.nominalRpmHigh' })}
            feHint={getHint('nominalRpmHigh')}
            feSeverity={getSeverity('nominalRpmHigh')}
            min={0}
          />
          <Spacer />
        </div>
      </div>
      <div className="w-1/4 pl-2 pr-2">
        <SelectWrapper
          objectType={ObjectType.GENERATOR}
          value={generator?.isolationType}
          fieldName="isolationType"
          label={intl.formatMessage({ id: 'generator.isolationType' })}
          productCategory={'Generator'}
          onValueChange={onIsolationTypeChange}
          editMode={editMode}
          hint={getHint('isolationType')}
          severity={getSeverity('isolationType')}
        />
        <Spacer />
      </div>
      <div className="w-1/4 pl-2 pr-2">
        <SelectWrapper
          objectType={ObjectType.GENERATOR}
          value={generator?.frequencyConverter}
          fieldName="frequencyConverter"
          label={intl.formatMessage({ id: 'generator.frequencyConverter' })}
          productCategory={'Generator'}
          onValueChange={onFrequencyConverterChange}
          editMode={editMode}
          hint={getHint('frequencyConverter')}
          severity={getSeverity('frequencyConverter')}
        />
        <Spacer />
      </div>
      <div className="w-2/4 pl-2 pr-2">
        <TextField disabled={!editMode} onChange={onWebsiteLinkChange} value={generator.websiteLink} feLabel={intl.formatMessage({ id: 'generator.websiteLink' })} feHint={getHint('websiteLink')} feSeverity={getSeverity('websiteLink')} />
        <Spacer />
      </div>
      <div className="w-1/4"></div>
      <div className="w-2/4 pl-2 pr-2">
        <TextAreaField rows={5} disabled={!editMode} onChange={onRemarkChange} value={generator.remark} feLabel={intl.formatMessage({ id: 'generator.remark' })} feHint={getHint('remark')} feSeverity={getSeverity('remark')} />
        <Spacer />
      </div>
      <div className="w-1/4 pl-2 pr-2 mt-7">
        <Checkbox feLabel={intl.formatMessage({ id: 'generator.completeFlag' })} checked={generator.completeFlag === 1} disabled={!editMode} onChange={onCompleteFlagChange} />
        <Checkbox className="mt-4" feLabel={intl.formatMessage({ id: 'generator.dataApproval' })} checked={generator.dataApproval === 1} disabled={!editMode} onChange={onDataApprovalChange} />
      </div>
    </div>
  );
};

interface Props {
  generator: Generator;
  editMode: boolean;
  onGeneratorChange: any;
  formError: Map<string, string>;
  changesetDetails?: ChangesetDetails;
}

export default GeneratorForm;
