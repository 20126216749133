import { ChangesetStatus, DifferenceType, State } from 'domain/index';

const BASE_PRODUCT_FIELDS = ['productId', 'designation', 'productCategory', 'productType', 'application', 'manufacturer', 'properties', 'design', 'productInformation', 'remark', 'stockingPoint', 'otherFeatures'];

export const GetTraslationIdForField = (category: string, name: string): string => (BASE_PRODUCT_FIELDS.includes(name) ? `baseProduct.${name}` : `${firstCaseLower(category).replace(' ', '')}.${name}`);

export const GetTranslationIdForEntityCategory = (category: string): string => `entity.category.${removeLastCharIfS(category).toLowerCase().replace(' ', '')}`;

export const GetTraslationIdForEntityCategoryPlural = (category: string): string => `entity.category.${removeLastCharIfS(category).toLowerCase().replace(' ', '')}.plural`;

export const GetTraslationIdForChangesetStatus = (status: ChangesetStatus | 'ALL_STATUSES'): string => `changeset.status.${status.toLowerCase()}`;

export const GetTraslationIdForChangesetState = (state: State): string => `changeset.state.${state.toLowerCase()}`;

export const GetTraslationIdForChangesetDifferenceType = (type: DifferenceType): string => `changeset.differenceType.${type.toLowerCase()}`;

const firstCaseLower = (value: string): string => value.charAt(0).toLowerCase() + value.slice(1);

const removeLastCharIfS = (value: string): string => (value.endsWith('s') ? value.slice(0, value.length - 1) : value);
