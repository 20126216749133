import { Button, Text, Colors, Select, Icons } from '@skf/ferris';
import { useState } from 'react';
import { useAppSelector } from 'store/index';
import { useIntl } from 'react-intl';
import { ChangesetIsActive } from 'utils/index';
import { Changeset, ChangesetStatus } from '../../../../domain';

const FormActions = ({ entityId, loading, editMode, changesetId, formHasChanges, formError, onTicketActionClick, onEditClick, onSaveClick, onDeleteClick, onCancelClick, onCloseChangesetClick, newMode, onCancelAdd }: Props) => {
  const intl = useIntl();
  const changesets = useAppSelector((state) => state.changesetData.changesets);
  const changeset = useAppSelector((state) => state.changesetDetailsData.changesetDetails);
  const [chosenTicket, setChosenTicket] = useState<string>('');

  const decorateHasEntity = (cs: Changeset, id: number) => {
    if (cs.refs.some((r) => r.id === id)) {
      return {
        feIcon: Icons.InfoCircleFilled
      };
    }
    return {};
  };

  const saveIsDisabled = (): boolean => !formHasChanges || formError.size > 0;
  const changesetsOptions = (): { label: string; value: string; feIcon?: Icons }[] =>
    [{ label: 'New ticket', value: '' }].concat(changesets.filter((x) => ChangesetIsActive(x.status)).map((x) => ({ label: 'Ticket - ' + x.changesetId.slice(0, 5), value: x.changesetId, ...decorateHasEntity(x, entityId) })));
  if (editMode) {
    return (
      <div className="flex justify-end items-end mt-2">
        <div className="items-end">
          {!changesetId && !newMode && (
            <Text className="mb-2 block" feColor={Colors.RedBase}>
              {intl.formatMessage({ id: 'product.direct.commit.message' })}
            </Text>
          )}
          <Button className="float-right" feLoading={loading.save} disabled={saveIsDisabled()} feType={'primary'} onClick={onSaveClick}>
            {intl.formatMessage({ id: 'button.save' })}
          </Button>
          {!!changesetId && !newMode && (
            <Button className="float-right mr-4" feType={'primary'} feLoading={loading.delete} feDestructive onClick={onDeleteClick}>
              {intl.formatMessage({ id: 'button.delete' })}
            </Button>
          )}
          {!newMode && (
            <Button className="float-right mr-4" feType={'secondary'} onClick={onCancelClick}>
              {intl.formatMessage({ id: 'button.cancel' })}
            </Button>
          )}
          {newMode && (
            <Button className="float-right mr-4" feType={'secondary'} onClick={onCancelAdd}>
              {intl.formatMessage({ id: 'button.cancel' })}
            </Button>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="flex justify-end items-end mt-2">
      {!changesetId && (
        <>
          <Select className="w-96 mr-4" multiple={false} feLabel={'Choose ticket'} feItems={changesetsOptions()} value={chosenTicket} onChange={(value: string) => setChosenTicket(value)} feRows={7} />
          <Button disabled={chosenTicket === ''} feType="secondary" className="mr-4" onClick={() => onTicketActionClick(chosenTicket, 'open')}>
            Open in ticket
          </Button>
          <Button feType="primary" feLoading={loading.editInTicket} className="mr-4" onClick={() => onTicketActionClick(chosenTicket, 'edit')}>
            Edit in ticket
          </Button>
        </>
      )}
      {changeset?.status !== ChangesetStatus.COMMITTED && (
        <Button feType={changesetId ? 'primary' : 'secondary'} onClick={onEditClick}>
          Edit
        </Button>
      )}
      {changeset?.status === ChangesetStatus.COMMITTED && (
        <Button className="ml-4" feType={'secondary'} onClick={(event: any) => (onCloseChangesetClick ? onCloseChangesetClick(event) : alert())}>
          Close changeset
        </Button>
      )}
    </div>
  );
};

interface Props {
  entityId: number;
  loading: { delete: boolean; save: boolean; editInTicket: boolean };
  editMode: boolean;
  changesetId?: string;
  formHasChanges: boolean;
  formError: Map<string, string>;
  onTicketActionClick: (changesetId: string, action: 'open' | 'edit') => void;
  onEditClick?: () => void;
  onSaveClick?: (event: any) => void;
  onDeleteClick?: () => void;
  onCancelClick?: () => void;
  onCloseChangesetClick?: (event: any) => void;
  newMode?: boolean;
  onCancelAdd?: any;
}

export default FormActions;
