import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Generator } from 'domain/index';
import { CreateGeneratorInChangeset, DeleteGenerator, GetChangesetDiff, GetGenerator, GetGeneratorFromChangeset } from 'services/api';
import { PostChangeset, PostChangesetRef } from 'services/api';
import { getChangesetDetails, resetProductPartsOriginal } from 'features/edit/shared';
import { getChangesets, removeBookmark } from 'store';
import _ from 'lodash';
import { AdditionalObjectDetailsChanges, AdditionalObjectTurbineChanges } from '../../../shared/edit/AdditionalObjectChanges';
import { SaveChangesService } from 'services/api/changeset.service.helper';
import { ObjectType } from 'domain/shared/ObjectId';
import { EditWarning } from 'domain/shared/Warning';

export type EditGeneratorState = {
  generator: Generator;
  generatorOriginal: Generator;
  editMode: boolean;
  eTag: string;
  loading: { generator: boolean; delete: boolean; save: boolean; editInTicket: boolean };
  initError: string | undefined;
  error: string | undefined;
  warnings: EditWarning[];
};

const initialState: EditGeneratorState = {
  generator: {} as Generator,
  generatorOriginal: {} as Generator,
  editMode: false,
  eTag: '',
  loading: { generator: false, delete: false, save: false, editInTicket: false },
  initError: undefined,
  error: undefined,
  warnings: []
};

export const getGenerator = createAsyncThunk('generator/getGenerator', async (options: { generatorId: number; changesetId: string | undefined }) => {
  if (!!options.changesetId) {
    const response = await GetGeneratorFromChangeset(options.changesetId, options.generatorId);
    return { data: response.data, etag: response.headers.etag };
  }
  const response = await GetGenerator(options.generatorId);
  return { data: response.data, etag: '' };
});

export const deleteGenerator = createAsyncThunk('generator/deleteGenerator', async (options: { generatorId: number; changesetId: string | undefined; eTag: string }, thunkAPI) => {
  thunkAPI.dispatch(setLoading({ delete: true }));
  try {
    await DeleteGenerator(options.changesetId ?? '', options.generatorId, options.eTag);
    if (!!options.changesetId) {
      thunkAPI.dispatch(getChangesetDetails({ changesetId: options.changesetId }));
    }
    thunkAPI.dispatch(getChangesets(false));
  } catch (error: any) {
    thunkAPI.dispatch(setError({ error: error?.message ?? 'Failed', loading: { delete: false } }));
  }
  thunkAPI.dispatch(setLoading({ delete: false }));
});

export const editInTicket = createAsyncThunk('generator/editInTicket', async (options: { entityId: number; changesetId: string; generatorName: string }, thunkAPI) => {
  thunkAPI.dispatch(setLoading({ editInTicket: true }));
  try {
    if (_.isEmpty(options.changesetId)) {
      const responsePostChaneset = await PostChangeset();
      thunkAPI.dispatch(setETag(responsePostChaneset.headers.etag));
      const responsePostRef = await PostChangesetRef(responsePostChaneset.data.changesetId, options.entityId, options.generatorName ?? '', 'Generator', responsePostChaneset.headers.etag);
      thunkAPI.dispatch(getChangesets(false));
      thunkAPI.dispatch(removeBookmark({ id: options.entityId }));
      thunkAPI.dispatch(setLoading({ editInTicket: false }));
      return responsePostRef.data.changesetId;
    } else {
      const responseGetChangeset = await GetChangesetDiff(options.changesetId);
      thunkAPI.dispatch(setETag(responseGetChangeset.headers.etag));
      const responsePostRef = await PostChangesetRef(options.changesetId, options.entityId, options.generatorName ?? '', 'Generator', responseGetChangeset.headers.etag);
      thunkAPI.dispatch(getChangesets(false));
      thunkAPI.dispatch(removeBookmark({ id: options.entityId }));
      thunkAPI.dispatch(setLoading({ editInTicket: false }));
      return responsePostRef.data.changesetId;
    }
  } catch (error: any) {
    thunkAPI.dispatch(setError({ error: error?.message ?? 'Failed', loading: { editInTicket: false } }));
  }
  thunkAPI.dispatch(setLoading({ editInTicket: false }));
});

export const putChanges = createAsyncThunk(
  'generator/putChanges',
  async (
    options: {
      entityId: number;
      changesetId: string | undefined;
      generatorDiff: any;
      additionalChanges: AdditionalObjectTurbineChanges & AdditionalObjectDetailsChanges;
      isAutoCommit: boolean;
      etag: string;
      newMode: boolean | undefined;
    },
    thunkAPI
  ) => {
    const saveService = new SaveChangesService({
      ...options,
      type: ObjectType.GENERATOR,
      createItemInChangeset: async (changesetId: string, item: Generator, etag: string) => {
        const responseCreateGearbox = await CreateGeneratorInChangeset(changesetId, item, etag);
        return {
          entityId: responseCreateGearbox.data.generatorId,
          etag: responseCreateGearbox.headers.etag
        };
      },
      actions: {
        preSave: () => {
          thunkAPI.dispatch(setLoading({ save: true }));
          thunkAPI.dispatch(resetWarnings());
        },
        setETag: (etag: string) => thunkAPI.dispatch(setETag(etag)),
        loadChangesetData: (changesetId: string) => {
          thunkAPI.dispatch(getChangesetDetails({ changesetId }));
          thunkAPI.dispatch(getChangesets(false));
        },
        postSave: () => {
          thunkAPI.dispatch(setEditMode({ editMode: false }));
          thunkAPI.dispatch(resetGeneratorOriginal());
          thunkAPI.dispatch(resetProductPartsOriginal());
          thunkAPI.dispatch(setLoading({ save: false }));
        },
        onError: (error) => thunkAPI.dispatch(setError({ error, loading: { save: false } })),
        onChangesetUpdateWarning: (warning: EditWarning) => thunkAPI.dispatch(setWarning(warning))
      }
    });

    const commandBuilder = saveService
      .getCommandBuilder()
      .addObjectDetails(options.additionalChanges.addObjectDetails)
      .updateObjectDetails(options.additionalChanges.updateObjectDetails)
      .removeObjectDetails(options.additionalChanges.removeObjectDetails)
      .addObjectTurbineDetails(options.additionalChanges.addTurbineDetails)
      .updateObjectTurbineDetails(options.additionalChanges.updateTurbineDetails)
      .removeObjectTurbineDetails(options.additionalChanges.removeTurbineDetails);

    try {
      const result = await saveService.save(options.generatorDiff as Generator, commandBuilder);
      return { status: 'ok', changesetId: result.changesetId, entityId: result.entityId };
    } catch (error: any) {
      thunkAPI.dispatch(setError({ error: error?.message ?? 'Failed', loading: { save: false } }));
    }
  }
);

export const editGeneratorSlice = createSlice({
  name: 'generator',
  initialState: initialState,
  reducers: {
    resetGeneratorState: (state: EditGeneratorState) => {
      state.generator = {} as Generator;
      state.generatorOriginal = {} as Generator;
      state.editMode = false;
      state.eTag = '';
      state.loading = { generator: false, delete: false, save: false, editInTicket: false };
      state.initError = undefined;
      state.error = undefined;
    },
    setEditMode: (state: EditGeneratorState, action: PayloadAction<{ editMode: boolean }>) => {
      state.editMode = action.payload.editMode;
    },
    setGenerator: (state: EditGeneratorState, action: PayloadAction<Partial<{ generator: Generator }>>) => {
      state.generator = { ...state.generator, ...action.payload.generator };
    },
    setGeneratorOriginal: (state: EditGeneratorState, action: PayloadAction<{ generator: Generator }>) => {
      state.generatorOriginal = action.payload.generator;
    },
    resetGeneratorOriginal: (state: EditGeneratorState) => {
      state.generatorOriginal = state.generator;
    },
    setLoading: (state: EditGeneratorState, action: PayloadAction<Partial<{ generator: boolean; delete: boolean; save: boolean; editInTicket: boolean; parts: boolean }>>) => {
      state.loading = { ...state.loading, ...action.payload };
    },
    setETag: (state: EditGeneratorState, action: PayloadAction<string>) => {
      state.eTag = action.payload;
    },
    setError: (state: EditGeneratorState, action: PayloadAction<{ error: string; loading: Partial<{ generator: boolean; changeset: boolean; delete: boolean; save: boolean; editInTicket: boolean; parts: boolean }> }>) => {
      state.loading = { ...state.loading, ...action.payload.loading };
      state.error = action.payload.error;
    },
    setWarning: (state: EditGeneratorState, action: PayloadAction<EditWarning>) => {
      state.warnings = [...state.warnings, action.payload];
    },
    resetWarnings: (state: EditGeneratorState, action: PayloadAction) => {
      state.warnings = [];
    },
    resetWarning: (state: EditGeneratorState, action: PayloadAction<EditWarning>) => {
      state.warnings = state.warnings.filter((w) => w.type !== action.payload.type);
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getGenerator.pending, (state, action) => {
        state.loading = { ...state.loading, generator: true };
      })
      .addCase(getGenerator.fulfilled, (state, action) => {
        state.generator = action.payload.data;
        state.generatorOriginal = action.payload.data;
        state.eTag = action.payload.etag;
        state.loading = { ...state.loading, generator: false };
      })
      .addCase(getGenerator.rejected, (state, action) => {
        state.loading = { ...state.loading, generator: false };
        state.error = action.error.message;
        state.initError = action.error.message;
      });
  }
});

export const { resetGeneratorState, setLoading, setError, setETag, setEditMode, setGenerator, setGeneratorOriginal, resetGeneratorOriginal, setWarning, resetWarnings, resetWarning } = editGeneratorSlice.actions;
export default editGeneratorSlice.reducer;
