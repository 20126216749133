import React from 'react';
import { useIntl } from 'react-intl';
import { Time } from '@skf/ferris';
import { GetNameFromEmail, GetTraslationIdForField } from 'utils/index';
import { User } from 'domain/index';

const ChangeLog = ({ category, name, modifiedAt, modifiedBy, children }: Props) => {
  const intl = useIntl();

  return (
    <div className="pb-2 flex">
      <div className="whitespace-nowrap pr-4">
        {!!modifiedAt && <Time className="pr-2" dateTime={modifiedAt} feFormat="date" />}
        {GetNameFromEmail(modifiedBy)}
      </div>
      <div>
        {!!name && <span className="font-bold">{`${intl.formatMessage({ id: `${GetTraslationIdForField(category, name)}` })}: `}</span>}
        {children}
      </div>
    </div>
  );
};

type Props = {
  category: string;
  name?: string;
  modifiedAt?: string;
  modifiedBy?: User;
  children?: React.ReactNode;
};

export default ChangeLog;
