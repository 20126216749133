import { RelationshipProduct, RecordCategory, Positions, PositionsCategory } from 'domain/index';
import _ from 'lodash';

const MAIN_SHAFT_LIST = [Positions.GENERAL, Positions.MS_GS, Positions.MS_RS, Positions.MS];
const MAIN_SHAFT_SEAL_LIST = [Positions.GENERAL, Positions.S_MS_RS, Positions.S_MS_GS, Positions.S_MS, Positions.MS];
const YAW_LIST = [Positions.GENERAL, Positions.YAW_BEARING, Positions.YAW_DRIVE];
const PITCH_LIST = [Positions.GENERAL, Positions.PITCH_BEARING, Positions.PITCH_DRIVE, Positions.BLADE];
const GENERATOR_LIST = [Positions.GENERAL, Positions.DE, Positions.NDE];
const GEARBOX_LIST = [
  Positions.GENERAL,
  Positions.HS_S_RS,
  Positions.HS_S_RS_i,
  Positions.HS_S_RS_o,
  Positions.HS_S2_RS,
  Positions.HS_S3_RS,
  Positions.HS_S_RS_a,
  Positions.HS_S_RS_r,
  Positions.HS_S_GS,
  Positions.HS_S_GS_i,
  Positions.HS_S_GS_o,
  Positions.HS_S2_GS,
  Positions.HS_S3_GS,
  Positions.HS_S_GS_a,
  Positions.HS_S_GS_r,
  Positions.HIS_S_RS,
  Positions.HIS_S_RS_i,
  Positions.HIS_S_RS_o,
  Positions.HIS_S_GS,
  Positions.HIS_S_GS_i,
  Positions.HIS_S_GS_o,
  Positions.HIS_P,
  Positions.HIS_C,
  Positions.HIS_C_RS,
  Positions.HIS_C_GS,
  Positions.IS_S_RS,
  Positions.IS_S_RS_i,
  Positions.IS_S_RS_o,
  Positions.IS_S_GS,
  Positions.IS_S_GS_i,
  Positions.IS_S_GS_o,
  Positions.IS_P,
  Positions.IS_C,
  Positions.IS_C_RS,
  Positions.IS_C_GS,
  Positions.IS_R,
  Positions.IS_R_RS,
  Positions.IS_R_GS,
  Positions.LIS_S_RS,
  Positions.LIS_S_RS_i,
  Positions.LIS_S_RS_o,
  Positions.LIS_S_GS,
  Positions.LIS_S_GS_i,
  Positions.LIS_S_GS_o,
  Positions.LIS_P,
  Positions.LIS_C,
  Positions.LIS_C_GS,
  Positions.LIS_C_RS,
  Positions.LS_S_RS,
  Positions.LS_S_GS,
  Positions.LS_P,
  Positions.LS_C_RS,
  Positions.LS_C_GS,
  Positions.PITCH_TUBE
];

export const GetPositionListByCategory = (category: PositionsCategory): Positions[] => {
  switch (category) {
    case PositionsCategory.MAIN_SHAFT:
      return MAIN_SHAFT_LIST;
    case PositionsCategory.MAIN_SHAFT_SEAL:
      return MAIN_SHAFT_SEAL_LIST;
    case PositionsCategory.YAW:
      return YAW_LIST;
    case PositionsCategory.PITCH:
      return PITCH_LIST;
    case PositionsCategory.GENERATOR:
      return GENERATOR_LIST;
    case PositionsCategory.GEARBOX:
      return GEARBOX_LIST;
    default:
      return [];
  }
};

export const FilterParts = (parts: RelationshipProduct[], recordCategory: RecordCategory): RelationshipProduct[] => _.filter(parts, (x) => x.recordCategory === recordCategory);
