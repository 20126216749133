import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Turbine } from 'domain/index';
import { DeleteTurbine, GetChangesetDiff } from 'services/api';
import { GetTurbine, GetTurbineFromChangeset, PostChangeset, PostChangesetRef, CreateTurbineInChangeset } from 'services/api';
import { getChangesetDetails, resetProductPartsOriginal } from 'features/edit/shared';
import { getChangesets, removeBookmark } from 'store';
import _ from 'lodash';
import { AdditionalObjectDetailsChanges, AdditionalObjectGearboxChanges, AdditionalObjectGeneratorChanges, AdditionalObjectTurbineChanges } from '../../../shared/edit/AdditionalObjectChanges';
import { SaveChangesService } from 'services/api/changeset.service.helper';
import { ObjectType } from 'domain/shared/ObjectId';
import { EditWarning } from 'domain/shared/Warning';

export type EditTurbineState = {
  turbine: Turbine;
  turbineOriginal: Turbine;
  editMode: boolean;
  eTag: string;
  loading: { turbine: boolean; delete: boolean; save: boolean; editInTicket: boolean };
  initError: string | undefined;
  error: string | undefined;
  warnings: EditWarning[];
};

const initialState: EditTurbineState = {
  turbine: {} as Turbine,
  turbineOriginal: {} as Turbine,
  editMode: false,
  eTag: '',
  loading: { turbine: false, delete: false, save: false, editInTicket: false },
  initError: undefined,
  error: undefined,
  warnings: []
};

export const getTurbine = createAsyncThunk('turbine/getTurbine', async (options: { turbineId: number; changesetId: string | undefined }) => {
  if (!!options.changesetId) {
    const response = await GetTurbineFromChangeset(options.changesetId, options.turbineId);
    return { data: response.data, etag: response.headers.etag };
  }
  const response = await GetTurbine(options.turbineId);
  return { data: response.data, etag: '' };
});

export const getChangeset = createAsyncThunk('turbine/getChangeset', async (options: { changesetId: string | undefined }) => {
  return (await GetChangesetDiff(options.changesetId)).data;
});

export const deleteTurbine = createAsyncThunk('turbine/deleteTurbine', async (options: { turbineId: number; changesetId: string | undefined; eTag: string }, thunkAPI) => {
  thunkAPI.dispatch(setLoading({ delete: true }));
  try {
    await DeleteTurbine(options.changesetId ?? '', options.turbineId, options.eTag);
    if (!!options.changesetId) {
      thunkAPI.dispatch(getChangesetDetails({ changesetId: options.changesetId }));
    }
    thunkAPI.dispatch(getChangesets(false));
  } catch (error: any) {
    thunkAPI.dispatch(setError({ error: error?.message ?? 'Failed', loading: { delete: false } }));
  }
  thunkAPI.dispatch(setLoading({ delete: false }));
});

export const editInTicket = createAsyncThunk('turbine/editInTicket', async (options: { entityId: number; changesetId: string; turbineName: string; etag: string }, thunkAPI) => {
  thunkAPI.dispatch(setLoading({ editInTicket: true }));
  try {
    if (_.isEmpty(options.changesetId)) {
      const responsePostChaneset = await PostChangeset();
      thunkAPI.dispatch(setETag(responsePostChaneset.headers.etag));
      const responsePostRef = await PostChangesetRef(responsePostChaneset.data.changesetId, options.entityId, options.turbineName ?? '', 'Turbine', responsePostChaneset.headers.etag);
      thunkAPI.dispatch(getChangesets(false));
      thunkAPI.dispatch(removeBookmark({ id: options.entityId }));
      thunkAPI.dispatch(setLoading({ editInTicket: false }));
      return responsePostRef.data.changesetId;
    } else {
      const responseGetChangeset = await GetChangesetDiff(options.changesetId);
      thunkAPI.dispatch(setETag(responseGetChangeset.headers.etag));
      const responsePostRef = await PostChangesetRef(options.changesetId, options.entityId, options.turbineName ?? '', 'Turbine', responseGetChangeset.headers.etag);
      thunkAPI.dispatch(getChangesets(false));
      thunkAPI.dispatch(removeBookmark({ id: options.entityId }));
      thunkAPI.dispatch(setLoading({ editInTicket: false }));
      return responsePostRef.data.changesetId;
    }
  } catch (error: any) {
    thunkAPI.dispatch(setError({ error: error?.message ?? 'Failed', loading: { editInTicket: false } }));
  }
  thunkAPI.dispatch(setLoading({ editInTicket: false }));
});

export const saveChanges = createAsyncThunk(
  'turbine/saveChanges',
  async (
    options: {
      entityId: number;
      changesetId: string | undefined;
      turbineDiff: any;
      additionalChanges: AdditionalObjectDetailsChanges & AdditionalObjectGearboxChanges & AdditionalObjectGeneratorChanges;
      isAutoCommit: boolean;
      etag: string;
      newMode: boolean | undefined;
    },
    thunkAPI
  ) => {
    const saveService = new SaveChangesService({
      ...options,
      type: ObjectType.TURBINE,
      createItemInChangeset: async (changesetId: string, item: Turbine, etag: string) => {
        const responseCreateGearbox = await CreateTurbineInChangeset(changesetId, item, etag);
        return {
          entityId: responseCreateGearbox.data.turbineId,
          etag: responseCreateGearbox.headers.etag
        };
      },
      actions: {
        preSave: () => {
          thunkAPI.dispatch(setLoading({ save: true }));
          thunkAPI.dispatch(resetWarnings());
        },
        setETag: (etag: string) => thunkAPI.dispatch(setETag(etag)),
        loadChangesetData: (changesetId: string) => {
          thunkAPI.dispatch(getChangesetDetails({ changesetId }));
          thunkAPI.dispatch(getChangesets(false));
        },
        postSave: () => {
          thunkAPI.dispatch(setEditMode({ editMode: false }));
          thunkAPI.dispatch(resetTurbineOriginal());
          thunkAPI.dispatch(resetProductPartsOriginal());
          thunkAPI.dispatch(setLoading({ save: false }));
        },
        onError: (error) => thunkAPI.dispatch(setError({ error, loading: { save: false } })),
        onChangesetUpdateWarning: (warning: EditWarning) => thunkAPI.dispatch(setWarning(warning))
      }
    });

    const commandBuilder = saveService
      .getCommandBuilder()
      .addObjectDetails(options.additionalChanges.addObjectDetails)
      .updateObjectDetails(options.additionalChanges.updateObjectDetails)
      .removeObjectDetails(options.additionalChanges.removeObjectDetails)
      .addObjectGearboxDetails(options.additionalChanges.addGearboxDetails)
      .updateObjectGearboxDetails(options.additionalChanges.updateGearboxDetails)
      .removeObjectGearboxDetails(options.additionalChanges.removeGearboxDetails)
      .addObjectGeneratorDetails(options.additionalChanges.addGeneratorDetails)
      .updateObjectGeneratorDetails(options.additionalChanges.updateGeneratorDetails)
      .removeObjectGeneratorDetails(options.additionalChanges.removeGeneratorDetails);

    try {
      const result = await saveService.save(options.turbineDiff as Turbine, commandBuilder);
      return { status: 'ok', changesetId: result.changesetId, entityId: result.entityId };
    } catch (error: any) {
      thunkAPI.dispatch(setError({ error: error?.message ?? 'Failed', loading: { save: false } }));
    }
  }
);

export const editTurbineSlice = createSlice({
  name: 'turbine',
  initialState: initialState,
  reducers: {
    resetTurbineState: (state: EditTurbineState) => {
      state.turbine = {} as Turbine;
      state.turbineOriginal = {} as Turbine;
      state.editMode = false;
      state.eTag = '';
      state.loading = { turbine: false, delete: false, save: false, editInTicket: false };
      state.initError = undefined;
      state.error = undefined;
    },
    setEditMode: (state: EditTurbineState, action: PayloadAction<{ editMode: boolean }>) => {
      state.editMode = action.payload.editMode;
    },
    setTurbine: (state: EditTurbineState, action: PayloadAction<Partial<{ turbine: Turbine }>>) => {
      state.turbine = { ...state.turbine, ...action.payload.turbine };
    },
    setTurbineOriginal: (state: EditTurbineState, action: PayloadAction<{ turbine: Turbine }>) => {
      state.turbineOriginal = action.payload.turbine;
    },
    resetTurbineOriginal: (state: EditTurbineState) => {
      state.turbineOriginal = state.turbine;
    },
    setLoading: (state: EditTurbineState, action: PayloadAction<Partial<{ turbine: boolean; changeset: boolean; delete: boolean; save: boolean; editInTicket: boolean }>>) => {
      state.loading = { ...state.loading, ...action.payload };
    },
    setETag: (state: EditTurbineState, action: PayloadAction<string>) => {
      state.eTag = action.payload;
    },
    setError: (state: EditTurbineState, action: PayloadAction<{ error: string; loading: Partial<{ turbine: boolean; changeset: boolean; delete: boolean; save: boolean; editInTicket: boolean }> }>) => {
      state.loading = { ...state.loading, ...action.payload.loading };
      state.error = action.payload.error;
    },
    setWarning: (state: EditTurbineState, action: PayloadAction<EditWarning>) => {
      state.warnings = [...state.warnings, action.payload];
    },
    resetWarnings: (state: EditTurbineState, action: PayloadAction) => {
      state.warnings = [];
    },
    resetWarning: (state: EditTurbineState, action: PayloadAction<EditWarning>) => {
      state.warnings = state.warnings.filter((w) => w.type !== action.payload.type);
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getTurbine.pending, (state, action) => {
        state.loading = { ...state.loading, turbine: true };
      })
      .addCase(getTurbine.fulfilled, (state, action) => {
        state.turbine = action.payload.data;
        state.turbineOriginal = action.payload.data;
        state.eTag = action.payload.etag;
        state.loading = { ...state.loading, turbine: false };
      })
      .addCase(getTurbine.rejected, (state, action) => {
        state.loading = { ...state.loading, turbine: false };
        state.error = action.error.message;
        state.initError = action.error.message;
      });
  }
});

export const { resetTurbineState, setLoading, setError, setETag, setEditMode, setTurbine, setTurbineOriginal, resetTurbineOriginal, setWarning, resetWarnings, resetWarning } = editTurbineSlice.actions;
export default editTurbineSlice.reducer;
