import { AxiosResponse, AxiosResponseHeaders } from 'axios';
import {
  Changeset,
  ChangesetDetails,
  ChangesetStatus,
  AnyDifference,
  DifferenceType,
  PropertyChange,
  Gearbox,
  Generator,
  LogPost,
  Product,
  ProductCategory,
  SelectOptions,
  Refs,
  Relationship,
  StockingPoint,
  Turbine,
  User,
  PropertyDifference,
  SearchResultByType,
  RelationshipProduct
} from 'domain/index';

const entityService = require('../../services/api/entity.service');
const changesetService = require('../../services/api/changeset.service');
const relationshipService = require('../../services/api/relationship.service');

export const MockApiCalls = () => {
  jest.spyOn(entityService, 'GetProduct').mockImplementation(
    () =>
      new Promise<AxiosResponse<Product>>((resolve) => {
        resolve(dummyResponseProduct);
      })
  );
  jest.spyOn(entityService, 'GetStockingPoints').mockImplementation(
    () =>
      new Promise<AxiosResponse<StockingPoint[]>>((resolve) => {
        resolve(dummyResponseStockingPoint);
      })
  );
  jest.spyOn(entityService, 'GetGenerator').mockImplementation(
    () =>
      new Promise<AxiosResponse<Generator>>((resolve) => {
        resolve(dummyResponseGenerator);
      })
  );
  jest.spyOn(entityService, 'GetGearbox').mockImplementation(
    () =>
      new Promise<AxiosResponse<Gearbox>>((resolve) => {
        resolve(dummyResponseGearbox);
      })
  );
  jest.spyOn(entityService, 'GetTurbine').mockImplementation(
    () =>
      new Promise<AxiosResponse<Turbine>>((resolve) => {
        resolve(dummyResponseTurbine);
      })
  );
  jest.spyOn(changesetService, 'GetProductFromChangeset').mockImplementation(
    () =>
      new Promise<AxiosResponse<Product>>((resolve) => {
        resolve(dummyResponseProduct);
      })
  );
  jest.spyOn(changesetService, 'GetGearboxFromChangeset').mockImplementation(
    () =>
      new Promise<AxiosResponse<Gearbox>>((resolve) => {
        resolve(dummyResponseGearbox);
      })
  );
  jest.spyOn(changesetService, 'GetGeneratorFromChangeset').mockImplementation(
    () =>
      new Promise<AxiosResponse<Generator>>((resolve) => {
        resolve(dummyResponseGenerator);
      })
  );
  jest.spyOn(changesetService, 'GetTurbineFromChangeset').mockImplementation(
    () =>
      new Promise<AxiosResponse<Turbine>>((resolve) => {
        resolve(dummyResponseTurbine);
      })
  );
  jest.spyOn(changesetService, 'GetChangesets').mockImplementation(
    () =>
      new Promise<AxiosResponse<Changeset[]>>((resolve) => {
        resolve(dummyResponseChangesets);
      })
  );
  jest.spyOn(changesetService, 'GetChangeset').mockImplementation(
    () =>
      new Promise<AxiosResponse<Changeset>>((resolve) => {
        resolve(dummyResponseChangeset);
      })
  );
  jest.spyOn(changesetService, 'GetChangesetDiff').mockImplementation(
    () =>
      new Promise<AxiosResponse<ChangesetDetails>>((resolve) => {
        resolve(dummyResponseChangesetDiff);
      })
  );
  jest.spyOn(changesetService, 'GetStockingPointsFromChangeset').mockImplementation(
    () =>
      new Promise<AxiosResponse<StockingPoint[]>>((resolve) => {
        resolve(dummyResponseStockingPoint);
      })
  );
  jest.spyOn(changesetService, 'PostChangeset').mockImplementation(
    () =>
      new Promise<AxiosResponse<Changeset>>((resolve) => {
        resolve(dummyResponseChangeset);
      })
  );
  jest.spyOn(changesetService, 'PostChangesetRef').mockImplementation(
    () =>
      new Promise<AxiosResponse<Changeset>>((resolve) => {
        resolve(dummyResponseChangeset);
      })
  );
  jest.spyOn(changesetService, 'PatchChangeset').mockImplementation(
    () =>
      new Promise<AxiosResponse<Changeset>>((resolve) => {
        resolve(dummyResponseChangeset);
      })
  );
  jest.spyOn(changesetService, 'CommitChangeset').mockImplementation(
    () =>
      new Promise<AxiosResponse<Changeset>>((resolve) => {
        resolve(dummyResponseChangeset);
      })
  );
  jest.spyOn(changesetService, 'DeleteChangeset').mockImplementation(
    () =>
      new Promise<AxiosResponse<string>>((resolve) => {
        resolve(dummyResponseString);
      })
  );
  jest.spyOn(entityService, 'GetSelectOptions').mockImplementation(
    () =>
      new Promise<AxiosResponse<SelectOptions[]>>((resolve) => {
        resolve(dummyResponseSelectOptions);
      })
  );
  jest.spyOn(entityService, 'GetStockingPointOptions').mockImplementation(
    () =>
      new Promise<AxiosResponse<StockingPoint[]>>((resolve) => {
        resolve(dummyResponseStockingPoint);
      })
  );
  jest.spyOn(entityService, 'GetLogForProduct').mockImplementation(
    () =>
      new Promise<AxiosResponse<LogPost[]>>((resolve) => {
        resolve(dummyResponseLogPost);
      })
  );
  jest.spyOn(entityService, 'GetLogForGenerator').mockImplementation(
    () =>
      new Promise<AxiosResponse<LogPost[]>>((resolve) => {
        resolve(dummyResponseLogPost);
      })
  );
  jest.spyOn(entityService, 'GetLogForGearbox').mockImplementation(
    () =>
      new Promise<AxiosResponse<LogPost[]>>((resolve) => {
        resolve(dummyResponseLogPost);
      })
  );
  jest.spyOn(entityService, 'GetLogForTurbine').mockImplementation(
    () =>
      new Promise<AxiosResponse<LogPost[]>>((resolve) => {
        resolve(dummyResponseLogPost);
      })
  );
  jest.spyOn(relationshipService, 'GetRelatedComponentsForGearbox').mockImplementation(
    () =>
      new Promise<AxiosResponse<Relationship[]>>((resolve) => {
        resolve(dummyResponseRelationships);
      })
  );
  jest.spyOn(relationshipService, 'GetRelatedComponentsForGenerator').mockImplementation(
    () =>
      new Promise<AxiosResponse<Relationship[]>>((resolve) => {
        resolve(dummyResponseRelationships);
      })
  );
  jest.spyOn(relationshipService, 'GetRelatedComponentsForTurbine').mockImplementation(
    () =>
      new Promise<AxiosResponse<Relationship[]>>((resolve) => {
        resolve(dummyResponseRelationships);
      })
  );
  jest.spyOn(relationshipService, 'GetRelatedGearboxesForComponent').mockImplementation(
    () =>
      new Promise<AxiosResponse<Relationship[]>>((resolve) => {
        resolve(dummyResponseRelationships);
      })
  );
  jest.spyOn(relationshipService, 'GetRelatedGeneratorsForComponent').mockImplementation(
    () =>
      new Promise<AxiosResponse<Relationship[]>>((resolve) => {
        resolve(dummyResponseRelationships);
      })
  );
  jest.spyOn(relationshipService, 'GetRelatedTurbinesForComponent').mockImplementation(
    () =>
      new Promise<AxiosResponse<Relationship[]>>((resolve) => {
        resolve(dummyResponseRelationships);
      })
  );
  jest.spyOn(relationshipService, 'GetRelatedGearboxesForTurbine').mockImplementation(
    () =>
      new Promise<AxiosResponse<Relationship[]>>((resolve) => {
        resolve(dummyResponseRelationships);
      })
  );
  jest.spyOn(relationshipService, 'GetRelatedGeneratorsForTurbine').mockImplementation(
    () =>
      new Promise<AxiosResponse<Relationship[]>>((resolve) => {
        resolve(dummyResponseRelationships);
      })
  );
  jest.spyOn(relationshipService, 'GetRelatedTurbinesForGearbox').mockImplementation(
    () =>
      new Promise<AxiosResponse<Relationship[]>>((resolve) => {
        resolve(dummyResponseRelationships);
      })
  );
  jest.spyOn(relationshipService, 'GetRelatedTurbinesForGenerator').mockImplementation(
    () =>
      new Promise<AxiosResponse<Relationship[]>>((resolve) => {
        resolve(dummyResponseRelationships);
      })
  );
};

const dummyChangesets = [] as Changeset[];
const dummyResponseChangesets = { data: dummyChangesets, headers: { eTag: 'W/1' } as AxiosResponseHeaders } as AxiosResponse<Changeset[]>;

const dummyChangeset = { changesetId: '1', status: ChangesetStatus.NEW, refs: [] as Refs[] } as Changeset;
const dummyResponseChangeset = { data: dummyChangeset, headers: { eTag: 'W/1' } as AxiosResponseHeaders } as AxiosResponse<Changeset>;

const dummyChangesetDiff = {
  changesetId: '1',
  status: ChangesetStatus.NEW,
  differences: [{ type: DifferenceType.PROPERTIES_UPDATED, properties: [] as PropertyChange[], ref: { id: 1, name: '123', type: ProductCategory.BEARING } } as PropertyDifference] as AnyDifference[],
  createdBy: {} as User,
  refs: [] as Refs[],
  lastModifiedAt: '2022-03-01',
  createdAt: '2022-02-02'
} as ChangesetDetails;
const dummyResponseChangesetDiff = { data: dummyChangesetDiff, headers: { eTag: 'W/1' } as AxiosResponseHeaders } as AxiosResponse<ChangesetDetails>;

const dummyProduct = { productId: 500, productCategory: ProductCategory.BEARING } as Product;
const dummyResponseProduct = { data: dummyProduct, headers: { eTag: 'W/1' } as AxiosResponseHeaders } as AxiosResponse<Product>;

const dummyGenerator = { generatorId: 1, manufacturer: { manufacturerName: '' } } as Generator;
const dummyResponseGenerator = { data: dummyGenerator, headers: { eTag: 'W/1' } as AxiosResponseHeaders } as AxiosResponse<Generator>;

const dummyGearbox = { gearboxId: 1, manufacturer: { manufacturerName: '' } } as Gearbox;
const dummyResponseGearbox = { data: dummyGearbox, headers: { eTag: 'W/1' } as AxiosResponseHeaders } as AxiosResponse<Gearbox>;

const dummyTurbine = { turbineId: 1, manufacturer: { manufacturerName: '' } } as Turbine;
const dummyResponseTurbine = { data: dummyTurbine, headers: { eTag: 'W/1' } as AxiosResponseHeaders } as AxiosResponse<Turbine>;

const dummyStockingPoint = [] as StockingPoint[];
const dummyResponseStockingPoint = { data: dummyStockingPoint, headers: { eTag: 'W/1' } as AxiosResponseHeaders } as AxiosResponse<StockingPoint[]>;

const dummySelectOptions = [{ label: 'label', value: 'value' }] as SelectOptions[];
const dummyResponseSelectOptions = { data: dummySelectOptions, headers: { eTag: 'W/1' } as AxiosResponseHeaders } as AxiosResponse<SelectOptions[]>;

const dummyResponseString = { data: 'ok', headers: { eTag: 'W/1' } as AxiosResponseHeaders } as AxiosResponse<string>;

const dummyLogPosts = [{ comment: 'Comment', logDate: '2022-08-28', loggedBy: 'Admin', logId: 1, objectId: 2 }] as LogPost[];
const dummyResponseLogPost = { data: dummyLogPosts, headers: { eTag: 'W/1' } as AxiosResponseHeaders } as AxiosResponse<LogPost[]>;

const dummyRelationships = [] as Relationship[];
const dummyResponseRelationships = { data: dummyRelationships, headers: { eTag: 'W/1' } as AxiosResponseHeaders } as AxiosResponse<Relationship[]>;

const dummyRelationshipsProducts = [] as RelationshipProduct[];
const dummyResponseRelationshipProducts = { data: dummyRelationships, headers: { eTag: 'W/1' } as AxiosResponseHeaders } as AxiosResponse<RelationshipProduct[]>;

const dummySearchResults = [] as SearchResultByType[];
const dummyResponseSearchResult = { data: dummySearchResults } as AxiosResponse<SearchResultByType[]>;
