import { Alert, Icons } from '@skf/ferris';
import { useIntl } from 'react-intl';

const RemovedOverlay = ({ show }: Props) => {
  const intl = useIntl();

  return (
    <>
      {show && (
        <>
          <div className="absolute w-full z-10 top-11 bg-gray-200 bg-opacity-75 transition-opacity" style={{ height: 'calc(100% - 44px)' }}></div>
          <Alert feIcon={Icons.Warning} feSeverity="warning">
            {intl.formatMessage({ id: 'removed.overlay.info' })}
          </Alert>
        </>
      )}
    </>
  );
};

interface Props {
  show: boolean;
}

export default RemovedOverlay;
