import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Bookmark, ProductCategory } from 'domain/index';
import { ObjectType } from '../../domain/shared/ObjectId';

export type BookmarksState = {
  bookmarks: Bookmark[];
};

const initialState: BookmarksState = {
  bookmarks: []
};

export const bookmarksSlice = createSlice({
  name: 'bookmarks',
  initialState: initialState,
  reducers: {
    addBookmark: (state: BookmarksState, action: PayloadAction<{ id: number; category: ObjectType | ProductCategory; name: string }>) => {
      if (!state.bookmarks.some((x) => x.id === action.payload.id)) {
        state.bookmarks = state.bookmarks.concat([{ id: action.payload.id, category: action.payload.category, name: action.payload.name }]);
      }
    },
    removeBookmark: (state: BookmarksState, action: PayloadAction<{ id: number }>) => {
      state.bookmarks = state.bookmarks.filter((x) => x.id !== action.payload.id);
    }
  }
});

export const { addBookmark, removeBookmark } = bookmarksSlice.actions;
export default bookmarksSlice.reducer;
