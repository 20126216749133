import TwoColumnLayout from 'components/layout/TwoColumnLayout';
import { ReactNode } from 'react';
import BookmarkCard from './BookmarkCard';
import TicketCard from './TicketCard';

const TwoColumnTicketLayout = ({ children }: { children: ReactNode }) => {
  return (
    <TwoColumnLayout
      isSecure
      left={
        <>
          <TicketCard />
          <BookmarkCard />
        </>
      }
      right={children}
    />
  );
};

export default TwoColumnTicketLayout;
