export const ValueIsDate = (value: string | undefined, fieldName: string): boolean => {
  const dateFieldNames = ['productionStartDate', 'productionEndDate'];
  if (ValueIsEmpty(value)) {
    return false;
  }
  if (dateFieldNames.includes(fieldName)) {
    return true;
  }
  return false;
};

export const ValueIsEmpty = (value: string | undefined): boolean => {
  if (!value || value === 'null' || value === 'undefined' || value === '') {
    return true;
  }
  return false;
};
