import axios from 'axios';
import { GetUserRequestAttachmentDownloadUrl } from './changeset.service';

const client = axios.create();

export const DownloadAttachment = async (changesetId: string, attachmentId: string) => {
  const response = await GetUserRequestAttachmentDownloadUrl(changesetId, attachmentId);
  return client.get(response.data.downloadUrl, {
    responseType: 'blob'
  });
};
