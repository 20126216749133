import { Pagination, Sorting } from './SearchQuery';
import { ObjectType } from '../../../domain/shared/ObjectId';

export interface HitDocument {
  manufacturer: string;
}

export interface Gearbox extends HitDocument {
  manufacturer: string;
  gearboxType: string;
  completeFlag: number;
}

export interface Turbine extends HitDocument {
  manufacturer: string;
  driveType: string;
  turbineType: string;
  completeFlag: number;
  country: string[];
  numberOfTurbines: number;
}

export interface Generator extends HitDocument {
  manufacturer: string;
  generatorType: string;
}

export interface Product extends HitDocument {
  manufacturer: string;
  productAvailabilityFlag: false;
  productAvailabilityQuantity: 0;
  productCategory: string;
  productType: string;
  productTurbineName: string[];
  productTurbineIds: number[];
  productGearboxName: string[];
  productGearboxIds: number[];
  productGeneratorName: string[];
  productGeneratorIds: number[];
  productPosition: string[];
}

export interface Hit {
  score: number;
  id: number;
  name: string;
  created: Date;
  modified: Date;
  createdBy: string;
  modifiedBy: string;
  objectType: ObjectType;
  emptyFields: string[];
  preview: string;
  document: Gearbox | Turbine | Generator | Product;
}

export enum FacetType {
  DateFilter = 'DateFilter',
  MultiChoiceOrFilter = 'MultiChoiceOrFilter',
  MultiChoiceAndFilter = 'MultiChoiceAndFilter',
  ValueFilter = 'ValueFilter',
  BoolFilter = 'BoolFilter'
}
export interface Facet {
  field: string;
  type: FacetType;
}

export interface FacetWithValues extends Facet {
  values: { value: string; count: number }[];
}
export interface MultiChoiceFacet extends FacetWithValues {
  selected: string[];
}

export interface MultiChoiceOrFacet extends MultiChoiceFacet {}

export interface MultiChoiceAndFacet extends MultiChoiceFacet {}

export interface BoolFacet extends Facet {
  values: { value?: boolean; count: number }[];
  hint: string;
  state?: boolean;
}

export interface ValueFacet extends FacetWithValues {
  selected: string;
}

export interface DateFacet extends Facet {
  minValue: string;
  maxValue: string;
  selected?: {
    minValue: string;
    maxValue: string;
  };
}

export interface SearchResult {
  total: number;
  pagination?: Pagination;
  sort?: Sorting;
  hits: Hit[];
  facets: Array<MultiChoiceOrFacet | MultiChoiceAndFacet | ValueFacet | DateFacet | BoolFacet>;
}
