import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RelationshipProduct } from 'domain/index';
import { GetRelatedComponentsForGearbox, GetRelatedComponentsForGenerator, GetRelatedComponentsForTurbine } from 'services/api';
import { UpdateListByAction } from 'features/edit/shared';
import { EntityState, stateAsSaved } from '../../entity-state';

export type RelationshipProductsState = {
  productParts: RelationshipProduct[];
  productPartsOriginal: RelationshipProduct[];
  productPartsAreInitialized: boolean;
  loading: boolean;
  error: string | undefined;
};

const initialState: RelationshipProductsState = {
  productParts: [],
  productPartsOriginal: [],
  productPartsAreInitialized: false,
  loading: false,
  error: undefined
};

export const getRelatedComponents = createAsyncThunk('turbine/getRelatedComponents', async (options: { type: 'Turbine' | 'Generator' | 'Gearbox'; id: number; changesetId: string | undefined }) => {
  if (options.type === 'Turbine') {
    return (await GetRelatedComponentsForTurbine(options.id, options.changesetId)).data;
  }
  if (options.type === 'Generator') {
    return (await GetRelatedComponentsForGenerator(options.id, options.changesetId)).data;
  }
  if (options.type === 'Gearbox') {
    return (await GetRelatedComponentsForGearbox(options.id, options.changesetId)).data;
  }
  return [];
});

export const relationshipProductsSlice = createSlice({
  name: 'turbine',
  initialState: initialState,
  reducers: {
    resetProductPartsState: (state: RelationshipProductsState) => {
      state.productParts = [];
      state.productPartsOriginal = [];
      state.productPartsAreInitialized = false;
      state.loading = false;
      state.error = undefined;
    },
    setProductPartsMetadataStateAsSaved: (state: RelationshipProductsState) => {
      state.productParts = state.productParts.map((pp) => {
        if (pp.metadata?.state) {
          pp.metadata.state = stateAsSaved(pp.metadata.state);
        }
        return pp;
      });
    },
    resetProductParts: (state: RelationshipProductsState) => {
      state.productParts = state.productPartsOriginal;
    },
    resetProductPartsOriginal: (state: RelationshipProductsState) => {
      state.productPartsOriginal = state.productParts;
    },
    setProductParts: (state: RelationshipProductsState, action: PayloadAction<{ value: RelationshipProduct; index: number; action: 'Add' | 'Edit' | 'Delete' }>) => {
      state.productParts = UpdateListByAction([...state.productParts], action.payload.value, action.payload.index, action.payload.action);
    },
    setProductPartsOriginal: (state: RelationshipProductsState, action: PayloadAction<{ productParts: RelationshipProduct[] }>) => {
      state.productPartsOriginal = action.payload.productParts;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getRelatedComponents.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getRelatedComponents.fulfilled, (state, action) => {
        state.loading = false;
        state.productParts = action.payload;
        state.productPartsOriginal = action.payload;
        state.productPartsAreInitialized = true;
        state.error = undefined;
      })
      .addCase(getRelatedComponents.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

export const { resetProductPartsState, resetProductParts, setProductParts, resetProductPartsOriginal, setProductPartsMetadataStateAsSaved } = relationshipProductsSlice.actions;
export default relationshipProductsSlice.reducer;
