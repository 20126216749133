import { Switch } from '@skf/ferris';
import { BoolFilter } from 'features/search/domain/SearchQuery';
import { BoolFacet } from 'features/search/domain/SearchResult';
import { useBoolFilterValueFilter, useFilterLabel } from 'features/search/hooks/useFilterLabel';
import { setBoolValue } from 'features/search/store';
import { useAppDispatch } from 'store';
import { SearchFacetProps } from '../SearchFilter';
import FilterCollapse from './FilterCollapse';

const SearchBooleanFacet = ({ facet, activeFacet }: SearchFacetProps<BoolFacet, BoolFilter>) => {
  const dispatch = useAppDispatch();
  const label = useFilterLabel(facet.field);
  const getFilterLabel = useBoolFilterValueFilter();

  const onSelectKeyword = (value: boolean | undefined, checked: boolean) => {
    if (checked) {
      dispatch(setBoolValue({ facet: activeFacet ? activeFacet.facet : facet, value }));
    } else {
      dispatch(setBoolValue({ facet: activeFacet ? activeFacet.facet : facet }));
    }
  };

  const getCheckBoxFor = (value: boolean | undefined, count: number, index: number) => {
    return <Switch key={`facet${value}${index}`} className="mb-2" feLabel={`${getFilterLabel(facet.field, value)} (${count})`} onChange={(_event, checked) => onSelectKeyword(value, checked)} feSize="sm" checked={facet.state === value} />;
  };

  return (
    <FilterCollapse hide={facet.values.length === 0 && !activeFacet} defaultExpanded={activeFacet ? true : false} label={label}>
      {(activeFacet ? activeFacet.facet : facet).values.map((v, i) => getCheckBoxFor(v.value, v.count, i))}
    </FilterCollapse>
  );
};

export default SearchBooleanFacet;
