import { useIntl } from 'react-intl';
import { Button, Dialog, Flexbox, Spacer, Spacings } from '@skf/ferris';
import GearboxForm from '../form/GearboxForm';
import { RelationGearbox } from 'domain/index';

const GearboxDialog = ({ gearboxToEdit, isOpen, onFormChange, onSave, onCancel, formError }: Props) => {
  const intl = useIntl();
  const saveIsDisabled = (): boolean => (formError ? formError?.size > 0 : false);

  return (
    <>
      <Dialog cssWidth={'40rem'} cssHeight={'31rem'} feInterruptive feTitle={!!gearboxToEdit.objectId ? intl.formatMessage({ id: 'gearbox.form.edit' }) : intl.formatMessage({ id: 'gearbox.form.add' })} open={isOpen}>
        <GearboxForm
          gearbox={gearboxToEdit}
          onFormChange={(value: RelationGearbox) => {
            onFormChange(value);
          }}
          formError={formError}
        />
        <Spacer />
        <Flexbox feGap={Spacings.Md} feJustifyContent="flex-end">
          <Button feType="secondary" onClick={onCancel}>
            {intl.formatMessage({ id: 'windTurbine.form.cancel' })}
          </Button>
          <Button onClick={onSave} disabled={false}>
            {intl.formatMessage({ id: 'windTurbine.form.save' })}
          </Button>
        </Flexbox>
      </Dialog>
    </>
  );
};

interface Props {
  gearboxToEdit: RelationGearbox;
  isOpen: boolean;
  onFormChange: any;
  onSave: any;
  onCancel: any;
  formError?: Map<string, string>;
}

export default GearboxDialog;
