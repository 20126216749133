import { Card, Heading, Link } from '@skf/ferris';
import { useNavigate } from 'react-router';
import { useIntl } from 'react-intl';

const PageNotFound = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <Card className="m-5">
      <div className="text-center">
        <Heading as={'h1'}>{intl.formatMessage({ id: 'page.not.found.heading' })}</Heading>
        <p className="mb-2">{intl.formatMessage({ id: 'page.not.found.text' })}</p>
        <Link as="a" href="/" onClick={(_, route) => navigate(route)}>
          {intl.formatMessage({ id: 'page.not.found.link' })}
        </Link>
      </div>
    </Card>
  );
};

export default PageNotFound;
