import { Card, Heading } from '@skf/ferris';
import { useIntl } from 'react-intl';

const NotAllowedPage = () => {
  const intl = useIntl();

  return (
    <Card className="m-5">
      <div className="text-center">
        <Heading as={'h1'}>{intl.formatMessage({ id: 'not.allowed.heading' })}</Heading>
        <p className="mb-2">{intl.formatMessage({ id: 'not.allowed.text.level1' })}</p>
        <p className="mb-2">{intl.formatMessage({ id: 'not.allowed.text.level2' })}</p>
      </div>
    </Card>
  );
};

export default NotAllowedPage;
