import { ChangesetDetails, DifferenceType, ObjectDetailsDifference, ObjectDetailsDifferenceUpdated, PropertyChange, PropertyDifference, RecordCategory } from 'domain/index';

export const GetChangeOnField = (productId: number, fieldName: string, changesetDetails?: ChangesetDetails): PropertyChange | undefined => {
  if (!!changesetDetails) {
    const difference = changesetDetails.differences.find((x) => x.ref.id === productId) as PropertyDifference;
    if (!!difference) {
      return difference.properties?.find((x) => x.name === fieldName);
    }
  }
  return undefined;
};

export const GetHasChangesOnParts = (productId: number, recordCategories: RecordCategory[], changesetDetails?: ChangesetDetails): boolean => {
  const [added, updated, removed] = GetChangesOnParts(productId, recordCategories, changesetDetails);
  return added.length > 0 || updated.length > 0 || removed.length > 0;
};

export const GetChangesOnParts = (productId: number, recordCategories: RecordCategory[], changesetDetails?: ChangesetDetails) => {
  if (!!changesetDetails) {
    const difference = changesetDetails.differences.find((x) => x.ref.id === productId && x.type === DifferenceType.OBJECT_DETAILS_UPDATED) as ObjectDetailsDifference;
    if (!!difference) {
      const added = difference.added?.filter((x) => recordCategories.includes(x.recordCategory));
      const updated = difference.updated?.filter((x) => recordCategories.includes(x.recordCategory));
      const removed = difference.removed?.filter((x) => recordCategories.includes(x.recordCategory));
      return [added ?? ([] as ObjectDetailsDifferenceUpdated[]), updated ?? ([] as ObjectDetailsDifferenceUpdated[]), removed ?? ([] as ObjectDetailsDifferenceUpdated[])] as const;
    }
  }
  return [[] as ObjectDetailsDifferenceUpdated[], [] as ObjectDetailsDifferenceUpdated[], [] as ObjectDetailsDifferenceUpdated[]] as const;
};

export const GetChangesDescriptionOnParts = (entityId: number, recordCategories: RecordCategory[], changesetDetails?: ChangesetDetails): string => {
  const [added, updated, removed] = GetChangesOnParts(entityId, recordCategories, changesetDetails);
  let text = '';
  text = added.length > 0 ? text.concat(`Added: ${added.map((x) => x.name).join(', ')}. `) : text;
  text = removed.length > 0 ? text.concat(`Removed: ${removed.map((x) => x.name).join(', ')}. `) : text;
  text = updated.length > 0 ? text.concat(`Updated: ${updated.map((x) => x.name).join(', ')}.`) : text;
  return text;
};
