import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RelationshipTurbine } from 'domain/index';
import { GetRelatedTurbinesForComponent } from 'services/api';

export type RelationTurbinesState = {
  turbines: RelationshipTurbine[];
  turbinesOriginal: RelationshipTurbine[];
  turbinesAreInitialized: boolean;
  loading: boolean;
  error: string | undefined;
};

const initialState: RelationTurbinesState = {
  turbines: [],
  turbinesOriginal: [],
  turbinesAreInitialized: false,
  loading: false,
  error: undefined
};

export const getRelatedTurbines = createAsyncThunk('relationshipTurbines/getRelatedTurbines', async (options: { id: number; changesetId?: string }) => {
  return (await GetRelatedTurbinesForComponent(options.id, options.changesetId)).data;
});

export const relationshipTurbinesSlice = createSlice({
  name: 'relationshipTurbines',
  initialState: initialState,
  reducers: {
    resetRelationsshipTurbinesState: (state: RelationTurbinesState) => {
      state.turbines = [];
      state.turbinesOriginal = [];
      state.turbinesAreInitialized = false;
      state.loading = false;
      state.error = undefined;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getRelatedTurbines.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getRelatedTurbines.fulfilled, (state, action) => {
        state.loading = false;
        state.turbines = action.payload;
        state.turbinesOriginal = action.payload;
        state.turbinesAreInitialized = true;
        state.error = undefined;
      })
      .addCase(getRelatedTurbines.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

export const { resetRelationsshipTurbinesState } = relationshipTurbinesSlice.actions;
export default relationshipTurbinesSlice.reducer;
