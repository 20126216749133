import { NumberField, Spacer } from '@skf/ferris';
import { BaseSeverity } from '@skf/ferris/dist/esm/common/types/severity';
import { ChangesetDetails, Seal } from 'domain/index';
import { useIntl } from 'react-intl';
import { GetHint, GetSeverity, SelectWrapper } from '../../shared/index';
import { ObjectType } from '../../../../domain/shared/ObjectId';

const SealForm = ({ seal, editMode, onSealChange, formError, changesetDetails }: Props) => {
  const intl = useIntl();
  const onInnerDiameterChange = (event: any, value: number) => {
    onSealChange({ ...seal, innerDiameter: value });
  };
  const onOuterDiameterChange = (event: any, value: number) => {
    onSealChange({ ...seal, outerDiameter: value });
  };
  const onWidthChange = (event: any, value: number) => {
    onSealChange({ ...seal, width: value });
  };
  const onWeightChange = (event: any, value: number) => {
    onSealChange({ ...seal, weight: value });
  };
  const onMaterialChange = (value: string) => {
    onSealChange({ ...seal, material: value });
  };

  const getSeverity = (fieldName: string): BaseSeverity | undefined => {
    return GetSeverity(seal.productId, formError, fieldName, changesetDetails);
  };

  const getHint = (fieldName: string): string | undefined => {
    return GetHint(seal.productId, formError, fieldName, changesetDetails, intl);
  };

  return (
    <div className="flex flex-wrap ">
      <div className="w-1/4 pl-2 pr-2">
        <NumberField
          disabled={!editMode}
          onChange={onInnerDiameterChange}
          value={seal.innerDiameter}
          feLabel={intl.formatMessage({ id: 'seal.innerDiameter' })}
          feHint={getHint('innerDiameter')}
          feSeverity={getSeverity('innerDiameter')}
          min={0}
        />
        <Spacer />
      </div>
      <div className="w-1/4 pl-2 pr-2">
        <NumberField
          disabled={!editMode}
          onChange={onOuterDiameterChange}
          value={seal.outerDiameter}
          feLabel={intl.formatMessage({ id: 'seal.outerDiameter' })}
          feHint={getHint('outerDiameter')}
          feSeverity={getSeverity('outerDiameter')}
          min={0}
        />
        <Spacer />
      </div>
      <div className="w-1/4 pl-2 pr-2">
        <NumberField disabled={!editMode} onChange={onWidthChange} value={seal.width} feLabel={intl.formatMessage({ id: 'seal.width' })} feHint={getHint('width')} feSeverity={getSeverity('width')} min={0} />
        <Spacer />
      </div>
      <div className="w-1/4 pl-2 pr-2">
        <NumberField disabled={!editMode} onChange={onWeightChange} value={seal.weight} feLabel={intl.formatMessage({ id: 'seal.weight' })} feHint={getHint('weight')} feSeverity={getSeverity('weight')} min={0} />
        <Spacer />
      </div>
      <div className="w-1/4 pl-2 pr-2">
        <SelectWrapper
          objectType={ObjectType.PRODUCT}
          value={seal.material}
          fieldName="material"
          label={intl.formatMessage({ id: 'seal.material' })}
          productCategory="Seal"
          onValueChange={onMaterialChange}
          editMode={editMode}
          hint={getHint('material')}
          severity={getSeverity('material')}
        />
        <Spacer />
      </div>
    </div>
  );
};

interface Props {
  seal: Seal;
  editMode: boolean;
  onSealChange: any;
  formError: Map<string, string>;
  changesetDetails?: ChangesetDetails;
}

export default SealForm;
