import { ChangesetStatus } from 'domain/index';
import { useIntl } from 'react-intl';
import { GetColorForStatus, GetTraslationIdForChangesetStatus } from 'utils/index';

const ChangesetStatusTag = ({ status, fitTable }: Props) => {
  const intl = useIntl();
  const getStatusText = (): string => `${!fitTable ? intl.formatMessage({ id: 'changeset.status.prefix' }) : ''} ${intl.formatMessage({ id: GetTraslationIdForChangesetStatus(status) })}`;

  return (
    <div>
      <div className={`flex pr-5 ${fitTable ? '-ml-4' : ''}`}>
        <span className={`w-4 h-100 mr-2 ${GetColorForStatus(status)}`}></span>
        <span className="pt-4 pb-4">{getStatusText()}</span>
      </div>
    </div>
  );
};

interface Props {
  status: ChangesetStatus;
  fitTable?: boolean;
}

export default ChangesetStatusTag;
