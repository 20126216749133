export enum Positions {
  GENERAL = 'General',
  NDE = 'NDE (Generator)',
  DE = 'DE (Generator)',
  HS_S_RS = 'HS-S-RS (Gearbox)',
  HS_S_RS_i = 'HS-S-RS-i (Gearbox)',
  HS_S_RS_o = 'HS-S-RS-o (Gearbox)',
  HS_S2_RS = 'HS-S2-RS (Gearbox)',
  HS_S3_RS = 'HS-S3-RS (Gearbox)',
  HS_S_RS_a = 'HS-S-RS-a (Gearbox)',
  HS_S_RS_r = 'HS-S-RS-r (Gearbox)',
  HS_S_GS = 'HS-S-GS (Gearbox)',
  HS_S_GS_i = 'HS-S-GS-i (Gearbox)',
  HS_S_GS_o = 'HS-S-GS-o (Gearbox)',
  HS_S2_GS = 'HS-S2-GS (Gearbox)',
  HS_S3_GS = 'HS-S3-GS (Gearbox)',
  HS_S_GS_a = 'HS-S-GS-a (Gearbox)',
  HS_S_GS_r = 'HS-S-GS-r (Gearbox)',
  HIS_S_RS = 'HIS-S-RS (Gearbox)',
  HIS_S_RS_i = 'HIS-S-RS-i (Gearbox)',
  HIS_S_RS_o = 'HIS-S-RS-o (Gearbox)',
  HIS_S_GS = 'HIS-S-GS (Gearbox)',
  HIS_S_GS_i = 'HIS-S-GS-i (Gearbox)',
  HIS_S_GS_o = 'HIS-S-GS-o (Gearbox)',
  HIS_P = 'HIS-P (Gearbox)',
  HIS_C = 'HIS-C (Gearbox)',
  HIS_C_RS = 'HIS-C-RS (Gearbox)',
  HIS_C_GS = 'HIS-C-GS (Gearbox)',
  IS_S_RS = 'IS-S-RS (Gearbox)',
  IS_S_RS_i = 'IS-S-RS-i (Gearbox)',
  IS_S_RS_o = 'IS-S-RS-o (Gearbox)',
  IS_S_GS = 'IS-S-GS (Gearbox)',
  IS_S_GS_i = 'IS-S-GS-i (Gearbox)',
  IS_S_GS_o = 'IS-S-GS-o (Gearbox)',
  IS_P = 'IS-P (Gearbox)',
  IS_C = 'IS-P (Gearbox)',
  IS_C_RS = 'IS-C-RS (Gearbox)',
  IS_C_GS = 'IS-C-GS (Gearbox)',
  IS_R = 'IS-R (Gearbox)',
  IS_R_RS = 'IS-R-RS (Gearbox)',
  IS_R_GS = 'IS-R-GS (Gearbox)',
  LIS_S_RS = 'LIS-S-RS (Gearbox)',
  LIS_S_RS_i = 'LIS-S-RS-i (Gearbox)',
  LIS_S_RS_o = 'LIS-S-RS-o (Gearbox)',
  LIS_S_GS = 'LIS-S-GS (Gearbox)',
  LIS_S_GS_i = 'LIS-S-GS-i (Gearbox)',
  LIS_S_GS_o = 'LIS-S-GS-o (Gearbox)',
  LIS_P = 'LIS-P (Gearbox)',
  LIS_C = 'LIS-C (Gearbox)',
  LIS_C_GS = 'LIS-C-GS (Gearbox)',
  LIS_C_RS = 'LIS-C-RS (Gearbox)',
  LS_S_RS = 'LS-S-RS (Gearbox)',
  LS_S_GS = 'LS-S-GS (Gearbox)',
  LS_P = 'LS-P (Gearbox)',
  LS_C_RS = 'LS-C-RS (Gearbox)',
  LS_C_GS = 'LS-C-GS (Gearbox)',
  MAIN_BEARING = 'Main bearing (Gearbox)',
  PITCH_TUBE = 'Pitch tube (Gearbox)',
  PUMP = 'Pump (Gearbox)',
  MS_GS = 'MS-GS (Main shaft)',
  MS_RS = 'MS-RS (Main shaft)',
  MS = 'MS (Main shaft)',
  S_MS_RS = 'S-MS-RS (Seal main shaft)',
  S_MS_GS = 'S-MS-GS (Seal main shaft)',
  S_MS = 'S-MS (Seal main shaft)',
  BLADE = 'Blade',
  PITCH_BEARING = 'Pitch bearing',
  PITCH_DRIVE = 'Pitch drive',
  YAW_BEARING = 'Yaw bearing',
  YAW_DRIVE = 'Yaw drive'
}
