import { useIntl } from 'react-intl';

const ChangeLogStockingPointItem = ({ added, removed }: Props) => {
  const intl = useIntl();

  return (
    <>
      {!!added?.length && (
        <div>
          <span>{intl.formatMessage({ id: 'changelog.added' })}: </span> <span>{added.join(', ')}</span>
        </div>
      )}
      {!!removed?.length && (
        <div>
          <span>{intl.formatMessage({ id: 'changelog.removed' })}: </span> <span>{removed.join(', ')}</span>
        </div>
      )}
    </>
  );
};

type Props = {
  added?: string[];
  removed?: string[];
};

export default ChangeLogStockingPointItem;
