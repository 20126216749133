import { useMemo } from 'react';
import { getSearchService } from '../services/SearchService';
import { ObjectType } from '../../../domain/shared/ObjectId';

const useSuggestSearch = (objectType?: ObjectType, productCategory?: string) => {
  const func = useMemo(() => {
    const searchService = getSearchService();
    if (objectType) {
      return async (query: string) => {
        const result = await searchService.suggestWithType(query, objectType, productCategory);
        return result;
      };
    } else {
      return async (query: string, exact?: boolean) => {
        const result = await searchService.suggest(query, exact);
        return result;
      };
    }
  }, [objectType, productCategory]);

  return func;
};

export default useSuggestSearch;
