import { ObjectDetails } from 'domain/api/relationships/ObjectDetails';
import {
  AddObjectGearboxDetailsChange,
  AddObjectGeneratorDetailsChange,
  AddObjectTurbineDetailsChange,
  RemoveObjectGearboxDetailsChange,
  RemoveObjectGeneratorDetailsChange,
  RemoveObjectTurbineDetailsChange,
  UpdateObjectGearboxDetailsChange,
  UpdateObjectGeneratorDetailsChange,
  UpdateObjectTurbineDetailsChange
} from 'domain/api/relationships/TurbineShareDetails';
import { StockingPoint } from 'domain/products/StockingPoint';
import { ObjectId, ObjectType } from 'domain/shared/ObjectId';
import { EditWarning, EditWarningType } from 'domain/shared/Warning';
import { PostChangeset, PostChangesetCommands } from 'services/api';

export interface SaveResult {
  entityId: number;
  changesetId: string;
}

interface SaveChangesActions {
  setETag: (etag: string) => void;
  loadChangesetData: (changesetId: string) => void;
  preSave: () => void;
  postSave: () => void;
  onError: (errorMessage: string) => void;
  onChangesetUpdateWarning: (warning: EditWarning) => void;
}

export interface SaveChangesOptions<T> {
  entityId?: number;
  changesetId?: string;
  isAutoCommit: boolean;
  type: ObjectType;
  etag: string;
  actions: SaveChangesActions;
  createItemInChangeset: (changesetId: string, item: T, etag: string) => Promise<{ entityId: number; etag: string }>;
}

interface ActionResult {
  status: 'error' | 'ok';
  error?: Error;
}

export interface CommandBuilder<T> {
  buildCommand(item?: T): Record<string, unknown>;
}

export class SaveChangesService<T> {
  private options: SaveChangesOptions<T>;
  private isNewEntity: boolean;
  private isNewChangeset: boolean;

  constructor(options: SaveChangesOptions<T>) {
    this.options = { ...options };
    this.isNewEntity = options.entityId ? false : true;
    this.isNewChangeset = options.changesetId ? false : true;
  }

  getCommandBuilder() {
    return new CommandBuilderImpl<T>();
  }

  async save(item: T, commandBuilder: CommandBuilder<T>): Promise<SaveResult> {
    try {
      this.options.actions.preSave();
      //Ensure changeset
      const changesetResult = await this.ensureChangeset();
      this.breakOnError(changesetResult);
      //Ensure entity
      const entityResult = await this.ensureEntity(item);
      this.breakOnError(entityResult);

      //Save changes
      const changesResult = await this.saveChanges(item, commandBuilder);
      if (!this.isNewEntity) {
        this.breakOnError(changesResult);
      } else if (changesResult.status === 'error') {
        console.log(changesResult);
        this.options.actions.onChangesetUpdateWarning({ type: EditWarningType.ChangesetUpdate, message: changesResult.error?.message ?? '', entityId: this.options.entityId! });
      }

      if (!this.isNewChangeset) {
        this.options.actions.loadChangesetData(this.options.changesetId!);
      }
      this.options.actions.postSave();
    } catch (error: any) {
      throw error;
    }

    return {
      entityId: this.options.entityId!,
      changesetId: this.options.changesetId!
    };
  }

  async saveChanges(item: T, commandBuilder: CommandBuilder<T>): Promise<ActionResult> {
    const commands = commandBuilder.buildCommand(this.isNewEntity ? undefined : item);
    try {
      const result = await PostChangesetCommands(this.options.changesetId!, new ObjectId(this.options.entityId!, this.options.type), commands, this.options.etag, this.options.isAutoCommit);
      this.options.etag = result.headers.etag;
      this.options.actions.setETag(this.options.etag);
      return {
        status: 'ok'
      };
    } catch (error) {
      return {
        status: 'error',
        error: error as Error
      };
    }
  }

  breakOnError(result: ActionResult) {
    if (result.status === 'error') {
      throw result.error;
    }
  }

  async ensureEntity(item: T): Promise<ActionResult> {
    if (!this.options.entityId) {
      try {
        const result = await this.options.createItemInChangeset(this.options.changesetId!, item, this.options.etag);
        this.options.entityId = result.entityId;
        this.options.etag = result.etag;
        this.options.actions.setETag(this.options.etag);
        return {
          status: 'ok'
        };
      } catch (error) {
        return {
          status: 'error',
          error: error as Error
        };
      }
    } else {
      return {
        status: 'ok'
      };
    }
  }

  async ensureChangeset(): Promise<ActionResult> {
    if (!this.options.changesetId) {
      try {
        const responsePostChaneset = await PostChangeset();
        this.options.changesetId = responsePostChaneset.data.changesetId;
        this.options.etag = responsePostChaneset.headers.etag;
        this.options.actions.setETag(this.options.etag);
        return {
          status: 'ok'
        };
      } catch (error) {
        return {
          status: 'error',
          error: error as Error
        };
      }
    } else {
      return {
        status: 'ok'
      };
    }
  }
}

class CommandBuilderImpl<T> implements CommandBuilder<T> {
  private actions: Array<(commands: Record<string, unknown>) => Record<string, unknown>>;

  constructor() {
    this.actions = [];
  }

  buildCommand(item: T | undefined): Record<string, unknown> {
    this.actions = [(commands: Record<string, unknown>) => (item ? { ...commands, update: { ...item } } : commands), ...this.actions];

    return this.actions.filter((a) => a).reduce((result, action) => ({ ...action(result) }), {});
  }

  addStockingPoints(stockingPoints: StockingPoint[]): CommandBuilderImpl<T> {
    return this.anyCommand(stockingPoints, 'addStockingPoints');
  }

  removeStockinpoints(stockingPoints: StockingPoint[]): CommandBuilderImpl<T> {
    return this.anyCommand(stockingPoints, 'removeStockingPoints');
  }

  addObjectDetails(objectDetails: ObjectDetails[]): CommandBuilderImpl<T> {
    return this.anyCommand(objectDetails, 'addObjectDetails');
  }

  updateObjectDetails(objectDetails: ObjectDetails[]): CommandBuilderImpl<T> {
    return this.anyCommand(objectDetails, 'updateObjectDetails');
  }

  removeObjectDetails(objectDetails: ObjectDetails[]): CommandBuilderImpl<T> {
    return this.anyCommand(objectDetails, 'removeObjectDetails');
  }

  addObjectTurbineDetails(objectTurbineDetails: AddObjectTurbineDetailsChange[]): CommandBuilderImpl<T> {
    return this.anyCommand(objectTurbineDetails, 'addObjectTurbineDetails');
  }

  updateObjectTurbineDetails(objectTurbineDetails: UpdateObjectTurbineDetailsChange[]): CommandBuilderImpl<T> {
    return this.anyCommand(objectTurbineDetails, 'updateObjectTurbineDetails');
  }

  removeObjectTurbineDetails(objectTurbineDetails: RemoveObjectTurbineDetailsChange[]): CommandBuilderImpl<T> {
    return this.anyCommand(objectTurbineDetails, 'removeObjectTurbineDetails');
  }

  addObjectGearboxDetails(objectGearboxDetails: AddObjectGearboxDetailsChange[]): CommandBuilderImpl<T> {
    return this.anyCommand(objectGearboxDetails, 'addObjectTurbineDetails');
  }

  updateObjectGearboxDetails(objectGearboxDetails: UpdateObjectGearboxDetailsChange[]): CommandBuilderImpl<T> {
    return this.anyCommand(objectGearboxDetails, 'updateObjectTurbineDetails');
  }

  removeObjectGearboxDetails(objectGearboxDetails: RemoveObjectGearboxDetailsChange[]): CommandBuilderImpl<T> {
    return this.anyCommand(objectGearboxDetails, 'removeObjectTurbineDetails');
  }

  addObjectGeneratorDetails(objectGeneraorDetails: AddObjectGeneratorDetailsChange[]): CommandBuilderImpl<T> {
    return this.anyCommand(objectGeneraorDetails, 'addObjectTurbineDetails');
  }

  updateObjectGeneratorDetails(objectGeneraorDetails: UpdateObjectGeneratorDetailsChange[]): CommandBuilderImpl<T> {
    return this.anyCommand(objectGeneraorDetails, 'updateObjectTurbineDetails');
  }

  removeObjectGeneratorDetails(objectGeneraorDetails: RemoveObjectGeneratorDetailsChange[]): CommandBuilderImpl<T> {
    return this.anyCommand(objectGeneraorDetails, 'removeObjectTurbineDetails');
  }

  anyCommand<TItem>(items: TItem[], fieldname: string): CommandBuilderImpl<T> {
    this.actions = [...this.actions, (commands: Record<string, unknown>) => (items.length > 0 ? { ...commands, [fieldname]: items } : commands)];
    return this;
  }
}
