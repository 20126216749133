import { Flexbox, Heading, Loader } from '@skf/ferris';
import { useIntl } from 'react-intl';
import { useAppSelector } from 'store';
import SearchPagination from './SearchPagination';
import SearchTable from './SearchTable';

const SearchResult = () => {
  const intl = useIntl();
  const loading = useAppSelector((state) => state.searchData.loading);

  return loading ? (
    <Loader />
  ) : (
    <>
      <Flexbox feJustifyContent="space-between" className="mb-3">
        <Heading as={'h1'}>{intl.formatMessage({ id: 'search.result.heading' })}</Heading>
        <SearchPagination />
      </Flexbox>
      <SearchTable />
      <Flexbox feJustifyContent="flex-end" className="mt-3">
        <SearchPagination />
      </Flexbox>
    </>
  );
};

export default SearchResult;
