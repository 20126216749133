import { Changeset, ProductCategory, State } from 'domain/index';
import { useIntl } from 'react-intl';
import { GetEntityUrl, GetTranslationIdForEntityCategory } from 'utils/index';
import NavigationLink from '../navigation-link/NavigationLink';
import { asObjectId, ObjectType } from '../../../domain/shared/ObjectId';

const SubNavigation = ({ changeset }: Props) => {
  const intl = useIntl();

  const getLinkText = (category: string, name: string): string => `${intl.formatMessage({ id: GetTranslationIdForEntityCategory(category) })} ${name}`;
  const getLinkUrl = (category: ObjectType | ProductCategory, id: number, changesetId: string): string => GetEntityUrl(asObjectId(id, category), changesetId);
  const getColor = (state: State): string => {
    switch (state) {
      case State.NEW:
        return 'bg-green-base';
      case State.MODIFIED:
        return 'bg-yellow-base';
      case State.DELETED:
        return 'bg-red-base';
      case State.EMPTY:
        return 'bg-gray-300';
      default:
        return '';
    }
  };

  return (
    <div>
      {changeset.refs.map((ref) => (
        <NavigationLink key={ref.id} linkText={getLinkText(ref.type, ref.name)} linkUrl={getLinkUrl(ref.type, ref.id, changeset.changesetId)} color={getColor(ref.state)} isChild={true} />
      ))}
    </div>
  );
};

interface Props {
  changeset: Changeset;
}

export default SubNavigation;
