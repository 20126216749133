import { BoolFacet, DateFacet, FacetType, FacetWithValues, MultiChoiceAndFacet, MultiChoiceOrFacet, SearchResult, ValueFacet } from '../domain/SearchResult';

export const resultEffects = (result: SearchResult): SearchResult => {
  return promoteEmptyFacets(result);
};

const promoteEmptyFacets = (result: SearchResult): SearchResult => {
  return {
    ...result,
    facets: result.facets.map((f) => {
      if (f.type === FacetType.MultiChoiceAndFilter || f.type === FacetType.MultiChoiceOrFilter || f.type === FacetType.ValueFilter) {
        return {
          ...f,
          values: [(f as FacetWithValues).values.find((v) => v.value === 'empty'), ...(f as FacetWithValues).values.filter((v) => v.value !== 'empty')].filter((v) => v)
        };
      } else {
        return f;
      }
    }) as Array<MultiChoiceOrFacet | MultiChoiceAndFacet | ValueFacet | DateFacet | BoolFacet>
  };
};
