import { useIntl } from 'react-intl';
import { useState } from 'react';
import { Button, Heading, Icons } from '@skf/ferris';
import { GetTranslationIdForEntityCategory } from 'utils';
import { RelationTurbine } from 'domain/index';
import WindTurbineDialog from './dialog/WindTurbineDialog';
import WindTurbineTable from './table/WindTurbineTable';
import { EntityState } from '../entity-state';
import { SetNumberFieldErrors, SetRequiredError } from '../utils/validation/Validation.utility';

const WindTurbine = ({ windTurbines, entityId, editMode, onTurbineChange }: Props) => {
  const intl = useIntl();
  const [formIsOpen, setFormIsOpen] = useState(false);
  const [turbineToEdit, setTurbineToEdit] = useState<RelationTurbine>({} as RelationTurbine);
  const [indexToEdit, setIndexToEdit] = useState<number | null>(null);
  const [formError, setFormError] = useState<Map<string, string>>(new Map());

  const handleFormChange = (value: RelationTurbine) => {
    setTurbineToEdit((prev) => {
      return {
        ...prev,
        objectId: entityId,
        turbineId: value.turbineId,
        turbineName: value.turbine.turbineName,
        manufacturer: { manufacturerName: value.turbine.manufacturer.manufacturerName },
        marketShare: value.marketShare,
        remark: value.remark,
        turbine: value.turbine
      };
    });
    setFormError((prev) => {
      let errors = SetNumberFieldErrors(value.marketShare, 'marketShare', Number.MAX_SAFE_INTEGER, prev, intl);
      errors = SetRequiredError(value.turbineId, 'turbineId', errors, intl);
      return errors;
    });
  };

  const getMetadata = (turbineToEdit: RelationTurbine) => {
    if (turbineToEdit.metadata) {
      if (turbineToEdit.metadata.state === EntityState.NOT_MODIFIED) {
        return { state: EntityState.MODIFIED_UNSAVED };
      } else if (turbineToEdit.metadata.state === EntityState.MODIFIED) {
        return { state: EntityState.MODIFIED_UNSAVED };
      } else if (turbineToEdit.metadata.state === EntityState.NEW) {
        return { state: EntityState.MODIFIED_UNSAVED };
      } else {
        return turbineToEdit.metadata;
      }
    } else {
      return { state: EntityState.NEW_UNSAVED };
    }
  };

  return (
    <>
      <Heading as="h2">{`${intl.formatMessage({ id: GetTranslationIdForEntityCategory('Turbine') })}`}</Heading>
      <WindTurbineTable
        editMode={editMode}
        onDeleteTurbine={(windTurbine: RelationTurbine, index: number) => onTurbineChange(windTurbine, index, 'Delete')}
        onEditTurbine={(windTurbine: RelationTurbine, index: number) => {
          setTurbineToEdit(windTurbine);
          setIndexToEdit(index);
          setFormIsOpen(true);
        }}
        windTurbines={windTurbines}
      />
      {editMode && (
        <div className="w-full flex justify-end">
          <Button
            className="ml-auto mr-0"
            feType="secondary"
            feIcon={{ feIcon: Icons.Plus, position: 'left' }}
            onClick={() => {
              setTurbineToEdit({ turbine: { manufacturer: {} } } as RelationTurbine);
              setIndexToEdit(null);
              setFormIsOpen(true);
              setFormError(SetRequiredError(undefined, 'turbineId', new Map(), intl));
            }}
          >
            {intl.formatMessage({ id: 'windTurbine.button.add' })}
          </Button>
        </div>
      )}
      <WindTurbineDialog
        turbineToEdit={turbineToEdit}
        isOpen={formIsOpen}
        onFormChange={handleFormChange}
        formError={formError}
        onSave={() => {
          onTurbineChange(
            {
              ...turbineToEdit,
              metadata: getMetadata(turbineToEdit)
            },
            indexToEdit,
            indexToEdit !== null ? 'Edit' : 'Add'
          );
          setFormIsOpen(false);
        }}
        onCancel={() => {
          setFormIsOpen(false);
          setTurbineToEdit({ turbine: { manufacturer: {} } } as RelationTurbine);
          setIndexToEdit(null);
          setFormError(new Map());
        }}
      />
    </>
  );
};

interface Props {
  windTurbines: RelationTurbine[];
  editMode: boolean;
  onTurbineChange: any;
  entityId: number;
}

export default WindTurbine;
