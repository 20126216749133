import { Icon, IconColors, Icons } from '@skf/ferris';
import { PartStatus } from 'domain/index';

const StatusIcon = ({ status, heading }: Props) => {
  const getColor = (status: string): IconColors => {
    switch (status) {
      case PartStatus.OE_Certified:
      case PartStatus.SKF_Verified:
        return IconColors.Green;
      case PartStatus.Technical_Equivalent:
        return IconColors.Yellow;
      case PartStatus.No_Secured_SKF_Alternative:
        return IconColors.Red;
      default:
        return IconColors.Gray;
    }
  };

  return (
    <>
      <Icon className="mr-2 align-text-top" feIcon={Icons.StatusCircle} feColor={getColor(status)} />
      {heading ?? status}
    </>
  );
};

interface Props {
  status: string;
  heading?: string;
}

export default StatusIcon;
