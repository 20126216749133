import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RelationshipGearbox } from 'domain/index';
import { GetRelatedGearboxesForComponent } from 'services/api';

export type RelationshipGeraboxesState = {
  gearboxes: RelationshipGearbox[];
  gearboxesAreInitialized: boolean;
  loading: boolean;
  error: string | undefined;
};

const initialState: RelationshipGeraboxesState = {
  gearboxes: [],
  gearboxesAreInitialized: false,
  loading: false,
  error: undefined
};

export const getRelatedGearboxesForComponent = createAsyncThunk('gearboxes/getRelatedGearboxesForComponent', async (options: { id: number; changesetId?: string }) => {
  return (await GetRelatedGearboxesForComponent(options.id, options.changesetId)).data;
});

export const relationshipGearboxesSlice = createSlice({
  name: 'gearboxes',
  initialState: initialState,
  reducers: {
    resetGearboxesState: (state: RelationshipGeraboxesState) => {
      state.gearboxes = [];
      state.gearboxesAreInitialized = false;
      state.loading = false;
      state.error = undefined;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getRelatedGearboxesForComponent.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getRelatedGearboxesForComponent.fulfilled, (state, action) => {
        state.loading = false;
        state.gearboxes = action.payload;
        state.gearboxesAreInitialized = true;
        state.error = undefined;
      })
      .addCase(getRelatedGearboxesForComponent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

export const { resetGearboxesState } = relationshipGearboxesSlice.actions;
export default relationshipGearboxesSlice.reducer;
