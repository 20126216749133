import { PartStatus } from 'domain/index';
import { useIntl } from 'react-intl';
import StatusIcon from '../status-icon/StatusIcon';

const StatusHelper = () => {
  const intl = useIntl();

  const createStatusInfo = (status: string, heading: string, description: string, isLast?: boolean): JSX.Element => {
    return (
      <>
        <p className="mb-1">
          <StatusIcon status={status} heading={heading} />
        </p>
        <p className={!isLast ? 'mb-4' : ''}>{description}</p>
      </>
    );
  };

  return (
    <div>
      {createStatusInfo(PartStatus.OE_Certified, intl.formatMessage({ id: 'part.status.helpText.OECertified' }), intl.formatMessage({ id: 'part.status.helpText.OECertified.description' }))}
      {createStatusInfo(PartStatus.SKF_Verified, intl.formatMessage({ id: 'part.status.helpText.SKFVerified' }), intl.formatMessage({ id: 'part.status.helpText.SKFVerified.description' }))}
      {createStatusInfo(PartStatus.Technical_Equivalent, intl.formatMessage({ id: 'part.status.helpText.TechnicalEquivalent' }), intl.formatMessage({ id: 'part.status.helpText.TechnicalEquivalent.description' }))}
      {createStatusInfo(PartStatus.No_Secured_SKF_Alternative, intl.formatMessage({ id: 'part.status.helpText.NoSecured' }), intl.formatMessage({ id: 'part.status.helpText.NoSecured.description' }), true)}
    </div>
  );
};

export default StatusHelper;
