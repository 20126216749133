import { configureStore, Store } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import changesetReducer from 'store/ChangesetSlice';
import bookmarksReducer from 'store/BookmarksSlice';
import searchReducer from 'features/search/store';
import userReducer from 'store/UserSlice';
import turbineReducer from '../features/edit/edit-turbine/store/turbine-slice';
import relationGearboxesReducer from '../features/edit/edit-turbine/store/relation-gearboxes';
// import relationGearboxesReducer from 'features/edit/shared/store/relation-gearboxes';
import relationGeneratorsReducer from '../features/edit/edit-turbine/store/relation-generators';
// import relationGeneratorsReducer from 'features/edit/shared/store/relation-generators';
import gearboxReducer from '../features/edit/edit-gearbox/store/gearbox-slice';
import generatorReducer from '../features/edit/edit-generator/store/generator-slice';
import productReducer from '../features/edit/edit-product/store/product-slice';
import relationshipTurbinesReducer from '../features/edit/edit-product/store/relationship-turbines-slice';
import relationshipGearboxesReducer from '../features/edit/edit-product/store/relationship-gearboxes-slice';
import relationshipGeneratorsReducer from '../features/edit/edit-product/store/relationship-generators-slice';
import stockingPointsReducer from '../features/edit/edit-product/store/stocking-points-slice';
import relationshipProductsReducer from '../features/edit/shared/store/relationship-products';
import relationTurbinesReducer from '../features/edit/shared/store/relation-turbines';
import positionsReducer from '../features/edit/shared/store/position-slice';
import changesetDetailsReducer from '../features/edit/shared/store/changeset-details-slice';
import logReducer from '../features/edit/shared/store/log-slice';

const store = configureStore({
  reducer: {
    //Add slizes here, like:
    userData: userReducer,
    changesetData: changesetReducer,
    changesetDetailsData: changesetDetailsReducer,
    searchData: searchReducer,
    bookmarks: bookmarksReducer,
    editGearboxData: gearboxReducer,
    editGeneratorData: generatorReducer,
    editProductData: productReducer,
    editTurbineData: turbineReducer,
    logData: logReducer,
    positionsData: positionsReducer,
    relationshipGearboxesData: relationshipGearboxesReducer,
    relationshipGeneratorsData: relationshipGeneratorsReducer,
    relationshipProductsData: relationshipProductsReducer,
    relationshipTurbinesData: relationshipTurbinesReducer,
    relationGearboxesData: relationGearboxesReducer,
    relationGeneratorsData: relationGeneratorsReducer,
    relationTurbinesData: relationTurbinesReducer,
    stockingPointsData: stockingPointsReducer
    //read more: https://redux-toolkit.js.org/api/createSlice
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).prepend(thunk)
});

export const setDefaults = (storeToUpdate: Store) => {
  //To set default values use
  // storeToUpdate.dispatch(someAction(someData));
};

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
