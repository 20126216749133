import { Icon, Icons, Time } from '@skf/ferris';
import { ValueIsDate, ValueIsEmpty } from '../../../../../../utils';
import { useIntl } from 'react-intl';

const ChangeLogValueItem = ({ oldValue, newValue, name }: Props) => {
  const intl = useIntl();
  const getDisplayValue = (value: string | undefined, name: string): JSX.Element => {
    if (ValueIsEmpty(value)) {
      return <span className="text-gray-600">{intl.formatMessage({ id: 'changeset.diff.value.empty' })}</span>;
    }
    if (value && ValueIsDate(value, name)) {
      return <Time dateTime={value} feFormat="date"></Time>;
    }
    return <span>{value}</span>;
  };

  return (
    <>
      {getDisplayValue(oldValue, name)} <Icon feIcon={Icons.ArrowRight} /> {getDisplayValue(newValue, name)}
    </>
  );
};

type Props = {
  name: string;
  oldValue?: string;
  newValue?: string;
};

export default ChangeLogValueItem;
