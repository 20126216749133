import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Gearbox } from 'domain/index';
import { CreateGearboxInChangeset, DeleteGearbox, GetChangesetDiff, GetGearbox, GetGearboxFromChangeset } from 'services/api';
import { PostChangeset, PostChangesetRef } from 'services/api';
import { getChangesetDetails, resetProductPartsOriginal } from 'features/edit/shared';
import { getChangesets, removeBookmark } from 'store';
import _ from 'lodash';
import { AdditionalObjectDetailsChanges, AdditionalObjectTurbineChanges } from '../../../shared/edit/AdditionalObjectChanges';
import { SaveChangesService } from 'services/api/changeset.service.helper';
import { ObjectType } from 'domain/shared/ObjectId';
import { EditWarning } from 'domain/shared/Warning';

export type EditGearboxState = {
  gearbox: Gearbox;
  gearboxOriginal: Gearbox;
  editMode: boolean;
  eTag: string;
  loading: { gearbox: boolean; delete: boolean; save: boolean; editInTicket: boolean };
  initError: string | undefined;
  error: string | undefined;
  warnings: EditWarning[];
};

const initialState: EditGearboxState = {
  gearbox: {} as Gearbox,
  gearboxOriginal: {} as Gearbox,
  editMode: false,
  eTag: '',
  loading: { gearbox: false, delete: false, save: false, editInTicket: false },
  initError: undefined,
  error: undefined,
  warnings: []
};

export const getGearbox = createAsyncThunk('gearbox/getGearbox', async (options: { gearboxId: number; changesetId: string | undefined }) => {
  if (!!options.changesetId) {
    const response = await GetGearboxFromChangeset(options.changesetId, options.gearboxId);
    return { data: response.data, etag: response.headers.etag };
  }
  const response = await GetGearbox(options.gearboxId);
  return { data: response.data, etag: '' };
});

export const deleteGearbox = createAsyncThunk('gearbox/deleteGearbox', async (options: { gearboxId: number; changesetId: string | undefined; eTag: string }, thunkAPI) => {
  thunkAPI.dispatch(setLoading({ delete: true }));
  try {
    await DeleteGearbox(options.changesetId ?? '', options.gearboxId, options.eTag);
    if (!!options.changesetId) {
      thunkAPI.dispatch(getChangesetDetails({ changesetId: options.changesetId }));
    }
    thunkAPI.dispatch(getChangesets(false));
  } catch (error: any) {
    thunkAPI.dispatch(setError({ error: error?.message ?? 'Failed', loading: { delete: false } }));
  }
  thunkAPI.dispatch(setLoading({ delete: false }));
});

export const editInTicket = createAsyncThunk('gearbox/editInTicket', async (options: { entityId: number; changesetId: string; gearboxName: string; etag: string }, thunkAPI) => {
  thunkAPI.dispatch(setLoading({ editInTicket: true }));
  try {
    if (_.isEmpty(options.changesetId)) {
      const responsePostChaneset = await PostChangeset();
      thunkAPI.dispatch(setETag(responsePostChaneset.headers.etag));
      const responsePostRef = await PostChangesetRef(responsePostChaneset.data.changesetId, options.entityId, options.gearboxName ?? '', 'Gearbox', responsePostChaneset.headers.etag);
      thunkAPI.dispatch(getChangesets(false));
      thunkAPI.dispatch(removeBookmark({ id: options.entityId }));
      thunkAPI.dispatch(setLoading({ editInTicket: false }));
      return responsePostRef.data.changesetId;
    } else {
      const responseGetChangeset = await GetChangesetDiff(options.changesetId);
      thunkAPI.dispatch(setETag(responseGetChangeset.headers.etag));
      const responsePostRef = await PostChangesetRef(options.changesetId, options.entityId, options.gearboxName ?? '', 'Gearbox', responseGetChangeset.headers.etag);
      thunkAPI.dispatch(getChangesets(false));
      thunkAPI.dispatch(removeBookmark({ id: options.entityId }));
      thunkAPI.dispatch(setLoading({ editInTicket: false }));
      return responsePostRef.data.changesetId;
    }
  } catch (error: any) {
    thunkAPI.dispatch(setError({ error: error?.message ?? 'Failed', loading: { editInTicket: false } }));
  }
  thunkAPI.dispatch(setLoading({ editInTicket: false }));
});

export const saveChanges = createAsyncThunk(
  'gearbox/saveChanges',
  async (
    options: {
      entityId: number;
      changesetId: string | undefined;
      gearboxDiff: any;
      additionalChanges: AdditionalObjectTurbineChanges & AdditionalObjectDetailsChanges;
      isAutoCommit: boolean;
      etag: string;
      newMode: boolean | undefined;
    },
    thunkAPI
  ) => {
    const saveService = new SaveChangesService({
      ...options,
      type: ObjectType.GEARBOX,
      createItemInChangeset: async (changesetId: string, item: Gearbox, etag: string) => {
        const responseCreateGearbox = await CreateGearboxInChangeset(changesetId, item, etag);
        return {
          entityId: responseCreateGearbox.data.gearboxId,
          etag: responseCreateGearbox.headers.etag
        };
      },
      actions: {
        preSave: () => {
          thunkAPI.dispatch(setLoading({ save: true }));
          thunkAPI.dispatch(resetWarnings());
        },
        setETag: (etag: string) => thunkAPI.dispatch(setETag(etag)),
        loadChangesetData: (changesetId: string) => {
          thunkAPI.dispatch(getChangesetDetails({ changesetId }));
          thunkAPI.dispatch(getChangesets(false));
        },
        postSave: () => {
          thunkAPI.dispatch(setEditMode({ editMode: false }));
          thunkAPI.dispatch(resetGearboxOriginal());
          thunkAPI.dispatch(resetProductPartsOriginal());
          thunkAPI.dispatch(setLoading({ save: false }));
        },
        onError: (error) => thunkAPI.dispatch(setError({ error, loading: { save: false } })),
        onChangesetUpdateWarning: (warning: EditWarning) => thunkAPI.dispatch(setWarning(warning))
      }
    });

    const commandBuilder = saveService
      .getCommandBuilder()
      .addObjectDetails(options.additionalChanges.addObjectDetails)
      .updateObjectDetails(options.additionalChanges.updateObjectDetails)
      .removeObjectDetails(options.additionalChanges.removeObjectDetails)
      .addObjectTurbineDetails(options.additionalChanges.addTurbineDetails)
      .updateObjectTurbineDetails(options.additionalChanges.updateTurbineDetails)
      .removeObjectTurbineDetails(options.additionalChanges.removeTurbineDetails);

    try {
      const result = await saveService.save(options.gearboxDiff as Gearbox, commandBuilder);
      return { status: 'ok', changesetId: result.changesetId, entityId: result.entityId };
    } catch (error: any) {
      thunkAPI.dispatch(setError({ error: error?.message ?? 'Failed', loading: { save: false } }));
    }
  }
);

export const editGearboxSlice = createSlice({
  name: 'gearbox',
  initialState: initialState,
  reducers: {
    resetGearboxState: (state: EditGearboxState) => {
      state.gearbox = {} as Gearbox;
      state.gearboxOriginal = {} as Gearbox;
      state.editMode = false;
      state.eTag = '';
      state.loading = { gearbox: false, delete: false, save: false, editInTicket: false };
      state.initError = undefined;
      state.error = undefined;
    },
    setEditMode: (state: EditGearboxState, action: PayloadAction<{ editMode: boolean }>) => {
      state.editMode = action.payload.editMode;
    },
    setGearbox: (state: EditGearboxState, action: PayloadAction<Partial<{ gearbox: Gearbox }>>) => {
      state.gearbox = { ...state.gearbox, ...action.payload.gearbox };
    },
    setGearboxOriginal: (state: EditGearboxState, action: PayloadAction<{ gearbox: Gearbox }>) => {
      state.gearboxOriginal = action.payload.gearbox;
    },
    resetGearboxOriginal: (state: EditGearboxState) => {
      state.gearboxOriginal = state.gearbox;
    },
    setLoading: (state: EditGearboxState, action: PayloadAction<Partial<{ gearbox: boolean; changeset: boolean; delete: boolean; save: boolean; editInTicket: boolean }>>) => {
      state.loading = { ...state.loading, ...action.payload };
    },
    setETag: (state: EditGearboxState, action: PayloadAction<string>) => {
      state.eTag = action.payload;
    },
    setError: (state: EditGearboxState, action: PayloadAction<{ error: string; loading: Partial<{ gearbox: boolean; changeset: boolean; delete: boolean; save: boolean; editInTicket: boolean }> }>) => {
      state.loading = { ...state.loading, ...action.payload.loading };
      state.error = action.payload.error;
    },
    setWarning: (state: EditGearboxState, action: PayloadAction<EditWarning>) => {
      state.warnings = [...state.warnings, action.payload];
    },
    resetWarnings: (state: EditGearboxState, action: PayloadAction) => {
      state.warnings = [];
    },
    resetWarning: (state: EditGearboxState, action: PayloadAction<EditWarning>) => {
      state.warnings = state.warnings.filter((w) => w.type !== action.payload.type);
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getGearbox.pending, (state, action) => {
        state.loading = { ...state.loading, gearbox: true };
      })
      .addCase(getGearbox.fulfilled, (state, action) => {
        state.gearbox = action.payload.data;
        state.gearboxOriginal = action.payload.data;
        state.eTag = action.payload.etag;
        state.loading = { ...state.loading, gearbox: false };
      })
      .addCase(getGearbox.rejected, (state, action) => {
        state.loading = { ...state.loading, gearbox: false };
        state.error = action.error.message;
        state.initError = action.error.message;
      });
  }
});

export const { resetGearboxState, setLoading, setError, setETag, setEditMode, setGearbox, setGearboxOriginal, resetGearboxOriginal, setWarning, resetWarnings, resetWarning } = editGearboxSlice.actions;
export default editGearboxSlice.reducer;
