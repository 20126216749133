import { Spacer, TextField, NumberField } from '@skf/ferris';
import { useIntl } from 'react-intl';
import { BaseSeverity } from '@skf/ferris/dist/esm/common/types/severity';
import { Lubricator, ChangesetDetails } from 'domain/index';
import { GetHint, GetSeverity } from '../../shared/index';

const LubricatorForm = ({ lubricator, editMode, onLubricatorChange, formError, changesetDetails }: Props) => {
  const intl = useIntl();
  const onLubVolumeCapacityChange = (event: any, value: number) => {
    onLubricatorChange({ ...lubricator, lubVolumeCapacity: value });
  };
  const onLubMaxOperPressureChange = (event: any, value: number) => {
    onLubricatorChange({ ...lubricator, lubMaxOperPressure: value });
  };
  const onLubMaxFeedLineLenChange = (event: any, value: number) => {
    onLubricatorChange({ ...lubricator, lubMaxFeedLineLen: value });
  };
  const onLubDriveMechanismChange = (event: any, value: string) => {
    onLubricatorChange({ ...lubricator, lubDriveMechanism: value });
  };
  const onLubTempRangeChange = (event: any, value: string) => {
    onLubricatorChange({ ...lubricator, lubTempRange: value });
  };
  const onLubPumpableGreasesChange = (event: any, value: string) => {
    onLubricatorChange({ ...lubricator, lubPumpableGreases: value });
  };

  const getSeverity = (fieldName: string): BaseSeverity | undefined => {
    return GetSeverity(lubricator.productId, formError, fieldName, changesetDetails);
  };

  const getHint = (fieldName: string): string | undefined => {
    return GetHint(lubricator.productId, formError, fieldName, changesetDetails, intl);
  };

  return (
    <div className="flex flex-wrap">
      <div className="w-1/4 pl-2 pr-2">
        <NumberField
          disabled={!editMode}
          onChange={onLubVolumeCapacityChange}
          value={lubricator.lubVolumeCapacity}
          feLabel={intl.formatMessage({ id: 'lubricator.lubVolumeCapacity' })}
          feHint={getHint('lubVolumeCapacity')}
          feSeverity={getSeverity('lubVolumeCapacity')}
          min={0}
        />
        <Spacer />
      </div>
      <div className="w-1/4 pl-2 pr-2">
        <NumberField
          disabled={!editMode}
          onChange={onLubMaxOperPressureChange}
          value={lubricator.lubMaxOperPressure}
          feLabel={intl.formatMessage({ id: 'lubricator.lubMaxOperPressure' })}
          feHint={getHint('lubMaxOperPressure')}
          feSeverity={getSeverity('lubMaxOperPressure')}
          min={0}
        />
        <Spacer />
      </div>
      <div className="w-1/4 pl-2 pr-2">
        <NumberField
          disabled={!editMode}
          onChange={onLubMaxFeedLineLenChange}
          value={lubricator.lubMaxFeedLineLen}
          feLabel={intl.formatMessage({ id: 'lubricator.lubMaxFeedLineLen' })}
          feHint={getHint('lubMaxFeedLineLen')}
          feSeverity={getSeverity('lubMaxFeedLineLen')}
          min={0}
        />
        <Spacer />
      </div>
      <div className="w-1/4 pl-2 pr-2">
        <TextField
          disabled={!editMode}
          onChange={onLubDriveMechanismChange}
          value={lubricator.lubDriveMechanism}
          feLabel={intl.formatMessage({ id: 'lubricator.lubDriveMechanism' })}
          feHint={getHint('lubDriveMechanism')}
          feSeverity={getSeverity('lubDriveMechanism')}
        />
        <Spacer />
      </div>
      <div className="w-1/4 pl-2 pr-2">
        <TextField
          disabled={!editMode}
          onChange={onLubTempRangeChange}
          value={lubricator.lubTempRange}
          feLabel={intl.formatMessage({ id: 'lubricator.lubTempRange' })}
          feHint={getHint('lubTempRange')}
          feSeverity={getSeverity('lubTempRange')}
        />
        <Spacer />
      </div>
      <div className="w-2/4 pl-2 pr-2">
        <TextField
          disabled={!editMode}
          onChange={onLubPumpableGreasesChange}
          value={lubricator.lubPumpableGreases}
          feLabel={intl.formatMessage({ id: 'lubricator.lubPumpableGreases' })}
          feHint={getHint('lubPumpableGreases')}
          feSeverity={getSeverity('lubPumpableGreases')}
        />
        <Spacer />
      </div>
    </div>
  );
};

interface Props {
  lubricator: Lubricator;
  editMode: boolean;
  onLubricatorChange: any;
  formError: Map<string, string>;
  changesetDetails?: ChangesetDetails;
}

export default LubricatorForm;
