import { useIntl } from 'react-intl';
import { Heading, Link, Table, TableRow } from '@skf/ferris';
import { GetEntityUrl, GetTraslationIdForEntityCategoryPlural } from 'utils';
import { useNavigate, useParams } from 'react-router';
import _ from 'lodash';
import EntityStateIndicator from '../../shared/entity-state/EntityStateIndicator';
import { WithMetadata } from '../../../../domain/shared/Metadata';
import { asObjectId, fromTurbineId, ObjectType } from '../../../../domain/shared/ObjectId';
import { stateToStyle } from '../../shared/entity-state';

const RelationshipTable = ({ relations, editMode, objectType, showTurbines }: Props) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { changesetId } = useParams();

  const tableHead: TableRow[] = [
    {
      cells: [
        { children: intl.formatMessage({ id: 'relationshipTable.name' }), scope: 'col' },
        { children: intl.formatMessage({ id: 'relationshipTable.position' }), scope: 'col' },
        { children: intl.formatMessage({ id: 'relationshipTable.manufacturer' }), scope: 'col' },
        { children: showTurbines ? intl.formatMessage({ id: 'relationshipTable.turbines' }) : '', scope: 'col' }
      ]
    }
  ];

  const tdStyle = 'align-top whitespace-normal';

  const tableBody: TableRow[] = relations.map((relation) => ({
    cells: [
      {
        width: '25%',
        className: stateToStyle(tdStyle, relation.metadata?.state),
        children: (
          <>
            <div className="flex ">
              <EntityStateIndicator state={relation.metadata?.state} />
              {editMode ? (
                <p>{relation.name}</p>
              ) : (
                <Link as="a" href={GetEntityUrl(asObjectId(relation.id, objectType), changesetId)} onClick={(_, route) => navigate(route)}>
                  {`${relation.name}`}
                </Link>
              )}
            </div>
          </>
        )
      },
      { width: '25%', className: stateToStyle(tdStyle, relation.metadata?.state), children: <p>{relation.position}</p> },
      { width: '25%', className: stateToStyle(tdStyle, relation.metadata?.state), children: <p>{relation.manufacturer}</p> },
      {
        width: '25%',
        className: stateToStyle(tdStyle, relation.metadata?.state),
        children: (
          <div>
            {relation.turbines?.map((turbine, i, turbines) => (
              <span key={turbine.id}>
                {editMode ? (
                  <span>{`${turbine.name}`}</span>
                ) : (
                  <Link as="a" href={GetEntityUrl(fromTurbineId(turbine.id), changesetId)}>
                    {turbine.name}
                  </Link>
                )}
                {i + 1 !== turbines.length ? ', ' : ''}
              </span>
            ))}
          </div>
        )
      }
    ]
  }));

  return (
    <>
      <Heading as="h2">{`${intl.formatMessage({ id: GetTraslationIdForEntityCategoryPlural(objectType) })}`}</Heading>
      {_.some(relations) ? (
        <Table className="mt-5 mb-5" feBody={tableBody} feHead={tableHead} />
      ) : (
        <p className="mb-5">{`${intl.formatMessage({ id: 'part.empty.table' })} ${intl.formatMessage({ id: GetTraslationIdForEntityCategoryPlural(objectType) })}`}</p>
      )}
    </>
  );
};

interface Props {
  relations: ({
    id: number;
    name: string;
    position: string;
    manufacturer: string;
    turbines: { id: number; name: string }[];
  } & WithMetadata)[];
  objectType: ObjectType;
  showTurbines: boolean;
  editMode: boolean;
}

export default RelationshipTable;
