import EditProduct from 'features/edit/edit-product/EditProduct';
import SelectNewItem from './SelectNewItem';
import EditTurbine from 'features/edit/edit-turbine/EditTurbine';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { resetTurbineState } from 'features/edit/edit-turbine';
import EditGearbox from 'features/edit/edit-gearbox/EditGearbox';
import EditGenerator from 'features/edit/edit-generator/EditGenerator';
import { resetGearboxState } from 'features/edit/edit-gearbox/store/gearbox-slice';
import { resetGeneratorState } from 'features/edit/edit-generator/store/generator-slice';
import { ObjectType } from 'domain/shared/ObjectId';
import { ProductCategory } from 'domain/index';

const AddProduct = () => {
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState<string | undefined>(undefined);
  const [loadForms, setLoadForms] = useState<boolean>(false);
  const handleOnClick = async () => {
    if (selectedItem === 'Turbine') {
      dispatch(resetTurbineState());
    } else if (selectedItem === 'Gearbox') {
      dispatch(resetGearboxState());
    } else if (selectedItem === 'Generator') {
      dispatch(resetGeneratorState());
    }
    setLoadForms(true);
  };

  return (
    <>
      {!loadForms && <SelectNewItem selectedItem={selectedItem} setSelectedItem={setSelectedItem} handleOnClick={handleOnClick} />}
      {selectedItem === ObjectType.TURBINE && loadForms && <EditTurbine newMode={true} setLoadForms={setLoadForms} />}
      {selectedItem === ObjectType.GEARBOX && loadForms && <EditGearbox newMode={true} setLoadForms={setLoadForms} />}
      {selectedItem === ObjectType.GENERATOR && loadForms && <EditGenerator newMode={true} setLoadForms={setLoadForms} />}
      {((selectedItem === ProductCategory.BEARING && loadForms) ||
        (selectedItem === ProductCategory.GREASE && loadForms) ||
        (selectedItem === ProductCategory.LUBRICATION_SYSTEM && loadForms) ||
        (selectedItem === ProductCategory.LUBRICATOR && loadForms) ||
        (selectedItem === ProductCategory.SEAL && loadForms) ||
        (selectedItem === ProductCategory.HOUSING && loadForms) ||
        (selectedItem === ProductCategory.COUPLING && loadForms)) && <EditProduct newMode={true} setLoadForms={setLoadForms} />}
    </>
  );
};

export default AddProduct;
