import { useIntl } from 'react-intl';
import { Checkbox, Spacer, Table, TableRow } from '@skf/ferris';
import { StockingPoint } from 'domain/index';
import _ from 'lodash';

const StockingPointForm = ({ stockingPoints, stockingPointOptions, onFormChange }: Props) => {
  const intl = useIntl();

  const tableHead: TableRow[] = [
    {
      cells: [
        { children: intl.formatMessage({ id: 'stockingPoint.table.selected' }), scope: 'col' },
        { children: intl.formatMessage({ id: 'stockingPoint.table.id' }), scope: 'col', sortable: {} },
        { children: intl.formatMessage({ id: 'stockingPoint.table.location' }), scope: 'col', sortable: {} },
        { children: intl.formatMessage({ id: 'stockingPoint.table.name' }), scope: 'col', sortable: {} }
      ]
    }
  ];

  const tableBody: TableRow[] = stockingPointOptions.map((item: StockingPoint) => ({
    cells: [
      { width: '30%', children: <Checkbox feLabel="" feSize="sm" onChange={(_, checked: boolean) => onFormChange(item, checked)} checked={stockingPoints.some((x) => x.warehouseId === item.warehouseId)} />, scope: 'col' },
      { width: '30%', children: item.warehouseId, scope: 'col' },
      { width: '30%', children: item.area, scope: 'col' },
      { width: '30%', children: item.warehouseName, scope: 'col' }
    ]
  }));

  return (
    <div>
      {_.some(stockingPointOptions) ? <Table feHead={tableHead} feBody={tableBody} /> : <p>{intl.formatMessage({ id: 'stockingPoint.form.empty' })}</p>}
      <Spacer />
    </div>
  );
};

interface Props {
  stockingPoints: StockingPoint[];
  stockingPointOptions: StockingPoint[];
  onFormChange: any;
}

export default StockingPointForm;
