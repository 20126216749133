import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router';
import { Icon, IconColors, Icons, Link, Table, TableRow } from '@skf/ferris';
import { GetEntityUrl, GetTraslationIdForEntityCategoryPlural } from 'utils';
import { ProductCategory, RelationshipProduct } from 'domain/index';
import StatusIcon from '../form/status-icon/StatusIcon';
import _ from 'lodash';
import { EntityState, isDeleted, stateToColor, stateToStyle } from '../../entity-state';
import { fromProductId } from '../../../../../domain/shared/ObjectId';

const ListOfPartsTable = ({ productCategory, relations, filteredRelations, editMode, onEditPart, onDeletePart }: Props) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { changesetId } = useParams();

  const tableHead: TableRow[] = [
    {
      cells: [
        { children: '', scope: 'col' },
        { children: intl.formatMessage({ id: 'part.positionCode' }), scope: 'col' },
        { children: intl.formatMessage({ id: 'part.designation' }), scope: 'col' },
        { children: intl.formatMessage({ id: 'part.manufacturerName' }), scope: 'col' },
        { children: intl.formatMessage({ id: 'part.quantity' }), scope: 'col' },
        { children: intl.formatMessage({ id: 'part.upgradeFlag' }), scope: 'col' },
        { children: intl.formatMessage({ id: 'part.status' }), scope: 'col' },
        { children: intl.formatMessage({ id: 'part.label' }), scope: 'col' },
        { children: intl.formatMessage({ id: 'part.remark' }), scope: 'col' },
        { children: '', scope: 'col' }
      ]
    }
  ];

  const tdStyle = 'align-top whitespace-normal';

  const tableBody: TableRow[] = filteredRelations.map((relation, index) => ({
    cells: [
      { width: '1%', className: stateToStyle(tdStyle, relation.metadata?.state), children: <div title={'status'} className={`w-3 h-10 ${stateToColor(relation.metadata?.state)}`}></div> },
      { width: '14%', className: stateToStyle(tdStyle, relation.metadata?.state), children: <p>{relation.positionCode}</p> },
      {
        width: '15%',
        className: stateToStyle(tdStyle, relation.metadata?.state),
        children: (
          <>
            {relation.product ? (
              editMode ? (
                <p>{relation.product?.designation}</p>
              ) : (
                <Link as="a" href={GetEntityUrl(fromProductId(relation.productId), changesetId)} onClick={(_, route) => navigate(route)}>{`${relation.product?.designation}`}</Link>
              )
            ) : (
              <></>
            )}
          </>
        )
      },
      { width: '10%', className: stateToStyle(tdStyle, relation.metadata?.state), children: <p>{relation.product?.manufacturer}</p> },
      { width: '5%', className: stateToStyle(tdStyle, relation.metadata?.state), children: <p className="text-right">{relation.quantity}</p> },
      {
        width: '5%',
        className: stateToStyle(tdStyle, relation.metadata?.state),
        children: <p className="text-center">{relation.upgradeFlag === 1 ? <Icon feIcon={Icons.Check} feColor={IconColors.Green} /> : <Icon feIcon={Icons.Close} feColor={IconColors.Red} />}</p>
      },
      { width: '15%', className: stateToStyle(tdStyle, relation.metadata?.state), children: <StatusIcon status={relation.status} /> },
      { width: '10%', className: stateToStyle(tdStyle, relation.metadata?.state), children: <p>{relation.label}</p> },
      { width: '20%', className: stateToStyle(tdStyle, relation.metadata?.state), children: <p>{relation.remark}</p> },
      {
        width: '5%',
        children: (
          <>
            {editMode && !isDeleted(relation.metadata?.state) && (
              <>
                <Link
                  as="button"
                  aria-label={intl.formatMessage({ id: 'part.button.edit' })}
                  feIcon={{ feIcon: Icons.Edit, position: 'left' }}
                  children=""
                  onClick={() => {
                    onEditPart(relations.indexOf(relation), relation);
                  }}
                />
                <Link as="button" aria-label={intl.formatMessage({ id: 'part.button.delete' })} feIcon={{ feIcon: Icons.Trash, position: 'left' }} children="" onClick={() => onDeletePart(relation, index)} />
              </>
            )}
          </>
        )
      }
    ]
  }));

  return (
    <>
      {_.some(filteredRelations) ? (
        <Table className="mt-5 mb-5" feBody={tableBody} feHead={tableHead} />
      ) : (
        <p className="mb-5">{`${intl.formatMessage({ id: 'part.empty.table' })} ${intl.formatMessage({ id: GetTraslationIdForEntityCategoryPlural(productCategory) })}`}</p>
      )}
    </>
  );
};

interface Props {
  productCategory: ProductCategory;
  relations: RelationshipProduct[];
  filteredRelations: RelationshipProduct[];
  editMode: boolean;
  onEditPart: any;
  onDeletePart: any;
}

export default ListOfPartsTable;
