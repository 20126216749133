import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, Heading, Icons } from '@skf/ferris';
import { GetTranslationIdForEntityCategory } from 'utils';
import { ChangesetDetails, PositionsCategory, ProductCategory, RecordCategory, RelationshipProduct } from 'domain/index';
import { FilterParts } from '../utils/relationship/relationship.utility';
import ListOfPartsDialog from './dialog/ListOfPartsDialog';
import ListOfPartsTable from './table/ListOfPartsTable';
import { EntityState, stateAsUnsaved } from '../entity-state';
import { SetNumberFieldErrors, SetRequiredError } from '../utils/validation/Validation.utility';

const ListOfParts = ({ entityId, changesetDetails, productCategory: category, recordCategory, positionCategory, relations, editMode, onPartChange }: Props) => {
  const intl = useIntl();
  const [formIsOpen, setFormIsOpen] = useState(false);
  const [filteredRelations, setFilteredRelations] = useState<RelationshipProduct[]>([]);
  const [partToEdit, setRelationToEdit] = useState<RelationshipProduct>({ positionCode: '', product: { designation: '', manufacturer: '' }, recordCategory } as RelationshipProduct);
  const [indexToEdit, setIndexToEdit] = useState<number | null>(null);
  const [formError, setFormError] = useState<Map<string, string>>(new Map());

  useEffect(() => {
    setFilteredRelations([...FilterParts(relations, recordCategory)]);
  }, [relations, recordCategory]);

  const deriveEntityState = (current?: EntityState): EntityState => {
    if (!current) {
      return EntityState.NEW_UNSAVED;
    }
    return stateAsUnsaved(current);
  };

  const handleFormChange = (value: RelationshipProduct) => {
    setRelationToEdit((prev) => {
      return {
        ...prev,
        productId: value.productId,
        product: value.product,
        label: value.label,
        positionCode: value.positionCode,
        quantity: value.quantity,
        remark: value.remark,
        status: value.status,
        upgradeFlag: value.upgradeFlag,
        metadata: {
          state: deriveEntityState(prev.metadata?.state)
        }
      };
    });
    setFormError((prev) => {
      let errors = SetNumberFieldErrors(value.quantity, 'quantity', Number.MAX_SAFE_INTEGER, prev, intl);
      errors = SetRequiredError(value.productId, 'productId', errors, intl);
      return errors;
    });
  };

  return (
    <>
      <Heading as="h2">{`${intl.formatMessage({ id: GetTranslationIdForEntityCategory(category) })}`}</Heading>
      <ListOfPartsTable
        productCategory={category}
        relations={relations}
        filteredRelations={filteredRelations}
        editMode={editMode}
        onEditPart={(index: number, relation: RelationshipProduct) => {
          setIndexToEdit(index);
          setRelationToEdit(relation);
          setFormIsOpen(true);
        }}
        onDeletePart={(relation: RelationshipProduct, index: number) =>
          onPartChange(
            filteredRelations.find((x) => x.recordId === relation.recordId),
            relations.indexOf(relation),
            'Delete'
          )
        }
      />
      {editMode && (
        <div className="w-full flex justify-end">
          <Button
            className="ml-auto mr-0"
            feType="secondary"
            feIcon={{ feIcon: Icons.Plus, position: 'left' }}
            onClick={() => {
              setRelationToEdit({ positionCode: '', status: '', remark: '', upgradeFlag: 0, product: { designation: '', manufacturer: '' }, recordCategory } as RelationshipProduct);
              setIndexToEdit(null);
              setFormError(SetRequiredError(undefined, 'productId', new Map(), intl));
              setFormIsOpen(true);
            }}
          >
            {intl.formatMessage({ id: 'part.button.add' })}
          </Button>
        </div>
      )}
      <ListOfPartsDialog
        entityId={entityId}
        recordCategory={recordCategory}
        positionCategory={positionCategory}
        partToEdit={partToEdit}
        indexToEdit={indexToEdit}
        changesetDetails={changesetDetails}
        isOpen={formIsOpen}
        formError={formError}
        onFormChange={(value: RelationshipProduct) => {
          handleFormChange(value);
        }}
        onSave={(partToEdit: RelationshipProduct, indexToEdit: number | null) => {
          onPartChange(partToEdit, indexToEdit, indexToEdit !== null ? 'Edit' : 'Add');
          setFormIsOpen(false);
        }}
        onCancel={() => {
          setFormIsOpen(false);
          setFormError(new Map());
          setRelationToEdit({ product: { manufacturer: {} }, recordCategory } as RelationshipProduct);
          setIndexToEdit(null);
        }}
      />
    </>
  );
};

interface Props {
  entityId: number;
  changesetDetails: ChangesetDetails | undefined;
  productCategory: ProductCategory;
  recordCategory: RecordCategory;
  positionCategory: PositionsCategory;
  relations: RelationshipProduct[];
  editMode: boolean;
  onPartChange: any;
}

export default ListOfParts;
