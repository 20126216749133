export enum PositionsCategory {
  GENERATOR = 'GENERATOR',
  GEARBOX = 'GEARBOX',
  MAIN_SHAFT = 'MAIN SHAFT',
  MAIN_SHAFT_SEAL = 'MAIN SHAFT SEAL',
  PITCH = 'PITCH',
  YAW = 'YAW',
  BLADE = 'BLADE',
  GENERAL = 'GENERAL'
}
