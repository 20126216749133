import { useLocation, useNavigate } from 'react-router';

const NavigationLink = ({ linkText, linkUrl, color, isParent, isChild }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const linkIsActive = (): boolean => location.pathname === linkUrl;

  if (linkIsActive()) {
    const element = document.getElementById(`id-${linkUrl}`);
    const container = document.getElementById('ticket-navigation');
    if (element && container) {
      let rectElem = element.getBoundingClientRect();
      let rectContainer = container.getBoundingClientRect();
      if (rectElem.bottom > rectContainer.bottom) {
        element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
      }
      if (rectElem.top < rectContainer.top) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      }
    }
  }

  return (
    <div className={`pt-1 pb-1 ${isChild ? 'pl-5' : ''} `}>
      <div id={`id-${linkUrl}`} className={`flex border border-gray-100 rounded drop-shadow cursor-pointer hover:bg-blue-light ${linkIsActive() ? 'bg-blue-light' : ''}`} onClick={() => navigate(linkUrl)}>
        <span className={`w-2 h-100 mr-2 rounded-l ${color}`}></span>
        <div className={`pt-1 pb-1 ${isParent ? 'font-bold' : ''}`}>{linkText}</div>
      </div>
    </div>
  );
};

interface Props {
  linkText: string;
  linkUrl: string;
  color: string;
  isParent?: boolean;
  isChild?: boolean;
}

export default NavigationLink;
