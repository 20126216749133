import CognitoUserData from './CognitoUserData';

export interface User {
  email: string;
  locale: string;
  phone_number: string;
  username: string;
}

export const createUser = (user: CognitoUserData): User => {
  return {
    username: user.username,
    email: user.attributes['email'],
    locale: user.attributes['locale'],
    phone_number: user.attributes['phone_number']
  } as User;
};
