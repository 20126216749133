import { AxiosResponse } from 'axios';
import { RelationshipGearbox, RelationshipGenerator, RelationshipProduct, RelationshipTurbine } from 'domain/index';
import { getAppService } from './service.base';
import { RelationGearbox, RelationGenerator, RelationTurbine } from 'domain/relationships/ObjectTurbineShareDetails';

const apiService = getAppService();

const withChangesetPrefix = (changesetId?: string) => (changesetId ? `changesets/${changesetId}/` : '');

export const GetRelatedComponentsForGearbox = async (id: number, changesetId?: string): Promise<AxiosResponse<RelationshipProduct[]>> => {
  return apiService.withGet(`${withChangesetPrefix(changesetId)}gearboxes/${id}/components`, 'Something went wrong while fetching relationships for gearbox.');
};

export const GetRelatedComponentsForGenerator = async (id: number, changesetId?: string): Promise<AxiosResponse<RelationshipProduct[]>> => {
  return apiService.withGet(`${withChangesetPrefix(changesetId)}generators/${id}/components`, 'Something went wrong while fetching relationships for generator.');
};

export const GetRelatedComponentsForTurbine = async (id: number, changesetId?: string): Promise<AxiosResponse<RelationshipProduct[]>> => {
  return apiService.withGet(`${withChangesetPrefix(changesetId)}turbines/${id}/components`, 'Something went wrong while fetching relationships for turbine.');
};

export const GetRelatedGearboxesForComponent = async (id: number, changesetId?: string): Promise<AxiosResponse<RelationshipGearbox[]>> => {
  return apiService.withGet(`${withChangesetPrefix(changesetId)}components/${id}/gearboxes`, 'Something went wrong while fetching relationships for component.');
};

export const GetRelatedGeneratorsForComponent = async (id: number, changesetId?: string): Promise<AxiosResponse<RelationshipGenerator[]>> => {
  return apiService.withGet(`${withChangesetPrefix(changesetId)}components/${id}/generators`, 'Something went wrong while fetching relationships for component.');
};

export const GetRelatedTurbinesForComponent = async (id: number, changesetId?: string): Promise<AxiosResponse<RelationshipTurbine[]>> => {
  return apiService.withGet(`${withChangesetPrefix(changesetId)}components/${id}/turbines`, 'Something went wrong while fetching relationships for component.');
};

export const GetRelatedGearboxesForTurbine = async (turbineId: number, changesetId?: string): Promise<AxiosResponse<RelationGearbox[]>> => {
  return apiService.withGet(`${withChangesetPrefix(changesetId)}turbines/${turbineId}/related/gearboxes`, 'Something went wrong while fetching relationships for turbine.');
};

export const GetRelatedGeneratorsForTurbine = async (turbineId: number, changesetId?: string): Promise<AxiosResponse<RelationGenerator[]>> => {
  return apiService.withGet(`${withChangesetPrefix(changesetId)}turbines/${turbineId}/related/generators`, 'Something went wrong while fetching relationships for turbine.');
};

export const GetRelatedTurbinesForGearbox = async (gearboxId: number, changesetId?: string): Promise<AxiosResponse<RelationTurbine[]>> => {
  return apiService.withGet(`${withChangesetPrefix(changesetId)}gearboxes/${gearboxId}/related/turbines`, 'Something went wrong while fetching relationships for gearbox.');
};

export const GetRelatedTurbinesForGenerator = async (generatorId: number, changesetId?: string): Promise<AxiosResponse<RelationTurbine[]>> => {
  return apiService.withGet(`${withChangesetPrefix(changesetId)}generators/${generatorId}/related/turbines`, 'Something went wrong while fetching relationships for generator.');
};
