import { ChangesetStatus } from 'domain/changeset/ChangesetStatus';
import { Pagination, Sorting } from './ChangesetSearchQuery';
import { Changeset } from 'domain/changeset/Changeset';
import { ProductCategory, State } from 'domain/index';
import { ObjectType } from 'domain/shared/ObjectId';

export interface StatusFacet {
  values: { value: ChangesetStatus; count: number }[];
  selected: ChangesetStatus;
}

export interface Hit {
  score: number;
  changesetId: string;
  status: ChangesetStatus;
  comment: string;
  version: number;
  createdByUserName: string;
  createdByEmail: string;
  createdAt: string;
  lastModifiedByUserName: string;
  lastModifiedByEmail: string;
  refNames: string[];
  refIds: number[];
  refTypes: string[];
  userRequestCategory: string;
  userRequestComment: string;
}

export interface ChangesetSearchResult {
  total: number;
  pagination?: Pagination;
  sort?: Sorting;
  hits: Hit[];
  statusFacets: StatusFacet;
}

export const convertToChangesets = (hits: Hit[]): Changeset[] => {
  return hits.map((sc) => ({
    changesetId: sc.changesetId,
    status: sc.status,
    createdBy: { username: sc.createdByUserName, email: sc.createdByEmail },
    createdAt: sc.createdAt,
    lastModifiedBy: { username: sc.lastModifiedByUserName, email: sc.lastModifiedByEmail },
    lastModifiedAt: new Date().toISOString(),
    userRequest: { category: sc.userRequestCategory, comment: sc.userRequestComment },
    comment: sc.comment,
    refs: sc.refIds.map((id, index) => ({
      id,
      name: sc.refNames[index],
      type: sc.refTypes[index] as ProductCategory | ObjectType,
      state: State.MODIFIED
    }))
  }));
};
