import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import { QueryParamBuilder } from '../services/QueryParamBuilder';
import { performQuery } from '../store';

const useLocationSearch = () => {
  const [search, setSearch] = useSearchParams();
  const isSearch = useLocation().pathname === '/search';
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector((state) => state.userData.isAuthenticated);
  const activeQuery = useAppSelector((state) => state.searchData.query);
  const activeQueryIsUndefined = useAppSelector((state) => state.searchData.query.isEmpty === undefined);

  const performSearch = useCallback(() => {
    if (search.toString() !== '' || activeQueryIsUndefined) {
      dispatch(performQuery(new QueryParamBuilder().fromParams(search).getQuery()));
    }
  }, [search, dispatch, activeQueryIsUndefined]);

  useEffect(() => {
    if (isSearch && isAuthenticated) {
      performSearch();
    }
  }, [isSearch, performSearch, isAuthenticated]);

  useEffect(() => {
    const newParams = new QueryParamBuilder().fromQuery(activeQuery).build();
    if ((newParams.toString() !== '' && newParams.toString() !== search.toString()) || (newParams.toString() === '' && activeQuery.isEmpty)) {
      setSearch(newParams);
    }
  }, [activeQuery, search, setSearch]);

  return null;
};

export default useLocationSearch;
