import { Alert, Icons } from '@skf/ferris';
import { EditWarning, EditWarningType } from 'domain/shared/Warning';
import { useIntl } from 'react-intl';

const EditWarnings = ({ warnings, entityId, closeWarning }: EditWarningProps) => {
  const intl = useIntl();

  const getWarningMessage = (type: EditWarningType, message: string): string => {
    return intl.formatMessage({ id: `warning.${type}` }, { message });
  };

  return (
    <div className="mb-2">
      {warnings
        .filter((warning) => warning && warning.entityId === entityId)
        .map((warning, index) => (
          <Alert key={index} feIcon={Icons.Warning} feSeverity="warning" children={getWarningMessage(warning.type, warning.message ?? '')} feCloseButton={{ onClick: () => closeWarning(warning) }} />
        ))}
    </div>
  );
};

type EditWarningProps = {
  warnings: Array<EditWarning>;
  entityId: number;
  closeWarning: (warning: EditWarning) => void;
};

export default EditWarnings;
