import { Checkbox, Fieldset, NumberField, Spacer, TextField } from '@skf/ferris';
import { BaseSeverity } from '@skf/ferris/dist/esm/common/types/severity';
import { ChangesetDetails, Turbine } from 'domain/index';
import SelectWrapper from 'features/edit/shared/select-wrapper/SelectWrapper';
import { GetHint, GetHintForCombination, GetSeverity } from 'features/edit/shared/utils/form/Form.utility';
import { useIntl } from 'react-intl';
import { ObjectType } from '../../../../domain/shared/ObjectId';
import ManufacturerSelect from 'features/edit/shared/manufacturer-select/ManufacturerSelect';

const TurbineForm = ({ turbine, editMode, onTurbineChange, formError, changesetDetails }: ProductProps) => {
  const intl = useIntl();
  const onTurbineNameChange = (event: any, value: string) => {
    onTurbineChange({ ...turbine, turbineName: value });
  };
  const onManufacturerChange = (value: number, label: string) => {
    onTurbineChange({ ...turbine, manufacturerId: value, manufacturer: { manufacturerName: label } });
  };
  const onDriveTypeChange = (value: string) => {
    onTurbineChange({ ...turbine, driveType: value });
  };
  const onPowerOutputChange = (event: any, value: number) => {
    onTurbineChange({ ...turbine, powerOutput: value });
  };
  const onRotorDiameterChange = (event: any, value: number) => {
    onTurbineChange({ ...turbine, rotorDiameter: value });
  };
  const onSuspensionTypeChange = (value: string) => {
    onTurbineChange({ ...turbine, suspensionType: value });
  };
  const onTurbineTypeChange = (value: string) => {
    onTurbineChange({ ...turbine, turbineType: value });
  };
  const onTurbineLicencedChange = (value: string) => {
    onTurbineChange({ ...turbine, turbineLicenced: value });
  };
  const onWindClassChange = (value: string) => {
    onTurbineChange({ ...turbine, windClass: value });
  };
  const onGridFrequencyChange = (value: string) => {
    onTurbineChange({ ...turbine, gridFrequency: value });
  };
  const onDesignChange = (event: any, value: string) => {
    onTurbineChange({ ...turbine, design: value });
  };
  const onWindconSystemFlagChange = (event: any, checked: boolean) => {
    if (checked) {
      onTurbineChange({ ...turbine, windconSystemFlag: 1 });
    } else {
      onTurbineChange({ ...turbine, windconSystemFlag: 0, windconSystemLink: '' });
    }
  };
  const onWindconSystemLinkChange = (event: any, value: string) => {
    onTurbineChange({ ...turbine, windconSystemLink: value });
  };
  const onCompleteFlagChange = (event: any, value: boolean) => {
    if (value === true) {
      onTurbineChange({ ...turbine, completeFlag: 1 });
    } else {
      onTurbineChange({ ...turbine, completeFlag: 0 });
    }
  };
  const onDataApprovalChange = (event: any, value: boolean) => {
    if (value === true) {
      onTurbineChange({ ...turbine, dataApproval: 1 });
    } else {
      onTurbineChange({ ...turbine, dataApproval: 0 });
    }
  };
  const getSeverity = (fieldName: string): BaseSeverity | undefined => {
    return GetSeverity(turbine.turbineId, formError, fieldName, changesetDetails);
  };
  const getHint = (fieldName: string): string | undefined => {
    return GetHint(turbine.turbineId, formError, fieldName, changesetDetails, intl);
  };
  const getHintForWindconSystem = (): string | undefined => {
    return GetHintForCombination(turbine.turbineId, formError, 'windconSystemFlag', 'windconSystemLink', changesetDetails, intl);
  };

  return (
    <div className="flex flex-wrap ">
      <div className="w-1/4 pl-2 pr-2">
        <TextField
          required
          disabled={!editMode}
          onChange={onTurbineNameChange}
          value={turbine.turbineName}
          feLabel={intl.formatMessage({ id: 'turbine.turbineName' })}
          feHint={getHint('turbineName')}
          feSeverity={getSeverity('turbineName')}
        />
        <Spacer />
      </div>
      <div className="w-1/4 pl-2 pr-2">
        <ManufacturerSelect
          objectType={ObjectType.TURBINE}
          labelId="turbine.manufacturerId"
          required
          onChange={onManufacturerChange}
          editMode={editMode}
          initialValue={turbine.manufacturer?.manufacturerName}
          value={turbine.manufacturerId}
          hint={getHint('manufacturerName')}
          severity={getSeverity('manufacturerName')}
        />
        <Spacer />
      </div>
      <div className="w-1/4 pl-2 pr-2">
        <SelectWrapper
          value={turbine.driveType}
          required
          fieldName="driveType"
          label={intl.formatMessage({ id: 'turbine.driveType' })}
          objectType={ObjectType.TURBINE}
          onValueChange={onDriveTypeChange}
          editMode={editMode}
          hint={getHint('driveType')}
          severity={getSeverity('driveType')}
        />
        <Spacer />
      </div>
      <div className="w-1/4 pl-2 pr-2">
        <NumberField
          disabled={!editMode}
          required
          onChange={onPowerOutputChange}
          value={turbine.powerOutput}
          feLabel={intl.formatMessage({ id: 'turbine.powerOutput' })}
          feHint={getHint('powerOutput')}
          feSeverity={getSeverity('powerOutput')}
          min={0}
        />
        <Spacer />
      </div>
      <div className="w-1/4 pl-2 pr-2">
        <NumberField
          disabled={!editMode}
          onChange={onRotorDiameterChange}
          value={turbine.rotorDiameter}
          feLabel={intl.formatMessage({ id: 'turbine.rotorDiameter' })}
          feHint={getHint('rotorDiameter')}
          feSeverity={getSeverity('rotorDiameter')}
          min={0}
        />
        <Spacer />
      </div>
      <div className="w-1/4 pl-2 pr-2">
        <SelectWrapper
          value={turbine.suspensionType}
          fieldName="suspensionType"
          label={intl.formatMessage({ id: 'turbine.suspensionType' })}
          objectType={ObjectType.TURBINE}
          onValueChange={onSuspensionTypeChange}
          editMode={editMode}
          hint={getHint('suspensionType')}
          severity={getSeverity('suspensionType')}
        />
        <Spacer />
      </div>
      <div className="w-1/4 pl-2 pr-2">
        <SelectWrapper
          value={turbine.turbineType}
          fieldName="turbineType"
          label={intl.formatMessage({ id: 'turbine.turbineType' })}
          objectType={ObjectType.TURBINE}
          onValueChange={onTurbineTypeChange}
          editMode={editMode}
          hint={getHint('turbineType')}
          severity={getSeverity('turbineType')}
        />
        <Spacer />
      </div>
      <div className="w-1/4 pl-2 pr-2">
        <SelectWrapper
          value={turbine.turbineLicenced}
          fieldName="turbineLicenced"
          label={intl.formatMessage({ id: 'turbine.turbineLicenced' })}
          objectType={ObjectType.TURBINE}
          onValueChange={onTurbineLicencedChange}
          editMode={editMode}
          hint={getHint('turbineLicenced')}
          severity={getSeverity('turbineLicenced')}
        />
        <Spacer />
      </div>
      <div className="w-1/4 pl-2 pr-2">
        <SelectWrapper
          value={turbine.windClass}
          fieldName="windClass"
          label={intl.formatMessage({ id: 'turbine.windClass' })}
          objectType={ObjectType.TURBINE}
          onValueChange={onWindClassChange}
          editMode={editMode}
          hint={getHint('windClass')}
          severity={getSeverity('windClass')}
        />
        <Spacer />
      </div>
      <div className="w-1/4 pl-2 pr-2">
        <SelectWrapper
          value={turbine.gridFrequency}
          fieldName="gridFrequency"
          label={intl.formatMessage({ id: 'turbine.gridFrequency' })}
          objectType={ObjectType.TURBINE}
          onValueChange={onGridFrequencyChange}
          editMode={editMode}
          hint={getHint('gridFrequency')}
          severity={getSeverity('gridFrequency')}
        />
        <Spacer />
      </div>
      <div className="w-1/2 pl-2 pr-2">
        <TextField disabled={true} onChange={onDesignChange} value={turbine.design} feLabel={intl.formatMessage({ id: 'turbine.design' })} feHint={getHint('design')} feSeverity={getSeverity('design')} />
        <Spacer />
      </div>
      <div className="w-2/4 pl-2 pr-2">
        <Fieldset feLegend={intl.formatMessage({ id: 'turbine.windconSystemLink' })}>
          <div className="flex">
            <Checkbox disabled={!editMode} onChange={onWindconSystemFlagChange} defaultChecked={turbine.windconSystemFlag === 1 ? true : false} feLabel={intl.formatMessage({ id: 'turbine.windconSystemLink' })} feHideLabel={true} />
            <TextField
              className="w-full"
              disabled={!editMode || turbine.windconSystemFlag === 0 || turbine.windconSystemFlag === undefined}
              onChange={onWindconSystemLinkChange}
              value={turbine.windconSystemLink}
              feLabel={''}
              feHideLabel={true}
              feHint={getHintForWindconSystem()}
              feSeverity={getSeverity('windconSystemLink')}
            />
          </div>
        </Fieldset>
        <Spacer />
      </div>
      <div className="w-1/6 pl-2 mt-11">
        <Checkbox feLabel={intl.formatMessage({ id: 'turbine.completeFlag' })} checked={turbine.completeFlag === 1} disabled={!editMode} onChange={onCompleteFlagChange} />
      </div>
      <div className="w-1/6 mt-11">
        <Checkbox feLabel={intl.formatMessage({ id: 'turbine.dataApproval' })} checked={turbine.dataApproval === 1} disabled={!editMode} onChange={onDataApprovalChange} />
      </div>
    </div>
  );
};

export default TurbineForm;

interface ProductProps {
  turbine: Turbine;
  editMode: boolean;
  onTurbineChange: any;
  formError: Map<string, string>;
  changesetDetails?: ChangesetDetails;
}
