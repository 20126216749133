import { useIntl } from 'react-intl';
import { Bookmark, ProductCategory } from 'domain/index';
import { GetEntityUrl, GetTranslationIdForEntityCategory } from 'utils/index';
import NavigationLink from '../navigation-link/NavigationLink';
import { asObjectId, ObjectType } from '../../../domain/shared/ObjectId';

const Bookmarks = ({ bookmarks }: Props) => {
  const intl = useIntl();

  const getTitle = (name: string | undefined, category: string): string => `${intl.formatMessage({ id: GetTranslationIdForEntityCategory(category) })} ${name}`;
  const getUrl = (category: ObjectType | ProductCategory, id: number): string => GetEntityUrl(asObjectId(id, category));

  return (
    <div className="pb-1 pr-1">
      {bookmarks.map((bookmark) => (
        <NavigationLink key={bookmark.id} color="bg-gray-300" linkText={getTitle(bookmark.name, bookmark.category)} linkUrl={getUrl(bookmark.category, bookmark.id)} />
      ))}
    </div>
  );
};

interface Props {
  bookmarks: Bookmark[];
}

export default Bookmarks;
