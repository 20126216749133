import { Pagination } from '@skf/ferris';
import { useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from 'store';
import { defaultPagination } from '../domain/SearchQuery';
import { setPagination } from '../store';

const SearchPagination = () => {
  const intl = useIntl();
  const { total, pagination } = useAppSelector((state) => state.searchData.result ?? { total: 0, pagination: defaultPagination });
  const dispatch = useAppDispatch();

  const onPageChange = (newPage: number) => {
    dispatch(
      setPagination({
        skip: (newPage - 1) * defaultPagination.size,
        size: defaultPagination.size
      })
    );
  };

  return (
    <Pagination
      feControls={{
        next: {
          'aria-label': intl.formatMessage({ id: 'search.result.next' })
        },
        prev: {
          'aria-label': intl.formatMessage({ id: 'search.result.previous' })
        }
      }}
      feOnPageChange={(page) => onPageChange(page)}
      feItemsPerPage={defaultPagination.size}
      fePage={((pagination?.skip ?? defaultPagination.size) + defaultPagination.size) / defaultPagination.size}
      feTotal={total ?? 0}
    />
  );
};

export default SearchPagination;
