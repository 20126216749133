import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router';
import { Icons, Link, Table, TableRow, Icon, IconSizes, Flexbox, useTooltip } from '@skf/ferris';
import { GetEntityUrl, GetTraslationIdForEntityCategoryPlural } from 'utils';
import { RelationTurbineNullable, RelationGearbox } from 'domain/index';
import _ from 'lodash';
import { fromTurbineId, fromGearboxId } from '../../../../../domain/shared/ObjectId';
import { EntityState, stateToColor, stateToStyle } from '../../entity-state';
import { useEffect, useRef } from 'react';

const GearboxTable = ({ gearboxes, editMode, onEditGearbox, onDeleteGearbox }: Props) => {
  const intl = useIntl();

  const tableHead: TableRow[] = [
    {
      cells: [
        { children: '', scope: 'col' },
        { children: intl.formatMessage({ id: 'windTurbine.name' }), scope: 'col' },
        { children: intl.formatMessage({ id: 'windTurbine.manufacturer' }), scope: 'col' },
        { children: intl.formatMessage({ id: 'windTurbine.marketShare' }), scope: 'col' },
        { children: intl.formatMessage({ id: 'windTurbine.remark' }), scope: 'col' },
        { children: '', scope: 'col' }
      ]
    }
  ];

  const tdStyle = 'align-top whitespace-normal';

  const tableBody: TableRow[] = gearboxes.map((gearbox, index) => ({
    cells: [
      { width: '1%', className: stateToStyle(tdStyle, gearbox.metadata?.state), children: <div title={'status'} className={`w-3 h-10 ${stateToColor(gearbox.metadata?.state)}`}></div> },
      {
        width: '20%',
        className: stateToStyle(tdStyle, gearbox.metadata?.state),
        children: <WindTurbineNameCell gearbox={gearbox} editMode={editMode} />
      },
      { width: '20%', className: stateToStyle(tdStyle, gearbox.metadata?.state), children: <p>{gearbox.gearbox?.manufacturer?.manufacturerName ?? ''}</p> },
      { width: '10%', className: stateToStyle(tdStyle, gearbox.metadata?.state), children: <p className="text-right">{gearbox.marketShare}</p> },
      { width: '45%', className: stateToStyle(tdStyle, gearbox.metadata?.state), children: <p>{gearbox.remark}</p> },
      {
        width: '5%',
        className: 'align-top',
        children: (
          <>
            {editMode && gearbox.metadata?.state !== EntityState.DELETED && gearbox.metadata?.state !== EntityState.DELETED_UNSAVED && (
              <>
                <Link
                  as="button"
                  aria-label={intl.formatMessage({ id: 'windTurbine.button.edit' })}
                  feIcon={{ feIcon: Icons.Edit, position: 'left' }}
                  children=""
                  onClick={() => {
                    onEditGearbox(gearbox, index);
                  }}
                />
                <Link
                  as="button"
                  aria-label={intl.formatMessage({ id: 'windTurbine.button.delete' })}
                  feIcon={{ feIcon: Icons.Trash, position: 'left' }}
                  children=""
                  onClick={() => {
                    onDeleteGearbox(
                      gearboxes.find((x) => x.objectId === gearbox.objectId),
                      index
                    );
                  }}
                />
              </>
            )}
          </>
        )
      }
    ]
  }));

  return (
    <>
      {_.some(gearboxes) ? (
        <Table className="mt-5 mb-5" feBody={tableBody} feHead={tableHead} />
      ) : (
        <p className="mb-5">{`${intl.formatMessage({ id: 'windTurbine.empty.table' })} ${intl.formatMessage({ id: GetTraslationIdForEntityCategoryPlural('Gearbox') })}`}</p>
      )}
    </>
  );
};

interface Props {
  gearboxes: RelationGearbox[];
  editMode: boolean;
  onEditGearbox: any;
  onDeleteGearbox: any;
}

const WindTurbineNameCell = ({ gearbox, editMode }: { gearbox: RelationGearbox; editMode: boolean }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { changesetId } = useParams();
  const { addPopover } = useTooltip();
  const tooltipRef = useRef<HTMLDivElement | null>(null);

  const handleMouseOver = () => {
    addPopover({ content: intl.formatMessage({ id: 'windTurbine.missing.tooltip' }), feType: 'tooltip' }, tooltipRef.current);
  };

  if (!gearbox.gearbox) {
    return (
      <Flexbox className="cursor-pointer" onMouseOver={handleMouseOver} ref={tooltipRef}>
        {intl.formatMessage({ id: 'windTurbine.missing' }, { id: gearbox.objectId })}
        <Icon className="ml-2" aria-label="Information" feIcon={Icons.InfoCircleOutlined} feSize={IconSizes.Lg} title="Test" />
      </Flexbox>
    );
  } else if (editMode) {
    return <p>{gearbox.gearbox?.gearboxName ?? ''}</p>;
  } else {
    return <Link as="a" href={GetEntityUrl(fromGearboxId(gearbox.objectId), changesetId)} onClick={(_, route) => navigate(route)}>{`${gearbox.gearbox.gearboxName}`}</Link>;
  }
};

export default GearboxTable;
