import { ProductCategory } from '../products/ProductCategory';

export enum ObjectType {
  PRODUCT = 'Product',
  GEARBOX = 'Gearbox',
  GENERATOR = 'Generator',
  TURBINE = 'Turbine',
  MANUFACTURER = 'Manufacturer'
}

/**
 * Implementation notes:
 *
 * An id is supposed to be unique however, there is no proper constraint in master
 * database so lets be extra careful...Also note that in master database
 * the id is spread across multiple tables.
 *
 */
export class ObjectId {
  id: number;
  type: ObjectType;

  constructor(id: number, type: ObjectType) {
    this.id = id;
    this.type = type;
  }
}

export const asKey = (objectId: ObjectId): string => `${objectId.type}:${objectId.id}`;

const isProductType = (type: string) => {
  // The onewind web client is sending some variation on the product category
  // that we need to handle as product type. These are specifically used for the user requests.
  // It is only needed by this client - backend actually removes the empty refs
  // once the object is being edited.
  // See https://skfgroup.atlassian.net/browse/OWA-413
  const normalize = (str: string) => str.replace(/\s/g, '').toLowerCase();
  const normalizedType = normalize(type);
  return Object.values(ProductCategory).some((value) => normalize(value) === normalizedType);
};

export const asObjectId = (id: number, type: string): ObjectId => {
  if (isProductType(type)) {
    return fromProductId(id);
  }
  return new ObjectId(id, type as ObjectType);
};

export const fromProductId = (id: number): ObjectId => new ObjectId(id, ObjectType.PRODUCT);

export const fromGearboxId = (id: number): ObjectId => new ObjectId(id, ObjectType.GEARBOX);

export const fromGeneratorId = (id: number): ObjectId => new ObjectId(id, ObjectType.GENERATOR);

export const fromTurbineId = (id: number): ObjectId => new ObjectId(id, ObjectType.TURBINE);

export const fromManufacturerId = (id: number): ObjectId => new ObjectId(id, ObjectType.MANUFACTURER);
