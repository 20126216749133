import { ObjectDetails, RelationTurbine, RelationshipProduct, RelationGearbox, RelationGenerator } from 'domain/index';
import _ from 'lodash';
import { EntityState } from '../../features/edit/shared/entity-state';
import {
  AddObjectTurbineDetailsChange,
  RemoveObjectTurbineDetailsChange,
  UpdateObjectTurbineDetailsChange,
  AddObjectGearboxDetailsChange,
  UpdateObjectGearboxDetailsChange,
  RemoveObjectGearboxDetailsChange,
  AddObjectGeneratorDetailsChange,
  UpdateObjectGeneratorDetailsChange,
  RemoveObjectGeneratorDetailsChange
} from 'domain/api/relationships/TurbineShareDetails';
import { asObjectId, fromTurbineId, fromGearboxId, fromGeneratorId } from 'domain/shared/ObjectId';

export const ObjectComparison = <Type>(updatedObject: Type, originalObject: Type): Type => {
  let differenceObj: any = {};
  for (const key in updatedObject) {
    if (Object.prototype.hasOwnProperty.call(updatedObject, key)) {
      if (!_.isEqual(updatedObject[key], originalObject[key])) {
        differenceObj[key] = updatedObject[key];
      }
    }
  }
  return differenceObj;
};

export const ListComparison = <Type>(updatedList: Type[], originalList: Type[]) => {
  let added = _.differenceWith(updatedList, originalList, _.isEqual);
  let removed = _.differenceWith(originalList, updatedList, _.isEqual);
  return [added, removed] as const;
};

export const TurbineShareDetailsComparison = (
  turbines: RelationTurbine[],
  originalTurbines: RelationTurbine[]
): [added: AddObjectTurbineDetailsChange[], updated: UpdateObjectTurbineDetailsChange[], removed: RemoveObjectTurbineDetailsChange[]] => {
  const addedOrUpdated = _.differenceWith(turbines, originalTurbines, _.isEqual);
  const added = addedOrUpdated
    .filter((t) => t.metadata?.state === EntityState.NEW || t.metadata?.state === EntityState.NEW_UNSAVED)
    .map((t) => ({
      turbineId: fromTurbineId(t.turbineId),
      change: {
        marketShare: t.marketShare ?? undefined,
        remark: t.remark ?? undefined
      }
    }));
  const updated = addedOrUpdated
    .filter((t) => t.metadata?.state === EntityState.MODIFIED || t.metadata?.state === EntityState.MODIFIED_UNSAVED)
    .map((t) => {
      const originalTurbineDetails = originalTurbines.find((o) => o.recordId === t.recordId);
      //Check if turbine id has changed
      if (originalTurbineDetails && originalTurbineDetails.turbineId !== t.turbineId) {
        return {
          turbineId: fromTurbineId(originalTurbineDetails.turbineId),
          recordId: t.recordId!,
          change: {
            marketShare: t.marketShare ?? undefined,
            remark: t.remark ?? undefined,
            turbineId: asObjectId(t.turbineId, 'Turbine')
          }
        };
      } else {
        return {
          turbineId: fromTurbineId(t.turbineId),
          recordId: t.recordId!,
          change: {
            marketShare: t.marketShare ?? undefined,
            remark: t.remark ?? undefined
          }
        };
      }
    })
    .filter((t) => t) as UpdateObjectTurbineDetailsChange[];

  const removed = turbines
    .filter((t) => (t.metadata?.state ?? '') === EntityState.DELETED_UNSAVED)
    .map((t) => ({
      turbineId: fromTurbineId(t.turbineId),
      recordId: t.recordId!
    }));
  return [added, updated, removed];
};

export const GearboxShareDetailsComparison = (
  gearboxes: RelationGearbox[],
  originalGearboxes: RelationGearbox[]
): [added: AddObjectGearboxDetailsChange[], update: UpdateObjectGearboxDetailsChange[], removed: RemoveObjectGearboxDetailsChange[]] => {
  const addedOrUpdated = _.differenceWith(gearboxes, originalGearboxes, _.isEqual);
  const added = addedOrUpdated
    .filter((t) => t.metadata?.state === EntityState.NEW || t.metadata?.state === EntityState.NEW_UNSAVED)
    .map((t) => ({
      objectId: fromGearboxId(t.objectId),
      change: {
        marketShare: t.marketShare ?? undefined,
        remark: t.remark ?? undefined
      }
    }));
  const updated = addedOrUpdated
    .filter((t) => t.metadata?.state === EntityState.MODIFIED || t.metadata?.state === EntityState.MODIFIED_UNSAVED)
    .map((t) => {
      const originalGearboxDetails = originalGearboxes.find((o) => o.recordId === t.recordId);

      if (originalGearboxDetails && originalGearboxDetails.objectId !== t.objectId) {
        return {
          turbineId: fromTurbineId(originalGearboxDetails.turbineId),
          objectId: fromGearboxId(t.objectId),
          recordId: t.recordId!,
          change: {
            marketShare: t.marketShare ?? undefined,
            remark: t.remark ?? undefined,
            gearbox: t.gearbox ?? undefined
          }
        };
      } else {
        return {
          turbineId: fromTurbineId(t.turbineId),
          recordId: t.recordId!,
          objectId: fromGearboxId(t.objectId),
          change: {
            marketShare: t.marketShare ?? undefined,
            remark: t.remark ?? undefined
          }
        };
      }
    })
    .filter((t) => t) as UpdateObjectGearboxDetailsChange[];

  const removed = gearboxes
    .filter((t) => (t.metadata?.state ?? '') === EntityState.DELETED_UNSAVED)
    .map((t) => ({
      turbineId: fromTurbineId(t.turbineId),
      objectId: fromGearboxId(t.objectId),
      recordId: t.recordId!
    }));
  return [added, updated, removed];
};

export const GeneratorShareDetailsComparison = (
  generators: RelationGenerator[],
  originalGenerators: RelationGenerator[]
): [added: AddObjectGeneratorDetailsChange[], update: UpdateObjectGeneratorDetailsChange[], removed: RemoveObjectGeneratorDetailsChange[]] => {
  const addedOrUpdated = _.differenceWith(generators, originalGenerators, _.isEqual);
  const added = addedOrUpdated
    .filter((t) => t.metadata?.state === EntityState.NEW || t.metadata?.state === EntityState.NEW_UNSAVED)
    .map((t) => ({
      objectId: fromGeneratorId(t.objectId),
      change: {
        marketShare: t.marketShare ?? undefined,
        remark: t.remark ?? undefined
      }
    }));
  const updated = addedOrUpdated
    .filter((t) => t.metadata?.state === EntityState.MODIFIED || t.metadata?.state === EntityState.MODIFIED_UNSAVED)
    .map((t) => {
      const originalGeneratorDetails = originalGenerators.find((o) => o.recordId === t.recordId);

      if (originalGeneratorDetails && originalGeneratorDetails.objectId !== t.objectId) {
        return {
          turbineId: fromTurbineId(originalGeneratorDetails.turbineId),
          objectId: fromGeneratorId(t.objectId),
          recordId: t.recordId!,
          change: {
            marketShare: t.marketShare ?? undefined,
            remark: t.remark ?? undefined,
            generator: t.generator ?? undefined
          }
        };
      } else {
        return {
          turbineId: fromTurbineId(t.turbineId),
          recordId: t.recordId!,
          objectId: fromGeneratorId(t.objectId),
          change: {
            marketShare: t.marketShare ?? undefined,
            remark: t.remark ?? undefined
          }
        };
      }
    })
    .filter((t) => t) as UpdateObjectGeneratorDetailsChange[];

  const removed = generators
    .filter((t) => (t.metadata?.state ?? '') === EntityState.DELETED_UNSAVED)
    .map((t) => ({
      turbineId: fromTurbineId(t.turbineId),
      objectId: fromGeneratorId(t.objectId),
      recordId: t.recordId!
    }));
  return [added, updated, removed];
};

export const ProductPartsComparison = (listOfParts: RelationshipProduct[], listOfPartsOriginal: RelationshipProduct[]) => {
  const updatedObjectDetails = GetUpdatedProducts(listOfParts, listOfPartsOriginal);
  const addedObjectDetails = [...listOfParts.filter((x) => !x.recordId).map((x) => asNewObjectDetail(x))];
  const removedObjectDetails = [...listOfParts.filter((part) => part.metadata?.state === EntityState.DELETED_UNSAVED).map((x) => ({ recordId: x.recordId, recordCategory: x.recordCategory }))];

  return [addedObjectDetails, updatedObjectDetails, removedObjectDetails];
};

const copyWithoutDecorators = (rp: RelationshipProduct): RelationshipProduct => {
  // Since redux creates a read-only state we need to copy the data first before modifying it
  const copy = { ...rp } as RelationshipProduct;

  delete copy.metadata;
  delete copy.product;
  return copy;
};

const GetUpdatedProducts = (updatedList: RelationshipProduct[], originalList: RelationshipProduct[]): any[] => {
  const updatedParts = [] as any[];
  updatedList.forEach((updatedPart) => {
    const originalPart = originalList.find((x) => x.recordId === updatedPart.recordId && x.recordCategory === updatedPart.recordCategory);
    if (!!originalPart) {
      // Compare without decorators/additional objects
      const comparison = ObjectComparison(copyWithoutDecorators(updatedPart), copyWithoutDecorators(originalPart));
      if (Object.keys(comparison).length > 0) {
        // If productId has changed we actually want the
        // additional product data provided for convenience.
        // Otherwise not...
        if (comparison.productId) {
          comparison.product = updatedPart.product;
        }
        updatedParts.push({ ...{ recordId: updatedPart.recordId, recordCategory: updatedPart.recordCategory }, change: { ...comparison } });
      }
    }
  });
  return updatedParts;
};

const asNewObjectDetail = (part: RelationshipProduct): ObjectDetails => {
  return {
    productId: part.productId,
    recordCategory: part.recordCategory,
    change: {
      label: part.label,
      positionCode: part.positionCode,
      productId: part.productId,
      quantity: part.quantity,
      remark: part.remark,
      status: part.status,
      strategicFlag: part.strategicFlag,
      upgradeFlag: part.upgradeFlag
    }
  };
};
