import { TurbineDetailsDifference, TurbineDetailsDifferenceMutated, TurbineDetailsDifferences } from 'domain/changeset/details/Difference';
import { useIntl } from 'react-intl';
import { GetTraslationIdForField } from 'utils';
import ChangeLogValueItem from './ChangeLogValueItem';

const ChangeLogTurbineDetails = ({ differences, type }: { differences: TurbineDetailsDifferences; type: string }) => {
  const intl = useIntl();
  return (
    <>
      <span className="font-bold">{intl.formatMessage({ id: GetTraslationIdForField(type, 'turbineDetails') })}:</span>
      <TurbineStateDifferenceWithProperties difference={differences.added} type="added" />
      <TurbineStateDifferenceWithProperties difference={differences.updated} type="updated" />
      <TurbineStateDifference difference={differences.removed} type="removed" />
    </>
  );
};

const TurbineStateDifferenceWithProperties = ({ difference, type }: { difference?: TurbineDetailsDifferenceMutated[]; type: 'added' | 'removed' | 'updated' }) => {
  const intl = useIntl();
  return (
    <div className="pb-2 flex flex-col">
      {difference?.map((item, index) => (
        <div key={index} className="flex flex-col mt-2">
          <p className="font-bold">
            {intl.formatMessage({ id: 'changelog.' + type })}: {item.turbine?.turbineName}
          </p>
          {item.properties.map((prop, index) =>
            prop.newValue !== prop.oldValue && prop.name !== 'turbineName' ? (
              <div key={index} className="flex">
                <p className="pr-1">{`${intl.formatMessage({ id: `${GetTraslationIdForField('turbineDetails', prop.name)}` })}:`}</p>
                <ChangeLogValueItem name={prop.name} newValue={prop.newValue} oldValue={prop.oldValue} />
              </div>
            ) : null
          )}
        </div>
      ))}
    </div>
  );
};

const TurbineStateDifference = ({ difference, type }: { difference?: TurbineDetailsDifference[]; type: 'added' | 'removed' | 'updated' }) => {
  const intl = useIntl();
  return (
    <div className="pb-2 flex flex-col">
      {difference?.map((item, index) => (
        <p className="font-bold" key={index}>
          {intl.formatMessage({ id: 'changelog.' + type })}: {item.turbine.turbineName}
        </p>
      ))}
    </div>
  );
};

export default ChangeLogTurbineDetails;
