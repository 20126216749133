import { Changeset } from 'domain/index';
import { ChangesetIsActive, GetColorForStatus } from 'utils/index';
import { useIntl } from 'react-intl';
import NavigationLink from '../navigation-link/NavigationLink';
import SubNavigation from './SubNavigation';

const MainNavigation = ({ changesets }: Props) => {
  const intl = useIntl();

  const getLinkText = (changeset: Changeset): string => `${intl.formatMessage({ id: 'changeset.name' })} - ${changeset.changesetId.slice(0, 5)}`;
  const getLinkUrl = (changeset: Changeset): string => `/tickets/${changeset.changesetId}`;

  return (
    <div>
      {changesets
        .filter((x) => ChangesetIsActive(x.status))
        .map((changeset) => (
          <div className={`pb-1 pr-1`} key={changeset.changesetId}>
            <NavigationLink key={changeset.changesetId} linkText={getLinkText(changeset)} linkUrl={getLinkUrl(changeset)} color={GetColorForStatus(changeset.status)} isParent={true} />
            <SubNavigation changeset={changeset}></SubNavigation>
          </div>
        ))}
    </div>
  );
};

interface Props {
  changesets: Changeset[];
}

export default MainNavigation;
