import { Alert, Button, Card, Icons } from '@skf/ferris';
import { ChangesetStatus } from 'domain/index';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { AppDispatch, updateChangesetComment, updateChangesetStatus, loadChangeset, useAppSelector, deleteChangesetRef, deleteChangesetAttachement, deleteChangeset, dismissEditError } from 'store/index';
import { ChangesetHeader, Info, DeleteDialog, RejectDialog, ChangesTable, EmptyRefsTable, ChangesetNotFound, ChangesetActionButtons } from '.';
import CoverLoader from 'components/interaction/CoverLoader';
import { useIntl } from 'react-intl';

const ChangesetPage = () => {
  const { changesetId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const intl = useIntl();

  const loading = useAppSelector((state) => state.changesetData.editLoading);
  const error = useAppSelector((state) => state.changesetData.editError);
  const changeset = useAppSelector((state) => state.changesetData.currentChangeset);

  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);
  const [rejectDialogIsOpen, setRejectDialogIsOpen] = useState(false);

  const [comment, setComment] = useState(changeset?.comment ?? '');
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    if (changesetId) {
      dispatch(loadChangeset({ changesetId }));
    }
  }, [changesetId, dispatch]);

  useEffect(() => {
    if (changeset) {
      setComment(changeset.comment);
    }
  }, [changeset]);

  if (!changeset) {
    return <ChangesetNotFound />;
  }

  const removeRef = (refId: number) => {
    dispatch(deleteChangesetRef({ changesetId: changeset.changesetId, refId }));
  };

  const onConfirmDeleteClick = () => {
    setDeleteDialogIsOpen(false);
    dispatch(deleteChangeset({ changesetId: changeset.changesetId, callback: () => navigate('/') }));
  };

  const onSaveComment = () => {
    dispatch(updateChangesetComment({ changesetId: changesetId!, comment }));
    setEditMode(false);
  };

  const onRemoveUserRequestAttachment = (attachmentId: string) => {
    dispatch(deleteChangesetAttachement({ changesetId: changeset.changesetId, attachmentId }));
  };

  const handleNewItemClick = () => {
    navigate(`/add-new/${changesetId}`);
  };

  return (
    <div>
      {error && (
        <Alert
          className="mb-2"
          feCloseButton={{
            onClick: () => dispatch(dismissEditError())
          }}
          feIcon={Icons.Danger}
          feSeverity="error"
        >
          {error}
        </Alert>
      )}
      <Card feNoPadding className="pb-8 relative">
        <ChangesetHeader changeset={changeset} onDelete={() => setDeleteDialogIsOpen(true)} />
        <div>
          <Info changeset={changeset} comment={comment} editMode={editMode} onRemoveUserRequestAttachment={onRemoveUserRequestAttachment} onCommentChange={(value: string) => setComment(value)} />
          <ChangesetActionButtons
            changeset={changeset}
            editMode={editMode}
            onEditModeChange={(value: boolean) => setEditMode(value)}
            onComment={onSaveComment}
            onReject={() => setRejectDialogIsOpen(true)}
            onStatusUpdate={(status: ChangesetStatus) => dispatch(updateChangesetStatus({ changesetId: changesetId!, status }))}
          />
        </div>
        <CoverLoader loading={loading} />
      </Card>
      <div className="flex justify-end">
        <Button
          feIcon={{
            feIcon: Icons.Plus,
            position: 'left'
          }}
          className="mt-6 mb-6"
          feType="secondary"
          onClick={handleNewItemClick}
        >
          {intl.formatMessage({ id: 'button.addNew' })}
        </Button>
      </div>
      <div className="mt-5">
        <ChangesTable changesetId={changeset.changesetId} differences={changeset.differences} />
      </div>
      <div className="mt-5">
        <EmptyRefsTable changesetId={changeset.changesetId} refs={changeset.refs} onDeleteRefClick={removeRef} />
      </div>
      <DeleteDialog isOpen={deleteDialogIsOpen} onClose={() => setDeleteDialogIsOpen(false)} onConfirm={() => onConfirmDeleteClick()} />
      <RejectDialog
        isOpen={rejectDialogIsOpen}
        onClose={() => setRejectDialogIsOpen(false)}
        onConfirm={() => {
          dispatch(updateChangesetStatus({ changesetId: changesetId!, status: ChangesetStatus.REJECTED }));
          setRejectDialogIsOpen(false);
        }}
      />
    </div>
  );
};

export default ChangesetPage;
