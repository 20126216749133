import { Header, Icons } from '@skf/ferris';
import { HeaderNavProps } from '@skf/ferris/dist/esm/components/header/HeaderNav';
import { useAuth } from 'features/authentication/AuthProvider';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

const MainLayout = () => {
  const intl = useIntl();
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [navigation, setNavigation] = useState<HeaderNavProps>();
  const [search, setSearch] = useSearchParams();

  const onSignOut = useCallback(() => {
    auth.signout(() => {
      navigate('/');
    });
  }, [auth, navigate]);

  const onNavigate = useCallback(
    (target: string) => {
      navigate(target);
    },
    [navigate]
  );

  const linkIsActive = (linkUrl: string): boolean => location.pathname === linkUrl;

  useEffect(() => {
    if (auth.isAuthenticated) {
      setNavigation({
        feItems: [
          {
            children: intl.formatMessage({ id: 'button.addNew' }),
            href: '/add-new/',
            icon: Icons.Plus,
            selected: linkIsActive('/add-new/'),
            onClick: () => onNavigate('/add-new/')
          },
          {
            children: intl.formatMessage({ id: 'header.navigation.ticketList' }),
            href: '/',
            icon: Icons.Paper,
            selected: linkIsActive('/'),
            onClick: () => onNavigate('/')
          },
          {
            children: intl.formatMessage({ id: 'header.navigation.search' }),
            href: '/search',
            icon: Icons.Search,
            selected: linkIsActive('/search'),
            onClick: () => onNavigate(`/search?${search.toString()}`)
          },
          {
            children: intl.formatMessage({ id: 'header.navigation.signout' }),
            href: '#',
            icon: Icons.LogOut,
            onClick: onSignOut
          }
        ]
      });
    } else {
      setNavigation(undefined);
    }
  }, [auth.isAuthenticated, onSignOut, onNavigate, intl]);

  return (
    <div>
      <Header
        feIntro={{
          baseUrl: '/',
          name: process.env.REACT_APP_SITE_TITLE || 'OneWind Admin Tool'
        }}
        feNavigation={navigation}
      ></Header>
      <Outlet />
    </div>
  );
};

export default MainLayout;
