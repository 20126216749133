import { BaseSeverity } from '@skf/ferris/dist/esm/common/types/severity';
import { IntlShape } from 'react-intl';
import { ValueIsEmpty } from 'utils';
import { ChangesetDetails, ProductCategory, RecordCategory } from 'domain/index';
import { GetChangeOnField, GetChangesOnParts } from '../changeset-info/ChangesetInfo.utility';
import { WithMetadata } from '../../../../../domain/shared/Metadata';
import { EntityState } from '../../entity-state';

const CHANGE_PREFIX = 'This field has been changed from';

export const GetSeverity = (productId: number, formError: Map<string, string>, fieldName: string, changesetDetails?: ChangesetDetails): BaseSeverity | undefined => {
  if (formError.get(fieldName)) {
    return 'error';
  }
  if (GetChangeOnField(productId, fieldName, changesetDetails)) {
    return 'info';
  }
  return undefined;
};

export const GetSeverityForPart = (formError: Map<string, string>, fieldName: string) => {
  if (formError.get(fieldName)) {
    return 'error';
  }
  return undefined;
};

export const GetHint = (productId: number, formError: Map<string, string>, fieldName: string, changesetDetails: ChangesetDetails | undefined, intl: IntlShape): string | undefined => {
  if (formError.get(fieldName)) {
    return formError.get(fieldName);
  }
  const field = GetChangeOnField(productId, fieldName, changesetDetails);
  if (field) {
    return `${CHANGE_PREFIX} ${GetDisplayValue(field.oldValue, intl)}`;
  }
  return undefined;
};

export const GetHintForPart = (
  entityId: number,
  recordId: number | undefined,
  recordCategory: RecordCategory,
  fieldName: string,
  changesetDetails: ChangesetDetails | undefined,
  intl: IntlShape,
  formError: Map<string, string>
): string | undefined => {
  if (formError.get(fieldName)) {
    return formError.get(fieldName);
  }
  const [added, updated, removed] = GetChangesOnParts(entityId, [recordCategory], changesetDetails);
  const diff = updated.filter((x) => x.recordId === recordId && x.recordCategory === recordCategory).find((x) => x.properties.some((y) => y.name === fieldName));
  const field = diff?.properties.find((y) => y.name === fieldName);
  if (!!field) {
    return `${CHANGE_PREFIX} ${GetDisplayValue(field.oldValue, intl)}`;
  }
  return undefined;
};

export const GetHintForCombination = (id: number, formError: Map<string, string>, checkboxName: string, fieldName: string, changesetDetails: ChangesetDetails | undefined, intl: IntlShape): string | undefined => {
  if (formError.get(fieldName)) {
    return formError.get(fieldName);
  }
  if (formError.get(checkboxName)) {
    return formError.get(checkboxName);
  }
  const flag = GetChangeOnField(id, checkboxName, changesetDetails);
  const link = GetChangeOnField(id, fieldName, changesetDetails);
  if (!!flag && !!link) {
    return `${CHANGE_PREFIX} '${flag.oldValue === '0' ? 'unchecked' : 'checked'}', ${GetDisplayValue(link.oldValue, intl)}`;
  } else if (!!flag) {
    return `${CHANGE_PREFIX} '${flag.oldValue === '0' ? 'unchecked' : 'checked'}'`;
  } else if (!!link) {
    return `${CHANGE_PREFIX} ${GetDisplayValue(link.oldValue, intl)}`;
  }
  return undefined;
};

const GetDisplayValue = (oldValue: string | undefined, intl: IntlShape): string => {
  return `${ValueIsEmpty(oldValue) ? intl.formatMessage({ id: 'changeset.diff.value.empty' }) : `'${oldValue}'`}`;
};

export const ShowApplication = (productCategory: ProductCategory): boolean => [ProductCategory.GREASE, ProductCategory.LUBRICATION_SYSTEM, ProductCategory.LUBRICATOR, ProductCategory.SEAL].includes(productCategory);

export const ShowRemanufacturing = (productCategory: ProductCategory): boolean => [ProductCategory.GREASE].includes(productCategory);

export const ShowManufacturer = (productCategory: ProductCategory): boolean =>
  [ProductCategory.BEARING, ProductCategory.COUPLING, ProductCategory.GREASE, ProductCategory.HOUSING, ProductCategory.LUBRICATION_SYSTEM, ProductCategory.SEAL].includes(productCategory);

export const ShowProductType = (productCategory: ProductCategory): boolean => [ProductCategory.BEARING, ProductCategory.COUPLING].includes(productCategory);

export const UpdateListByAction = <Type extends WithMetadata>(originalList: Type[], value: Type, index: number | null, action: 'Add' | 'Edit' | 'Delete'): Type[] => {
  let updatedList = [...originalList];
  if (action === 'Edit' && index !== null) {
    updatedList[index] = value;
  }
  if (action === 'Delete' && index !== null) {
    updatedList[index].metadata = {
      state: EntityState.DELETED_UNSAVED
    };
  }
  if (action === 'Add') {
    updatedList = updatedList.concat(value);
  }
  return updatedList;
};
