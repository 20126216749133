import { IntlShape } from 'react-intl';

export const SetRequiredError = (value: string | number | undefined, name: string, formErrors: Map<string, string>, intl: IntlShape): Map<string, string> => {
  if (!value) {
    return formErrors.set(name, intl.formatMessage({ id: 'error.required' }));
  } else {
    formErrors.delete(name);
    return formErrors;
  }
};

export const SetStringMaxErrors = (value: string | undefined, name: string, max: number, formErrors: Map<string, string>, intl: IntlShape): Map<string, string> => {
  if (!!value && value.length > max) {
    return formErrors.set(name, `${intl.formatMessage({ id: 'error.maxText' })} ${max}`);
  } else {
    formErrors.delete(name);
    return formErrors;
  }
};

export const SetNumberFieldErrors = (value: number | undefined, name: string, max: number, formErrors: Map<string, string>, intl: IntlShape): Map<string, string> => {
  if (!!value && value > max) {
    return formErrors.set(name, `${intl.formatMessage({ id: 'error.maxValue' })} ${max}`);
  } else if (!!value && value < 0) {
    return formErrors.set(name, intl.formatMessage({ id: 'error.minValue' }));
  } else {
    formErrors.delete(name);
    return formErrors;
  }
};
