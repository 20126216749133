import { Facet } from './SearchResult';

export enum Order {
  ASC = 'asc',
  DESC = 'desc'
}

export interface Sorting {
  field: string;
  order: Order;
}

export interface Pagination {
  skip: number;
  size: number;
}

export const defaultPagination = {
  skip: 0,
  size: 25
};

export interface Filter {
  field: string;
}

export interface DateFilter extends Filter {
  from: string;
  to: string;
}

export interface MultiChoiceFilter extends Filter {
  values: string[];
}

export interface MultiChoiceAndFilter extends MultiChoiceFilter {}

export interface MultiChoiceOrFilter extends MultiChoiceFilter {}

export interface BoolFilter extends Filter {
  flag?: boolean;
}
export interface ValueFilter extends Filter {
  value: string;
}

export interface SearchQuery {
  q?: string;
  filters?: (DateFilter | MultiChoiceAndFilter | MultiChoiceAndFilter | MultiChoiceOrFilter | BoolFilter | ValueFilter)[];
  pagination?: Pagination;
  sort?: Sorting;
  highlight?: boolean;
  exactMatch?: boolean;
}

export interface ActiveSearchQuery extends Omit<SearchQuery, 'filters'> {
  filters: { filter: Filter; facet: Facet }[];
  isEmpty?: boolean;
}

export const filters = [
  {
    field: ''
  }
];
