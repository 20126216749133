import { Card, Spacer } from '@skf/ferris';
import { useIntl } from 'react-intl';
import { useAppSelector } from 'store';
import Bookmarks from './bookmarks/Bookmarks';

const BookmarkCard = () => {
  const intl = useIntl();
  const { bookmarks } = useAppSelector((state) => state.bookmarks);
  return (
    <Card>
      <p className="mt-2 font-bold">
        {intl.formatMessage({ id: 'ticket.navigation.bookmarks' })} ({bookmarks.length})
      </p>
      <Spacer />
      <div className="max-h-80 overflow-y-scroll">
        <Bookmarks bookmarks={bookmarks} />
      </div>
    </Card>
  );
};

export default BookmarkCard;
