import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Bearing } from 'domain/index';
import { Coupling, Grease, Housing, LubricationSystem, Lubricator, Seal, StockingPoint, ProductCategory } from 'domain/index';
import { CreateProductInChangeset, DeleteProduct, GetChangesetDiff } from 'services/api';
import { GetProduct, GetProductFromChangeset, PostChangeset, PostChangesetRef } from 'services/api';
import { getChangesetDetails } from 'features/edit/shared';
import { getChangesets, removeBookmark } from 'store';
import { MapProduct } from 'utils';
import _ from 'lodash';
import { resetStockingPointsOriginal } from '../stocking-points-slice';
import { SaveChangesService } from 'services/api/changeset.service.helper';
import { ObjectType } from 'domain/shared/ObjectId';
import { EditWarning } from 'domain/shared/Warning';

export type EditProductState = {
  product: Bearing | Coupling | Grease | Housing | LubricationSystem | Lubricator | Seal;
  productOriginal: Bearing | Coupling | Grease | Housing | LubricationSystem | Lubricator | Seal;
  editMode: boolean;
  eTag: string;
  loading: { product: boolean; delete: boolean; save: boolean; editInTicket: boolean };
  initError: string | undefined;
  error: string | undefined;
  warnings: EditWarning[];
};

const initialState: EditProductState = {
  product: { productCategory: ProductCategory.BEARING } as Bearing,
  productOriginal: { productCategory: ProductCategory.BEARING } as Bearing,
  editMode: false,
  eTag: '',
  loading: { product: false, delete: false, save: false, editInTicket: false },
  initError: undefined,
  error: undefined,
  warnings: []
};

export const getProduct = createAsyncThunk('product/getProduct', async (options: { productId: number; changesetId: string | undefined }) => {
  if (!!options.changesetId) {
    const response = await GetProductFromChangeset(options.changesetId, options.productId);
    return { data: MapProduct(response.data), etag: response.headers.etag };
  }
  const response = await GetProduct(options.productId);
  return { data: MapProduct(response.data), etag: '' };
});

export const deleteProduct = createAsyncThunk('product/deleteProduct', async (options: { productId: number; changesetId: string | undefined; eTag: string }, thunkAPI) => {
  thunkAPI.dispatch(setLoading({ delete: true }));
  try {
    await DeleteProduct(options.changesetId ?? '', options.productId, options.eTag);
    if (!!options.changesetId) {
      thunkAPI.dispatch(getChangesetDetails({ changesetId: options.changesetId }));
    }
    thunkAPI.dispatch(getChangesets(false));
  } catch (error: any) {
    thunkAPI.dispatch(setError({ error: error?.message ?? 'Failed', loading: { delete: false } }));
  }
  thunkAPI.dispatch(setLoading({ delete: false }));
});

export const editInTicket = createAsyncThunk('product/editInTicket', async (options: { entityId: number; changesetId: string; productName: string; productCategory: string }, thunkAPI) => {
  thunkAPI.dispatch(setLoading({ editInTicket: true }));
  try {
    if (_.isEmpty(options.changesetId)) {
      const responsePostChaneset = await PostChangeset();
      thunkAPI.dispatch(setETag(responsePostChaneset.headers.etag));
      const responsePostRef = await PostChangesetRef(responsePostChaneset.data.changesetId, options.entityId, options.productName ?? '', options.productCategory, responsePostChaneset.headers.etag);
      thunkAPI.dispatch(getChangesets(false));
      thunkAPI.dispatch(removeBookmark({ id: options.entityId }));
      thunkAPI.dispatch(setLoading({ editInTicket: false }));
      return responsePostRef.data.changesetId;
    } else {
      const responseGetChangeset = await GetChangesetDiff(options.changesetId);
      thunkAPI.dispatch(setETag(responseGetChangeset.headers.etag));
      const responsePostRef = await PostChangesetRef(options.changesetId, options.entityId, options.productName ?? '', options.productCategory, responseGetChangeset.headers.etag);
      thunkAPI.dispatch(getChangesets(false));
      thunkAPI.dispatch(removeBookmark({ id: options.entityId }));
      thunkAPI.dispatch(setLoading({ editInTicket: false }));
      return responsePostRef.data.changesetId;
    }
  } catch (error: any) {
    thunkAPI.dispatch(setError({ error: error?.message ?? 'Failed', loading: { editInTicket: false } }));
  }
  thunkAPI.dispatch(setLoading({ editInTicket: false }));
});

export const saveChanges = createAsyncThunk(
  'product/saveChanges',
  async (
    options: {
      entityId: number;
      changesetId: string | undefined;
      productDiff: any;
      addStockingPoints: StockingPoint[];
      removeStockingPoints: StockingPoint[];
      isAutoCommit: boolean;
      etag: string;
      newMode: boolean | undefined;
    },
    thunkAPI
  ) => {
    const saveService = new SaveChangesService({
      ...options,
      type: ObjectType.PRODUCT,
      createItemInChangeset: async (changesetId: string, item: Bearing | Coupling | Grease | Housing | LubricationSystem | Lubricator | Seal, etag: string) => {
        const responseCreateGearbox = await CreateProductInChangeset(changesetId, item, etag);
        return {
          entityId: responseCreateGearbox.data.productId,
          etag: responseCreateGearbox.headers.etag
        };
      },
      actions: {
        preSave: () => {
          thunkAPI.dispatch(setLoading({ save: true }));
          thunkAPI.dispatch(resetWarnings());
        },
        setETag: (etag: string) => thunkAPI.dispatch(setETag(etag)),
        loadChangesetData: (changesetId: string) => {
          thunkAPI.dispatch(getChangesetDetails({ changesetId }));
          thunkAPI.dispatch(getChangesets(false));
        },
        postSave: () => {
          thunkAPI.dispatch(setEditMode({ editMode: false }));
          thunkAPI.dispatch(resetProductOriginal());
          thunkAPI.dispatch(resetStockingPointsOriginal());
          thunkAPI.dispatch(setLoading({ save: false }));
        },
        onError: (error) => thunkAPI.dispatch(setError({ error, loading: { save: false } })),
        onChangesetUpdateWarning: (warning: EditWarning) => thunkAPI.dispatch(setWarning(warning))
      }
    });

    const commandBuilder = saveService.getCommandBuilder().addStockingPoints(options.addStockingPoints).removeStockinpoints(options.removeStockingPoints);

    try {
      const result = await saveService.save(options.productDiff as Bearing | Coupling | Grease | Housing | LubricationSystem | Lubricator | Seal, commandBuilder);
      return { status: 'ok', changesetId: result.changesetId, entityId: result.entityId };
    } catch (error: any) {
      thunkAPI.dispatch(setError({ error: error?.message ?? 'Failed', loading: { save: false } }));
    }
  }
);

export const productSlice = createSlice({
  name: 'product',
  initialState: initialState,
  reducers: {
    resetProductState: (state: EditProductState) => {
      state.product = {} as Bearing;
      state.productOriginal = {} as Bearing;
      state.editMode = false;
      state.eTag = '';
      state.loading = { product: false, delete: false, save: false, editInTicket: false };
      state.initError = undefined;
      state.error = undefined;
    },
    setEditMode: (state: EditProductState, action: PayloadAction<{ editMode: boolean }>) => {
      state.editMode = action.payload.editMode;
    },
    setProduct: (state: EditProductState, action: PayloadAction<Partial<{ product: Bearing | Coupling | Grease | Housing | LubricationSystem | Lubricator | Seal }>>) => {
      state.product = { ...state.product, ...action.payload.product };
    },
    setProductOriginal: (state: EditProductState, action: PayloadAction<{ product: Bearing | Coupling | Grease | Housing | LubricationSystem | Lubricator | Seal }>) => {
      state.productOriginal = action.payload.product;
    },
    resetProductOriginal: (state: EditProductState) => {
      state.productOriginal = state.product;
    },
    setLoading: (state: EditProductState, action: PayloadAction<Partial<{ product: boolean; delete: boolean; save: boolean; editInTicket: boolean }>>) => {
      state.loading = { ...state.loading, ...action.payload };
    },
    setETag: (state: EditProductState, action: PayloadAction<string>) => {
      state.eTag = action.payload;
    },
    setError: (state: EditProductState, action: PayloadAction<{ error: string; loading: Partial<{ product: boolean; delete: boolean; save: boolean; editInTicket: boolean }> }>) => {
      state.loading = { ...state.loading, ...action.payload.loading };
      state.error = action.payload.error;
    },
    setWarning: (state: EditProductState, action: PayloadAction<EditWarning>) => {
      state.warnings = [...state.warnings, action.payload];
    },
    resetWarnings: (state: EditProductState, action: PayloadAction) => {
      state.warnings = [];
    },
    resetWarning: (state: EditProductState, action: PayloadAction<EditWarning>) => {
      state.warnings = state.warnings.filter((w) => w.type !== action.payload.type);
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getProduct.pending, (state, action) => {
        state.loading = { ...state.loading, product: true };
      })
      .addCase(getProduct.fulfilled, (state, action) => {
        state.product = action.payload.data;
        state.productOriginal = action.payload.data;
        state.eTag = action.payload.etag;
        state.loading = { ...state.loading, product: false };
      })
      .addCase(getProduct.rejected, (state, action) => {
        state.loading = { ...state.loading, product: false };
        state.error = action.error.message;
        state.initError = action.error.message;
      });
  }
});

export const { resetProductState, setLoading, setError, setETag, setEditMode, setProduct, resetProductOriginal, setWarning, resetWarnings, resetWarning } = productSlice.actions;
export default productSlice.reducer;
