import { ChangesetStatus } from 'domain/changeset/ChangesetStatus';

export enum Order {
  ASC = 'asc',
  DESC = 'desc'
}

export interface Sorting {
  field: string;
  order: Order;
}

export interface Pagination {
  skip: number;
  size: number;
}

export interface ChangesetSearchQuery {
  q?: string;
  pagination?: Pagination;
  sort?: Sorting;
  status?: ChangesetStatus;
}
