import { useIntl } from 'react-intl';
import { Button, Dialog, Flexbox, Spacer, Spacings } from '@skf/ferris';
import { ChangesetDetails, PositionsCategory, RecordCategory, RelationshipProduct } from 'domain/index';
import PartForm from '../form/PartForm';

const ListOfPartsDialog = ({ entityId, recordCategory, positionCategory, partToEdit, indexToEdit, changesetDetails, isOpen, onFormChange, onSave, onCancel, formError }: Props) => {
  const intl = useIntl();
  const saveIsDisabled = (): boolean => formError.size > 0;

  return (
    <Dialog cssWidth={'40rem'} cssHeight={'40.5rem'} feInterruptive feTitle={!!partToEdit.recordId ? intl.formatMessage({ id: 'part.form.edit' }) : intl.formatMessage({ id: 'part.form.add' })} open={isOpen}>
      <PartForm entityId={entityId} recordCategory={recordCategory} changesetDetails={changesetDetails} positionCategory={positionCategory} part={partToEdit} onFormChange={onFormChange} formError={formError}></PartForm>
      <Spacer />
      <Flexbox feGap={Spacings.Md} feJustifyContent="flex-end">
        <Button
          feType="secondary"
          onClick={() => {
            onCancel();
          }}
        >
          {intl.formatMessage({ id: 'part.form.cancel' })}
        </Button>
        <Button
          onClick={() => {
            onSave(partToEdit, indexToEdit);
          }}
          disabled={saveIsDisabled()}
        >
          {intl.formatMessage({ id: 'part.form.save' })}
        </Button>
      </Flexbox>
    </Dialog>
  );
};

interface Props {
  entityId: number;
  recordCategory: RecordCategory;
  positionCategory: PositionsCategory;
  partToEdit: RelationshipProduct;
  indexToEdit: number | null;
  changesetDetails?: ChangesetDetails;
  isOpen: boolean;
  onFormChange: any;
  onSave: any;
  onCancel: any;
  formError?: any;
}

export default ListOfPartsDialog;
