import { ComboBoxItem } from './ComboBoxItem';
import { useCombobox, UseComboboxStateChange } from 'downshift';
const useComboBox = <TItem>(items: ComboBoxItem<TItem>[], onItemSelected: (item: ComboBoxItem<TItem> | null | undefined) => void, onSearch: (value?: string) => void) => {
  const comboBoxProps = useCombobox<ComboBoxItem<TItem> | null>({
    onInputValueChange(state: UseComboboxStateChange<ComboBoxItem<TItem> | null>) {
      if (comboBoxProps.selectedItem === state.selectedItem) {
        onSearch(state.inputValue);
      }
    },
    items: items,
    itemToString(item) {
      if (item) {
        return item.label;
      } else {
        return '';
      }
    },
    onSelectedItemChange: ({ selectedItem }) => {
      onItemSelected(selectedItem);
    },

    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.FunctionReset:
          return {
            ...changes,
            inputValue: ''
          };
        case useCombobox.stateChangeTypes.InputKeyDownArrowDown:
          return {
            ...changes,
            highlightedIndex: state.highlightedIndex + 1 === items.length ? 0 : state.highlightedIndex + 1
          };
        case useCombobox.stateChangeTypes.InputKeyDownArrowUp:
          return {
            ...changes,
            highlightedIndex: state.highlightedIndex - 1 === -1 ? items.length - 1 : state.highlightedIndex - 1
          };
      }
      return changes;
    }
  });

  return comboBoxProps;
};

export default useComboBox;
