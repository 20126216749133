import { User } from 'domain/index';

export const GetNameFromEmail = (user: User | undefined): string => {
  if (!user || !user.email || !user.email.includes('@')) {
    return '';
  }
  const name = user.email.split('@')[0];
  return name
    .split('.')
    .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
    .join(' ');
};
