import { BaseProduct, Bearing, Coupling, Grease, Housing, LubricationSystem, Lubricator, Product, ProductCategory, Seal } from 'domain/index';

export const MapProduct = (product: Product): BaseProduct => {
  const p = mapToProductType(product);
  p.metadata = product.metadata;
  return p;
};

const mapToProductType = (product: Product): BaseProduct => {
  switch (product.productCategory) {
    case 'Bearing':
      return MapBearingFromProduct(product);
    case 'Coupling':
      return MapCouplingFromProduct(product);
    case 'Grease':
      return MapGreaseFromProduct(product);
    case 'Housing':
      return MapHousingFromProduct(product);
    case 'Lubrication System':
    case 'LubricationSystem':
    case 'Lubricationsystem':
      return MapLubricationSystemFromProduct(product);
    case 'Lubricator':
      return MapLubricatorFromProduct(product);
    case 'Seal':
      return MapSealFromProduct(product);
    default:
      throw new Error('Cannot map product category');
  }
};

const MapBearingFromProduct = (product: Product): Bearing => ({
  productId: product.productId,
  designation: product.designation ?? '',
  productType: product.productType ?? '',
  design: product.design ?? '',
  manufacturer: product.manufacturer ?? '',
  application: product.application ?? '',
  productInformation: product.productInformation ?? '',
  otherFeatures: product.otherFeatures ?? '',
  properties: product.properties ?? '',
  productCategory: ProductCategory.BEARING,
  remark: product.remark ?? '',
  innerDiameter: product.innerDiameter ?? undefined,
  outerDiameter: product.outerDiameter ?? undefined,
  width: product.width ?? undefined,
  weight: product.weight ?? undefined,
  dynamicCapacity: product.dynamicCapacity ?? undefined,
  staticCapacity: product.staticCapacity ?? undefined,
  numberOfRe: product.numberOfRe ?? undefined,
  material: product.material ?? '',
  createdBy: product.createdBy ?? '',
  creationDate: product.creationDate ?? '',
  modifiedBy: product.modifiedBy ?? '',
  modificationDate: product.modificationDate ?? ''
});

const MapCouplingFromProduct = (product: Product): Coupling => ({
  productId: product.productId,
  designation: product.designation ?? '',
  productType: product.productType ?? '',
  design: product.design ?? '',
  manufacturer: product.manufacturer ?? '',
  application: product.application ?? '',
  productInformation: product.productInformation ?? '',
  otherFeatures: product.otherFeatures ?? '',
  properties: product.properties ?? '',
  productCategory: ProductCategory.COUPLING,
  remark: product.remark ?? '',
  createdBy: product.createdBy ?? '',
  creationDate: product.creationDate ?? '',
  modifiedBy: product.modifiedBy ?? '',
  modificationDate: product.modificationDate ?? ''
});

const MapGreaseFromProduct = (product: Product): Grease => ({
  productId: product.productId,
  designation: product.designation ?? '',
  productType: product.productType ?? '',
  design: product.design ?? '',
  manufacturer: product.manufacturer ?? '',
  application: product.application ?? '',
  productInformation: product.productInformation ?? '',
  otherFeatures: product.otherFeatures ?? '',
  properties: product.properties ?? '',
  productCategory: ProductCategory.GREASE,
  remark: product.remark ?? '',
  greaseNlgiClass: product.greaseNlgiClass ?? '',
  greaseTypeVisc: product.greaseTypeVisc ?? '',
  greaseSoap: product.greaseSoap ?? '',
  greaseSafetyData: product.greaseSafetyData ?? '',
  createdBy: product.createdBy ?? '',
  creationDate: product.creationDate ?? '',
  modifiedBy: product.modifiedBy ?? '',
  modificationDate: product.modificationDate ?? ''
});

const MapHousingFromProduct = (product: Product): Housing => ({
  productId: product.productId,
  designation: product.designation ?? '',
  productType: product.productType ?? '',
  design: product.design ?? '',
  manufacturer: product.manufacturer ?? '',
  application: product.application ?? '',
  productInformation: product.productInformation ?? '',
  otherFeatures: product.otherFeatures ?? '',
  properties: product.properties ?? '',
  productCategory: ProductCategory.HOUSING,
  remark: product.remark ?? '',
  createdBy: product.createdBy ?? '',
  creationDate: product.creationDate ?? '',
  modifiedBy: product.modifiedBy ?? '',
  modificationDate: product.modificationDate ?? ''
});

const MapLubricationSystemFromProduct = (product: Product): LubricationSystem => ({
  productId: product.productId,
  designation: product.designation ?? '',
  productType: product.productType ?? '',
  design: product.design ?? '',
  manufacturer: product.manufacturer ?? '',
  application: product.application ?? '',
  productInformation: product.productInformation ?? '',
  otherFeatures: product.otherFeatures ?? '',
  properties: product.properties ?? '',
  productCategory: ProductCategory.LUBRICATION_SYSTEM,
  remark: product.remark ?? '',
  weight: product.weight ?? 0,
  lubSSeriesRetro: product.lubSSeriesRetro ?? '',
  lubSNoOfLubePoints: product.lubSNoOfLubePoints ?? 0,
  lubSPump: product.lubSPump ?? '',
  lubSSystemType: product.lubSSystemType ?? '',
  lubSPrimaryFeeder: product.lubSPrimaryFeeder ?? '',
  lubSSecondaryFeeder: product.lubSSecondaryFeeder ?? '',
  lubSGrease: product.lubSGrease ?? '',
  lubSMeteringDevice: product.lubSMeteringDevice ?? '',
  lubSPinion: product.lubSPinion ?? '',
  lubSDocs: product.lubSDocs ?? '',
  createdBy: product.createdBy ?? '',
  creationDate: product.creationDate ?? '',
  modifiedBy: product.modifiedBy ?? '',
  modificationDate: product.modificationDate ?? ''
});

const MapLubricatorFromProduct = (product: Product): Lubricator => ({
  productId: product.productId,
  designation: product.designation ?? '',
  productType: product.productType ?? '',
  design: product.design ?? '',
  manufacturer: product.manufacturer ?? '',
  application: product.application ?? '',
  productInformation: product.productInformation ?? '',
  otherFeatures: product.otherFeatures ?? '',
  properties: product.properties ?? '',
  productCategory: ProductCategory.LUBRICATOR,
  remark: product.remark ?? '',
  lubVolumeCapacity: product.lubVolumeCapacity ?? 0,
  lubMaxOperPressure: product.lubMaxOperPressure ?? 0,
  lubMaxFeedLineLen: product.lubMaxFeedLineLen ?? 0,
  lubDriveMechanism: product.lubDriveMechanism ?? '',
  lubTempRange: product.lubTempRange ?? '',
  lubPumpableGreases: product.lubPumpableGreases ?? '',
  createdBy: product.createdBy ?? '',
  creationDate: product.creationDate ?? '',
  modifiedBy: product.modifiedBy ?? '',
  modificationDate: product.modificationDate ?? ''
});

const MapSealFromProduct = (product: Product): Seal => ({
  productId: product.productId,
  designation: product.designation ?? '',
  productType: product.productType ?? '',
  design: product.design ?? '',
  manufacturer: product.manufacturer ?? '',
  application: product.application ?? '',
  productInformation: product.productInformation ?? '',
  otherFeatures: product.otherFeatures ?? '',
  properties: product.properties ?? '',
  productCategory: ProductCategory.SEAL,
  remark: product.remark ?? '',
  innerDiameter: product.innerDiameter ?? 0,
  outerDiameter: product.outerDiameter ?? 0,
  width: product.width ?? 0,
  weight: product.weight ?? 0,
  material: product.material ?? '',
  createdBy: product.createdBy ?? '',
  creationDate: product.creationDate ?? '',
  modifiedBy: product.modifiedBy ?? '',
  modificationDate: product.modificationDate ?? ''
});
