import { Alert, Icons } from '@skf/ferris';
import { useIntl } from 'react-intl';
import { EntityExistsInAChangeset } from 'utils/index';
import { Changeset } from 'domain/index';

const ExistsInChangesetInfo = ({ isEditingInTicket, changesets, entityId }: Props) => {
  const intl = useIntl();

  return (
    <div>
      {!isEditingInTicket && EntityExistsInAChangeset(changesets, entityId) && (
        <div className="flex justify-end">
          <Alert feSeverity="info" feIcon={Icons.InfoCircleFilled}>
            {intl.formatMessage({ id: 'existsInChangesetInfo' })}
          </Alert>
        </div>
      )}
    </div>
  );
};

interface Props {
  isEditingInTicket: boolean;
  changesets: Changeset[];
  entityId: number;
}

export default ExistsInChangesetInfo;
