import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RelationGearbox } from 'domain/relationships/ObjectTurbineShareDetails';
import { GetRelatedGearboxesForTurbine } from 'services/api';
import { UpdateListByAction } from 'features/edit/shared';

export type RelationGearboxesState = {
  gearboxes: RelationGearbox[];
  gearboxesOriginal: RelationGearbox[];
  gearboxesAreInitialized: boolean;
  loading: boolean;
  error: string | undefined;
};

const initialState: RelationGearboxesState = {
  gearboxes: [],
  gearboxesOriginal: [],
  gearboxesAreInitialized: false,
  loading: false,
  error: undefined
};

export const getRelatedGearboxesForTurbine = createAsyncThunk('turbine/getRelatedGearboxesForTurbine', async (options: { turbineId: number; changesetId?: string }) => {
  return (await GetRelatedGearboxesForTurbine(options.turbineId, options.changesetId)).data;
});

export const relationGearboxesSlice = createSlice({
  name: 'relationGearboxesState',
  initialState: initialState,
  reducers: {
    resetRelationGearboxesState: (state: RelationGearboxesState) => {
      state.gearboxes = [];
      //   state.gearboxesOriginal = {} as RelationGearbox[];
      state.gearboxesAreInitialized = false;
      state.loading = false;
      state.error = undefined;
    },
    resetGearboxes: (state: RelationGearboxesState) => {
      state.gearboxes = state.gearboxesOriginal;
    },
    resetGearboxesOriginal: (state: RelationGearboxesState) => {
      state.gearboxesOriginal = state.gearboxes;
    },
    setGearboxes: (state: RelationGearboxesState, action: PayloadAction<{ value: RelationGearbox; index: number; action: 'Add' | 'Edit' | 'Delete' }>) => {
      state.gearboxes = UpdateListByAction([...state.gearboxes], action.payload.value, action.payload.index, action.payload.action);
    },
    setGearboxesOriginal: (state: RelationGearboxesState, action: PayloadAction<{ gearboxes: RelationGearbox[] }>) => {
      state.gearboxesOriginal = action.payload.gearboxes;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getRelatedGearboxesForTurbine.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getRelatedGearboxesForTurbine.fulfilled, (state, action) => {
        state.loading = false;
        state.gearboxes = action.payload;
        state.gearboxesAreInitialized = true;
        state.error = undefined;
      })
      .addCase(getRelatedGearboxesForTurbine.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

export const { resetRelationGearboxesState, resetGearboxes, resetGearboxesOriginal, setGearboxes, setGearboxesOriginal } = relationGearboxesSlice.actions;
export default relationGearboxesSlice.reducer;
