import { Radio, Button, Card, Heading } from '@skf/ferris';
import { AppDispatch, useAppSelector } from 'store/index';
import { useDispatch } from 'react-redux';
import { resetProductState, setProduct } from 'features/edit/edit-product/store/product-slice';
import { Bearing, Coupling, Grease, Housing, LubricationSystem, Lubricator, ProductCategory, Seal } from 'domain/index';
import { ObjectType } from 'domain/shared/ObjectId';
import { useIntl } from 'react-intl';

const SelectNewItem = ({ selectedItem, setSelectedItem, handleOnClick }: Props) => {
  const { product } = useAppSelector((state) => state.editProductData);
  const dispatch = useDispatch<AppDispatch>();
  const intl = useIntl();
  let selectedProduct = {} as Bearing;
  const onHandleChange = (value: any) => {
    setSelectedItem(value);
    if (value !== 'Turbine' || value !== 'Gearbox' || value !== 'Generator') {
      dispatch(resetProductState());
      dispatch(setProduct({ product: getSelectedProduct(value) }));
    }
  };

  const getSelectedProduct = (value: any) => {
    switch (value) {
      case ProductCategory.BEARING:
        return (selectedProduct = { productCategory: ProductCategory.BEARING } as Bearing);
      case ProductCategory.GREASE:
        return (selectedProduct = { productCategory: ProductCategory.GREASE } as Grease);
      case ProductCategory.LUBRICATION_SYSTEM:
        return (selectedProduct = { productCategory: ProductCategory.LUBRICATION_SYSTEM } as LubricationSystem);
      case ProductCategory.LUBRICATOR:
        return (selectedProduct = { productCategory: ProductCategory.LUBRICATOR } as Lubricator);
      case ProductCategory.SEAL:
        return (selectedProduct = { productCategory: ProductCategory.SEAL } as Seal);
      case ProductCategory.HOUSING:
        return (selectedProduct = { productCategory: ProductCategory.HOUSING } as Housing);
      case ProductCategory.COUPLING:
        return (selectedProduct = { productCategory: ProductCategory.COUPLING } as Coupling);
      default:
        return (selectedProduct = { productCategory: ProductCategory.BEARING } as Bearing);
    }
  };

  return (
    <>
      <Card className="mb-4">
        <div className="p-4 pl-20 pr-20 text-center">
          <Heading as="h3" className="font-bold">
            {intl.formatMessage({ id: 'add.new.page.heading' })}
          </Heading>

          <div className="flex flex-wrap pl-10 pr-10">
            <div className="w-1/4 mt-8">
              <Radio name="machineElement" feLabel={intl.formatMessage({ id: 'entity.category.turbine' })} value={ObjectType.TURBINE} onChange={(event) => onHandleChange(event.target.value)} />
            </div>
            <div className="w-1/4 mt-8">
              <Radio name="machineElement" feLabel={intl.formatMessage({ id: 'entity.category.bearing' })} value={ProductCategory.BEARING} onChange={(event) => onHandleChange(event.target.value)} />
            </div>
            <div className="w-1/4 mt-8">
              <Radio name="machineElement" feLabel={intl.formatMessage({ id: 'entity.category.coupling' })} value={ProductCategory.COUPLING} onChange={(event) => onHandleChange(event.target.value)} />
            </div>
            <div className="w-1/4 mt-8">
              <Radio name="machineElement" feLabel={intl.formatMessage({ id: 'entity.category.grease' })} value={ProductCategory.GREASE} onChange={(event) => onHandleChange(event.target.value)} />
            </div>
            <div className="w-1/4 mt-8">
              <Radio name="machineElement" feLabel={intl.formatMessage({ id: 'entity.category.gearbox' })} value={ObjectType.GEARBOX} onChange={(event) => onHandleChange(event.target.value)} />
            </div>
            <div className="w-1/4 mt-8">
              <Radio name="machineElement" feLabel={intl.formatMessage({ id: 'entity.category.housing' })} value={ProductCategory.HOUSING} onChange={(event) => onHandleChange(event.target.value)} />
            </div>
            <div className="w-1/4 mt-8">
              <Radio name="machineElement" feLabel={intl.formatMessage({ id: 'entity.category.lubricationsystem' })} value={ProductCategory.LUBRICATION_SYSTEM} onChange={(event) => onHandleChange(event.target.value)} />
            </div>
            <div className="w-1/4 mt-8">
              <Radio name="machineElement" feLabel={intl.formatMessage({ id: 'entity.category.lubricator' })} value={ProductCategory.LUBRICATOR} onChange={(event) => onHandleChange(event.target.value)} />
            </div>
            <div className="w-1/4 mt-8">
              <Radio name="machineElement" feLabel={intl.formatMessage({ id: 'entity.category.generator' })} value={ObjectType.GENERATOR} onChange={(event) => onHandleChange(event.target.value)} />
            </div>
            <div className="w-1/4 mt-8">
              <Radio name="machineElement" feLabel={intl.formatMessage({ id: 'entity.category.seal' })} value={ProductCategory.SEAL} onChange={(event) => onHandleChange(event.target.value)} />
            </div>
          </div>
        </div>
      </Card>
      <div className="flex justify-end">
        <Button feType="primary" onClick={handleOnClick} disabled={!selectedItem}>
          {intl.formatMessage({ id: 'button.select' })}
        </Button>
      </div>
    </>
  );
};

export default SelectNewItem;

interface Props {
  selectedItem: any;
  setSelectedItem: any;
  handleOnClick: any;
}
