import { useIntl } from 'react-intl';
import { getChangesets, useAppDispatch, useAppSelector } from 'store';
import { ActiveChangesetStatuses, ChangesetIsActive, GetNameFromEmail, GetTranslationIdForEntityCategory, GetTraslationIdForChangesetState, GetTraslationIdForChangesetStatus } from 'utils';
import ChangesetListBox from './box/ChangesetListBox';
import { useEffect, useState } from 'react';
import { Changeset } from 'domain/changeset/Changeset';

const ActiveChangesets = () => {
  const intl = useIntl();
  const { changesets, loading, error } = useAppSelector((state) => state.changesetData);
  const dispatch = useAppDispatch();

  const ALL_STATUSES = 'ALL_STATUSES';
  const [statusFilter, setStatusFilter] = useState(ALL_STATUSES);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredChangesets, setFilteredChangesets] = useState<Changeset[]>(changesets);

  const statusFilterOptions = ActiveChangesetStatuses().map((x) => ({ label: `${intl.formatMessage({ id: GetTraslationIdForChangesetStatus(x) })}`, value: x.toString() }));
  statusFilterOptions.unshift({ label: `${intl.formatMessage({ id: GetTraslationIdForChangesetStatus(ALL_STATUSES) })}`, value: ALL_STATUSES });

  useEffect(() => {
    const statusFiltered = statusFilter === ALL_STATUSES ? changesets : changesets.filter((x) => x.status === statusFilter);
    const searchFiltered =
      searchQuery === ''
        ? statusFiltered
        : statusFiltered.filter(
            (x) =>
              includesSearchTerm(x.changesetId, searchQuery) ||
              includesSearchTerm(x.createdBy?.username, searchQuery) ||
              includesSearchTerm(x.createdBy?.email, searchQuery) ||
              includesSearchTerm(GetNameFromEmail(x.createdBy), searchQuery) ||
              includesSearchTerm(x.createdAt, searchQuery) ||
              includesSearchTerm(x.comment, searchQuery) ||
              includesSearchTerm(intl.formatMessage({ id: GetTraslationIdForChangesetStatus(x.status) }), searchQuery) ||
              x.refs.some((y) => includesSearchTerm(intl.formatMessage({ id: GetTranslationIdForEntityCategory(y.type) }), searchQuery.toLowerCase())) ||
              x.refs.some((y) => includesSearchTerm(intl.formatMessage({ id: GetTraslationIdForChangesetState(y.state) }), searchQuery.toLowerCase())) ||
              x.refs.some((y) => includesSearchTerm(y.name, searchQuery.toLowerCase())) ||
              (x.userRequest && includesSearchTerm('user request', searchQuery))
          );
    setFilteredChangesets([...searchFiltered]);
  }, [changesets, statusFilter, searchQuery, intl]);

  useEffect(() => {
    dispatch(getChangesets(true));
  }, []);

  const includesSearchTerm = (value: string, searchTerm: string): boolean => {
    return value?.toLowerCase().includes(searchTerm.toLowerCase());
  };

  if (error) {
    return <p>Failed to load changesets, please try again</p>;
  }

  return (
    <ChangesetListBox
      loading={loading}
      changesets={filteredChangesets.filter((x) => ChangesetIsActive(x.status))}
      heading={intl.formatMessage({ id: 'changeset.list.header.active' })}
      statuses={statusFilterOptions}
      status={statusFilter}
      numberOfChangesets={changesets.length}
      query={searchQuery}
      onChangeStatus={(status) => setStatusFilter(status)}
      onSearch={(query) => setSearchQuery(query)}
    />
  );
};

export default ActiveChangesets;
