import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RelationTurbine } from 'domain/relationships/ObjectTurbineShareDetails';
import { UpdateListByAction } from 'features/edit/shared';
import { GetRelatedTurbinesForGearbox, GetRelatedTurbinesForGenerator } from 'services/api';
import { EntityState } from '../../entity-state';

export type RelationTurbinesState = {
  turbines: RelationTurbine[];
  turbinesOriginal: RelationTurbine[];
  turbinesAreInitialized: boolean;
  loading: boolean;
  error: string | undefined;
};

const initialState: RelationTurbinesState = {
  turbines: [],
  turbinesOriginal: [],
  turbinesAreInitialized: false,
  loading: false,
  error: undefined
};

export const getRelatedTurbines = createAsyncThunk('relationTurbines/getRelatedTurbines', async (options: { type: 'Generator' | 'Gearbox'; id: number; changesetId?: string }) => {
  if (options.type === 'Generator') {
    return (await GetRelatedTurbinesForGenerator(options.id, options.changesetId)).data;
  }
  if (options.type === 'Gearbox') {
    return (await GetRelatedTurbinesForGearbox(options.id, options.changesetId)).data;
  }
  return [];
});

export const relationTurbinesSlice = createSlice({
  name: 'relationTurbines',
  initialState: initialState,
  reducers: {
    resetRelationTurbinesState: (state: RelationTurbinesState) => {
      state.turbines = [];
      state.turbinesOriginal = [];
      state.turbinesAreInitialized = false;
      state.loading = false;
      state.error = undefined;
    },
    resetTurbines: (state: RelationTurbinesState) => {
      state.turbines = state.turbinesOriginal;
    },
    resetTurbinesOriginal: (state: RelationTurbinesState) => {
      state.turbinesOriginal = state.turbines;
    },
    setTurbines: (state: RelationTurbinesState, action: PayloadAction<{ value: RelationTurbine; index: number; action: 'Add' | 'Edit' | 'Delete' }>) => {
      state.turbines = UpdateListByAction([...state.turbines], action.payload.value, action.payload.index, action.payload.action);
    },
    setTurbinesOriginal: (state: RelationTurbinesState, action: PayloadAction<{ turbines: RelationTurbine[] }>) => {
      state.turbinesOriginal = action.payload.turbines;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getRelatedTurbines.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getRelatedTurbines.fulfilled, (state, action) => {
        state.loading = false;
        state.turbines = action.payload;
        state.turbinesOriginal = action.payload;
        state.turbinesAreInitialized = true;
        state.error = undefined;
      })
      .addCase(getRelatedTurbines.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

export const { resetRelationTurbinesState, resetTurbines, resetTurbinesOriginal, setTurbines, setTurbinesOriginal } = relationTurbinesSlice.actions;
export default relationTurbinesSlice.reducer;
