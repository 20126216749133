import { EntityState, stateToColor } from './index';

const EntityStateIndicator = ({ state }: Props) => {
  if (!state) {
    return null;
  }
  return <div className={`w-3 h-10 mr-4 ${stateToColor(state, 'bg-gray-100')}`} />;
};

interface Props {
  state?: EntityState;
}

export default EntityStateIndicator;
