import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Position } from 'domain/index';
import { UpdateListByAction } from 'features/edit/shared';

export type PositionsState = {
  positions: Position[];
};

const initialState: PositionsState = {
  positions: []
};

export const positionsSlice = createSlice({
  name: 'positions',
  initialState: initialState,
  reducers: {
    resetPositionsState: (state: PositionsState) => {
      state.positions = [];
    },
    setPositions: (state: PositionsState, action: PayloadAction<{ value: Position; index: number; action: 'Add' | 'Edit' | 'Delete' }>) => {
      state.positions = UpdateListByAction([...state.positions], action.payload.value, action.payload.index, action.payload.action);
    }
  }
});

export const { setPositions, resetPositionsState } = positionsSlice.actions;
export default positionsSlice.reducer;
