import { Card, Heading, Loader, Spacer, Tabs, useToast } from '@skf/ferris';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { AppDispatch, useAppSelector } from 'store/index';
import { GetEntityUrl, GetTranslationIdForEntityCategory, ObjectComparison, ProductPartsComparison, GearboxShareDetailsComparison, TurbineShareDetailsComparison, GeneratorShareDetailsComparison } from 'utils/index';
import { PositionsCategory, ProductCategory, RecordCategory, RelationGearbox, RelationGenerator, RelationshipProduct, State, Turbine } from 'domain/index';
import {
  ExistsInChangesetInfo,
  FormActions,
  GearboxParts,
  GeneratorParts,
  getChangesetDetails,
  GetHasChangesOnParts,
  getRelatedComponents,
  Header,
  ListOfParts,
  LogPosts,
  RemovedOverlay,
  resetChangesetDetailsState,
  resetPositionsState,
  SetNumberFieldErrors,
  setProductPartsMetadataStateAsSaved,
  SetRequiredError
} from '../shared/index';
import { getLog, resetLogState, resetProductParts, resetProductPartsState, setProductParts } from '../shared';
import { DescriptionForm, GetHasChangesInDetails, getRelatedGearboxesForTurbine, resetRelationGearboxesState, setGearboxes, resetGearboxes, setGenerators, resetGenerators, resetRelationGeneratorsStateState, TurbineForm } from './index';
import { deleteTurbine, editInTicket, getTurbine, resetTurbineState, resetWarning, saveChanges, setEditMode, setTurbine } from './store/turbine-slice';
import { resetGearboxesState, resetGeneratorsState } from '../edit-product';
// import { resetRelationGearboxesState, resetGearboxes, resetGearboxesOriginal, setGearboxes, setGearboxesOriginal } from '../shared/store/relation-gearboxes';
// import { resetRelationGeneratorsState, resetGenerators, resetGeneratorsOriginal, setGenerators, setGeneratorsOriginal } from '../shared/store/relation-generators';
import {
  getRelatedGeneratorsForTurbine
  // resetRelationGeneratorsStateState
} from './store/relation-generators';
import _ from 'lodash';
import EntityStateIndicator from '../shared/entity-state/EntityStateIndicator';
import { fromTurbineId } from '../../../domain/shared/ObjectId';
import EditWarnings from '../shared/edit-errors/EditWarnings';

const EditTurbine = ({ newMode, setLoadForms }: Props) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams();
  const turbineId = Number(id);
  const { changesetId } = useParams();
  const [searchParams] = useSearchParams();
  const { turbine, turbineOriginal, eTag, editMode, loading, initError, error, warnings } = useAppSelector((state) => state.editTurbineData);
  const { loading: loadingLogPosts, logPosts, logPostsAreInitialized, error: errorLogPosts } = useAppSelector((state) => state.logData);
  const { changesetDetails, error: errorChangesetDetails } = useAppSelector((state) => state.changesetDetailsData);
  const { loading: loadingProductParts, productParts, productPartsOriginal, productPartsAreInitialized, error: errorProductPart } = useAppSelector((state) => state.relationshipProductsData);
  const { loading: loadingGearboxes, gearboxes, gearboxesOriginal, gearboxesAreInitialized, error: errorGearboxes } = useAppSelector((state) => state.relationGearboxesData);
  const { loading: loadingGenerators, generators, generatorsOriginal, generatorsAreInitialized, error: errorGenerators } = useAppSelector((state) => state.relationGeneratorsData);
  const { loading: loadingTurbines, turbines, turbinesOriginal, turbinesAreInitialized, error: errorTurbines } = useAppSelector((state) => state.relationTurbinesData);
  const changesets = useAppSelector((state) => state.changesetData.changesets);
  const [formError, setFormError] = useState<Map<string, string>>(new Map());
  const currentEtag = useAppSelector((state) => state.changesetData.currentETag) || '';

  useEffect(() => {
    dispatch(resetTurbineState());
    dispatch(resetRelationGearboxesState());
    dispatch(resetRelationGeneratorsStateState());
    dispatch(resetLogState());
    dispatch(resetProductPartsState());
    dispatch(resetGearboxesState());
    dispatch(resetGeneratorsState());
    dispatch(resetPositionsState());
    updateChangesetDetails(changesetId);
    if (!newMode) {
      dispatch(getTurbine({ turbineId, changesetId: changesetId }));
    }
  }, [turbineId, changesetId]);

  useEffect(() => {
    if (newMode) {
      dispatch(setEditMode({ editMode: true }));
    } else {
      dispatch(setEditMode({ editMode: !!searchParams.get('editMode') }));
    }
  }, [searchParams]);

  useEffect(() => {
    if (!!error) handleError(error);
    if (!!errorLogPosts) handleError(errorLogPosts);
    if (!!errorChangesetDetails) handleError(errorChangesetDetails);
    if (!!errorProductPart) handleError(errorProductPart);
    if (!!errorGearboxes) handleError(errorGearboxes);
  }, [error, errorLogPosts, errorChangesetDetails, errorProductPart, errorGearboxes, errorGenerators]);

  const handleError = (message: string) => {
    addToast({ children: message, feSeverity: 'error' });
    console.log(message);
  };

  if (loading.turbine) {
    return <Loader className="mr-auto ml-auto" />;
  } else if (initError || !turbine) {
    return <p className="p-5">{intl.formatMessage({ id: 'editTurbinePage.error' })}</p>;
  }

  const isAutoCommit = (): boolean => (newMode ? false : !changesetId);

  const handleTurbineFormChange = (value: Turbine) => {
    setFormError((prev) => SetRequiredError(value.turbineName, 'turbineName', prev, intl));
    setFormError((prev) => SetRequiredError(value.driveType, 'driveType', prev, intl));
    setFormError((prev) => SetRequiredError(value.powerOutput, 'powerOutput', prev, intl));
    setFormError((prev) => SetRequiredError(value.manufacturerId, 'manufacturerName', prev, intl));

    setFormError((prev) => SetNumberFieldErrors(value.powerOutput, 'powerOutput', Number.MAX_SAFE_INTEGER, prev, intl));
    setFormError((prev) => SetNumberFieldErrors(value.rotorDiameter, 'rotorDiameter', Number.MAX_SAFE_INTEGER, prev, intl));

    dispatch(setTurbine({ turbine: value }));
  };

  // TODO: Make generic -> see redux refactoring branch. We should have a more generic approach
  // TODO: use same concept of ObjectId as in backend for example so we don't have to maintain
  // TODO: so much duplicate code...
  const onTicketActionClick = async (changesetId: string, action: 'edit' | 'open') => {
    let currentChangesetId: string | undefined = changesetId;
    if (action === 'edit') {
      currentChangesetId = await dispatch(editInTicket({ entityId: turbine.turbineId, turbineName: turbine.turbineName ?? '', changesetId, etag: '' })).unwrap();
    }
    navigate(GetEntityUrl(fromTurbineId(turbine.turbineId), currentChangesetId, action === 'edit'));
  };

  const onSave = async (event: any) => {
    const turbineDiff = ObjectComparison(turbine, turbineOriginal);
    const [addObjectDetails, updateObjectDetails, removeObjectDetails] = ProductPartsComparison(productParts, productPartsOriginal);
    const [addGearboxDetails, updateGearboxDetails, removeGearboxDetails] = GearboxShareDetailsComparison(gearboxes, gearboxesOriginal);
    const [addGeneratorDetails, updateGeneratorDetails, removeGeneratorDetails] = GeneratorShareDetailsComparison(generators, generatorsOriginal);
    const response = await dispatch(
      saveChanges({
        entityId: turbine.turbineId,
        changesetId,
        turbineDiff,
        additionalChanges: { addObjectDetails, updateObjectDetails, removeObjectDetails, addGearboxDetails, updateGearboxDetails, removeGearboxDetails, addGeneratorDetails, updateGeneratorDetails, removeGeneratorDetails },
        isAutoCommit: isAutoCommit(),
        etag: newMode && changesetId ? currentEtag : eTag,
        newMode
      })
    ).unwrap();
    dispatch(setProductPartsMetadataStateAsSaved());
    if (response?.status === 'ok') {
      addToast({ children: 'Turbine is saved successfully', feSeverity: 'success' });
      const redirectUrl = isAutoCommit() ? `/turbine/${response.entityId}` : `/turbine/${response.entityId}/${response.changesetId}`;
      navigate(redirectUrl);
      dispatch(getRelatedGearboxesForTurbine({ turbineId: response.entityId, changesetId }));
    }
  };

  const updateChangesetDetails = (changesetId: string | undefined) => {
    if (changesetId) {
      dispatch(getChangesetDetails({ changesetId }));
    } else {
      dispatch(resetChangesetDetailsState());
    }
  };

  const onDelete = () => dispatch(deleteTurbine({ changesetId, turbineId: turbine.turbineId, eTag }));

  const onCancelAdd = () => {
    setLoadForms(false);
  };

  const onClose = (event: any) => {
    navigate(GetEntityUrl(fromTurbineId(turbine.turbineId)));
  };

  return (
    <>
      <EditWarnings entityId={turbineId} warnings={warnings} closeWarning={(warning) => dispatch(resetWarning(warning))} />
      <Card feNoPadding>
        <div className="relative">
          <RemovedOverlay show={changesetDetails?.refs.find((x) => x.id === turbine.turbineId)?.state === State.DELETED} />
          <div className={!changesetId ? 'p-8' : ''}>
            <Header changesetId={changesetId} objectId={fromTurbineId(turbine.turbineId)}></Header>
            <div className={changesetId ? 'p-8' : ''}>
              <div className="flex pl-2">
                <EntityStateIndicator state={turbine?.metadata?.state} />
                <Heading as="h1">
                  {`${intl.formatMessage({ id: GetTranslationIdForEntityCategory('Turbine') }).toUpperCase()}`} {turbine.turbineName ? `- ${turbine.turbineName}` : <></>}
                </Heading>
              </div>
              <Spacer />
              <TurbineForm editMode={editMode} formError={formError} turbine={turbine} onTurbineChange={handleTurbineFormChange} changesetDetails={changesetDetails} />
              <Tabs
                onClick={(_, index) => {
                  if ((index === 1 || index === 2 || index === 3 || index === 4 || index === 5) && !productPartsAreInitialized && !newMode) dispatch(getRelatedComponents({ type: 'Turbine', id: turbine.turbineId, changesetId }));
                  if (index === 2 && !gearboxesAreInitialized && !newMode) dispatch(getRelatedGearboxesForTurbine({ turbineId: turbine.turbineId, changesetId: changesetId }));
                  if (index === 3 && !generatorsAreInitialized && !newMode) dispatch(getRelatedGeneratorsForTurbine({ turbineId: turbine.turbineId, changesetId: changesetId }));
                  if (index === 6 && !logPostsAreInitialized && !newMode) dispatch(getLog({ type: 'Turbine', id: turbine.turbineId }));
                }}
                className="mt-2 mb-4"
                feItems={[
                  {
                    children: <DescriptionForm turbine={turbine} editMode={editMode} onTurbineChange={handleTurbineFormChange} formError={formError} changesetDetails={changesetDetails} />,
                    id: '0',
                    label: `${intl.formatMessage({ id: 'editTurbine.tab.description' }).toUpperCase()}${GetHasChangesInDetails(turbine.turbineId, changesetDetails) ? ` *` : ''}`
                  },
                  {
                    children: (
                      <>
                        {!!loadingProductParts ? (
                          <Loader />
                        ) : (
                          <>
                            <ListOfParts
                              entityId={turbine.turbineId}
                              changesetDetails={changesetDetails}
                              productCategory={ProductCategory.BEARING}
                              recordCategory={RecordCategory.DriveBearing}
                              positionCategory={PositionsCategory.MAIN_SHAFT}
                              relations={productParts}
                              editMode={editMode}
                              onPartChange={(value: RelationshipProduct, index: number, action: 'Add' | 'Edit' | 'Delete') => dispatch(setProductParts({ value, index, action }))}
                            ></ListOfParts>
                            <ListOfParts
                              entityId={turbine.turbineId}
                              changesetDetails={changesetDetails}
                              productCategory={ProductCategory.SEAL}
                              recordCategory={RecordCategory.DriveSeal}
                              positionCategory={PositionsCategory.MAIN_SHAFT_SEAL}
                              relations={productParts}
                              editMode={editMode}
                              onPartChange={(value: RelationshipProduct, index: number, action: 'Add' | 'Edit' | 'Delete') => dispatch(setProductParts({ value, index, action }))}
                            ></ListOfParts>
                            <ListOfParts
                              entityId={turbine.turbineId}
                              changesetDetails={changesetDetails}
                              productCategory={ProductCategory.GREASE}
                              recordCategory={RecordCategory.DriveGrease}
                              positionCategory={PositionsCategory.MAIN_SHAFT}
                              relations={productParts}
                              editMode={editMode}
                              onPartChange={(value: RelationshipProduct, index: number, action: 'Add' | 'Edit' | 'Delete') => dispatch(setProductParts({ value, index, action }))}
                            ></ListOfParts>
                            <ListOfParts
                              entityId={turbine.turbineId}
                              changesetDetails={changesetDetails}
                              productCategory={ProductCategory.LUBRICATION_SYSTEM}
                              recordCategory={RecordCategory.DriveLubricationSystem}
                              positionCategory={PositionsCategory.MAIN_SHAFT}
                              relations={productParts}
                              editMode={editMode}
                              onPartChange={(value: RelationshipProduct, index: number, action: 'Add' | 'Edit' | 'Delete') => dispatch(setProductParts({ value, index, action }))}
                            ></ListOfParts>
                            <ListOfParts
                              entityId={turbine.turbineId}
                              changesetDetails={changesetDetails}
                              productCategory={ProductCategory.HOUSING}
                              recordCategory={RecordCategory.DriveHousing}
                              positionCategory={PositionsCategory.MAIN_SHAFT}
                              relations={productParts}
                              editMode={editMode}
                              onPartChange={(value: RelationshipProduct, index: number, action: 'Add' | 'Edit' | 'Delete') => dispatch(setProductParts({ value, index, action }))}
                            ></ListOfParts>
                          </>
                        )}
                      </>
                    ),
                    id: '1',
                    label: `${intl.formatMessage({ id: 'editTurbine.tab.mainShaft' }).toUpperCase()}${
                      GetHasChangesOnParts(turbine.turbineId, [RecordCategory.DriveBearing, RecordCategory.DriveSeal, RecordCategory.DriveGrease, RecordCategory.DriveLubricationSystem, RecordCategory.DriveHousing], changesetDetails)
                        ? ` *`
                        : ''
                    }`
                  },
                  {
                    children: (
                      <>
                        {!!loadingGearboxes || !!loadingProductParts ? (
                          <Loader />
                        ) : (
                          <>
                            <GearboxParts
                              relations={gearboxes}
                              editMode={editMode}
                              entityId={turbineId}
                              onGearboxChange={(value: RelationGearbox, index: number, action: 'Add' | 'Edit' | 'Delete') => dispatch(setGearboxes({ value, index, action }))}
                            />
                            <ListOfParts
                              entityId={turbine.turbineId}
                              changesetDetails={changesetDetails}
                              productCategory={ProductCategory.COUPLING}
                              recordCategory={RecordCategory.GearboxCoupling}
                              positionCategory={PositionsCategory.GEARBOX}
                              relations={productParts}
                              editMode={editMode}
                              onPartChange={(value: RelationshipProduct, index: number, action: 'Add' | 'Edit' | 'Delete') => dispatch(setProductParts({ value, index, action }))}
                            ></ListOfParts>
                          </>
                        )}
                      </>
                    ),
                    id: '2',
                    label: `${intl.formatMessage({ id: 'editTurbine.tab.gearbox' }).toUpperCase()}${GetHasChangesOnParts(turbine.turbineId, [RecordCategory.GearboxCoupling], changesetDetails) ? ` *` : ''}`
                  },
                  {
                    children: (
                      <>
                        {!!loadingGenerators || loadingProductParts ? (
                          <Loader />
                        ) : (
                          <>
                            <GeneratorParts
                              relations={generators}
                              editMode={editMode}
                              entityId={turbineId}
                              onGeneratorChange={(value: RelationGenerator, index: number, action: 'Add' | 'Edit' | 'Delete') => dispatch(setGenerators({ value, index, action }))}
                            />
                            <ListOfParts
                              entityId={turbine.turbineId}
                              changesetDetails={changesetDetails}
                              productCategory={ProductCategory.LUBRICATION_SYSTEM}
                              recordCategory={RecordCategory.GeneratorLubricationSystem}
                              positionCategory={PositionsCategory.GENERATOR}
                              relations={productParts}
                              editMode={editMode}
                              onPartChange={(value: RelationshipProduct, index: number, action: 'Add' | 'Edit' | 'Delete') => dispatch(setProductParts({ value, index, action }))}
                            ></ListOfParts>
                          </>
                        )}
                      </>
                    ),
                    id: '3',
                    label: `${intl.formatMessage({ id: 'editTurbine.tab.generator' }).toUpperCase()}${GetHasChangesOnParts(turbine.turbineId, [RecordCategory.GeneratorLubricationSystem], changesetDetails) ? ` *` : ''}`
                  },
                  {
                    children: (
                      <>
                        {!!loadingProductParts ? (
                          <Loader />
                        ) : (
                          <>
                            <ListOfParts
                              entityId={turbine.turbineId}
                              changesetDetails={changesetDetails}
                              productCategory={ProductCategory.BEARING}
                              recordCategory={RecordCategory.RotorBearing}
                              positionCategory={PositionsCategory.PITCH}
                              relations={productParts}
                              editMode={editMode}
                              onPartChange={(value: RelationshipProduct, index: number, action: 'Add' | 'Edit' | 'Delete') => dispatch(setProductParts({ value, index, action }))}
                            ></ListOfParts>
                            <ListOfParts
                              entityId={turbine.turbineId}
                              changesetDetails={changesetDetails}
                              productCategory={ProductCategory.SEAL}
                              recordCategory={RecordCategory.RotorSeal}
                              positionCategory={PositionsCategory.PITCH}
                              relations={productParts}
                              editMode={editMode}
                              onPartChange={(value: RelationshipProduct, index: number, action: 'Add' | 'Edit' | 'Delete') => dispatch(setProductParts({ value, index, action }))}
                            ></ListOfParts>
                            <ListOfParts
                              entityId={turbine.turbineId}
                              changesetDetails={changesetDetails}
                              productCategory={ProductCategory.GREASE}
                              recordCategory={RecordCategory.RotorGrease}
                              positionCategory={PositionsCategory.PITCH}
                              relations={productParts}
                              editMode={editMode}
                              onPartChange={(value: RelationshipProduct, index: number, action: 'Add' | 'Edit' | 'Delete') => dispatch(setProductParts({ value, index, action }))}
                            ></ListOfParts>
                            <ListOfParts
                              entityId={turbine.turbineId}
                              changesetDetails={changesetDetails}
                              productCategory={ProductCategory.LUBRICATION_SYSTEM}
                              recordCategory={RecordCategory.RotorLubricationSystem}
                              positionCategory={PositionsCategory.PITCH}
                              relations={productParts}
                              editMode={editMode}
                              onPartChange={(value: RelationshipProduct, index: number, action: 'Add' | 'Edit' | 'Delete') => dispatch(setProductParts({ value, index, action }))}
                            ></ListOfParts>
                          </>
                        )}
                      </>
                    ),
                    id: '4',
                    label: `${intl.formatMessage({ id: 'editTurbine.tab.rotor' }).toUpperCase()}${
                      GetHasChangesOnParts(turbine.turbineId, [RecordCategory.RotorBearing, RecordCategory.RotorSeal, RecordCategory.RotorGrease, RecordCategory.RotorLubricationSystem], changesetDetails) ? ` *` : ''
                    }`
                  },
                  {
                    children: (
                      <>
                        {!!loadingProductParts ? (
                          <Loader />
                        ) : (
                          <>
                            <ListOfParts
                              entityId={turbine.turbineId}
                              changesetDetails={changesetDetails}
                              productCategory={ProductCategory.BEARING}
                              recordCategory={RecordCategory.TowerBearing}
                              positionCategory={PositionsCategory.YAW}
                              relations={productParts}
                              editMode={editMode}
                              onPartChange={(value: RelationshipProduct, index: number, action: 'Add' | 'Edit' | 'Delete') => dispatch(setProductParts({ value, index, action }))}
                            ></ListOfParts>
                            <ListOfParts
                              entityId={turbine.turbineId}
                              changesetDetails={changesetDetails}
                              productCategory={ProductCategory.SEAL}
                              recordCategory={RecordCategory.TowerSeal}
                              positionCategory={PositionsCategory.YAW}
                              relations={productParts}
                              editMode={editMode}
                              onPartChange={(value: RelationshipProduct, index: number, action: 'Add' | 'Edit' | 'Delete') => dispatch(setProductParts({ value, index, action }))}
                            ></ListOfParts>
                            <ListOfParts
                              entityId={turbine.turbineId}
                              changesetDetails={changesetDetails}
                              productCategory={ProductCategory.GREASE}
                              recordCategory={RecordCategory.TowerGrease}
                              positionCategory={PositionsCategory.YAW}
                              relations={productParts}
                              editMode={editMode}
                              onPartChange={(value: RelationshipProduct, index: number, action: 'Add' | 'Edit' | 'Delete') => dispatch(setProductParts({ value, index, action }))}
                            ></ListOfParts>
                            <ListOfParts
                              entityId={turbine.turbineId}
                              changesetDetails={changesetDetails}
                              productCategory={ProductCategory.LUBRICATION_SYSTEM}
                              recordCategory={RecordCategory.TowerLubricationSystem}
                              positionCategory={PositionsCategory.YAW}
                              relations={productParts}
                              editMode={editMode}
                              onPartChange={(value: RelationshipProduct, index: number, action: 'Add' | 'Edit' | 'Delete') => dispatch(setProductParts({ value, index, action }))}
                            ></ListOfParts>
                          </>
                        )}
                      </>
                    ),
                    id: '5',
                    label: `${intl.formatMessage({ id: 'editTurbine.tab.tower' }).toUpperCase()}${
                      GetHasChangesOnParts(turbine.turbineId, [RecordCategory.TowerBearing, RecordCategory.TowerSeal, RecordCategory.TowerGrease, RecordCategory.TowerLubricationSystem], changesetDetails) ? ` *` : ''
                    }`
                  },
                  // Currently not fully implemented.
                  // {
                  //   children: (
                  //     <PositionSetup positions={positions} editMode={editMode} onPositionChange={(value: Position, index: number, action: 'Add' | 'Edit' | 'Delete') => dispatch(setPositions({ value, index, action }))}></PositionSetup>
                  //   ),
                  //   id: '6',
                  //   label: `${intl.formatMessage({ id: 'editTurbine.tab.positionSetup' }).toUpperCase()}`
                  // },
                  {
                    id: '7',
                    children: <LogPosts loading={loadingLogPosts} logPosts={logPosts} />,
                    label: intl.formatMessage({ id: 'log.tab' }).toUpperCase()
                  }
                ]}
                feType="expanded"
              />
              <ExistsInChangesetInfo isEditingInTicket={!!changesetId} changesets={changesets} entityId={turbine.turbineId} />
              <FormActions
                entityId={turbine.turbineId}
                editMode={editMode}
                formError={formError}
                loading={{ delete: loading.delete, editInTicket: loading.editInTicket, save: loading.save }}
                formHasChanges={!(_.isEqual(turbine, turbineOriginal) && _.isEqual(productParts, productPartsOriginal) && _.isEqual(gearboxes, gearboxesOriginal) && _.isEqual(generators, generatorsOriginal))}
                changesetId={changesetId}
                onTicketActionClick={onTicketActionClick}
                onEditClick={() => dispatch(setEditMode({ editMode: true }))}
                onSaveClick={onSave}
                onCloseChangesetClick={onClose}
                onDeleteClick={onDelete}
                onCancelClick={() => {
                  dispatch(setEditMode({ editMode: false }));
                  dispatch(setTurbine({ turbine: { ...turbineOriginal } }));
                  dispatch(resetProductParts());
                  dispatch(resetGearboxes());
                  dispatch(resetGenerators());
                  dispatch(resetPositionsState());
                }}
                newMode={newMode}
                onCancelAdd={onCancelAdd}
              />
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

export default EditTurbine;

interface Props {
  newMode?: boolean;
  setLoadForms?: any;
}
