import { BaseSeverity } from '@skf/ferris/dist/esm/common/types/severity';
import ComboBox from 'components/form/ComboBox';
import { ComboBoxItem } from 'components/form/ComboBox/ComboBoxItem';
import { ObjectType } from 'domain/shared/ObjectId';
import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { GetSelectOptions } from 'services/api';

const getComboBoxItem = (label?: string, value?: number): ComboBoxItem<{ label: string; value: number }> | null => {
  if (value) {
    return {
      label: !!label ? label : value?.toString(),
      value: value.toString(),
      item: {
        label: !!label ? label : value?.toString(),
        value: value
      }
    } as ComboBoxItem<{ label: string; value: number }>;
  }
  return null;
};

const ManufacturerSelect = ({
  objectType,
  labelId,
  onChange,
  editMode,
  initialValue,
  value,
  hint,
  severity,
  required
}: {
  objectType: ObjectType;
  labelId: string;
  onChange: (value: number, label: string) => void;
  editMode: boolean;
  initialValue?: string;
  value?: number;
  hint?: string;
  severity?: BaseSeverity;
  required?: boolean;
}) => {
  const intl = useIntl();
  const [allOptions, setAllOptions] = useState([{ label: !!initialValue ? initialValue : value?.toString(), value: value }]);
  const [filteredOptions, setFilteredOptions] = useState([{ label: !!initialValue ? initialValue : value?.toString(), value: value }]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [selectedItem, setSelectedItem] = useState<ComboBoxItem<{ label: string; value: number }> | null>(getComboBoxItem(initialValue, value));

  useEffect(() => {
    if (editMode) {
      let isSubscribed = true;
      setIsLoading(true);

      const fetch = async () => {
        const result = await GetSelectOptions('manufacturerId', objectType, {});
        if (isSubscribed) {
          setAllOptions(result.data.map((o) => ({ label: o.label, value: parseInt(o.value) })));
          setFilteredOptions(result.data.map((o) => ({ label: o.label, value: parseInt(o.value) })));
          setIsLoading(false);
        }
      };

      fetch().catch((err) => setIsError(true));

      return () => {
        isSubscribed = false;
      };
    }
  }, [editMode]);

  const onItemSelected = (item: ComboBoxItem<{ label: string; value: number }> | null | undefined): void => {
    if (item) {
      onChange(item.item.value, item.label);
      setSelectedItem(item);
    }
  };

  const onSearch = (value?: string | undefined) => {
    if (value) {
      setFilteredOptions(allOptions.filter((o) => o.label?.toLocaleLowerCase()?.startsWith(value.toLocaleLowerCase())));
    } else {
      setFilteredOptions(allOptions);
    }
  };

  const onReset = () => {
    setFilteredOptions(allOptions);
  };

  return (
    <ComboBox<{ label: string; value: number }>
      className="w-full"
      items={filteredOptions.map((o) => ({ label: o.label, item: o, value: o.value?.toString() } as ComboBoxItem<{ label: string; value: number }>))}
      label={intl.formatMessage({ id: labelId })}
      isError={isError}
      isLoading={isLoading}
      onItemSelected={onItemSelected}
      onSearch={onSearch}
      onReset={onReset}
      disabled={!editMode}
      required={required}
      hint={hint}
      disableWidth
      currentItem={selectedItem}
      severity={severity}
    />
  );
};

export default ManufacturerSelect;
