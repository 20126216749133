import { ValueFilter } from 'features/search/domain/SearchQuery';
import { ValueFacet } from 'features/search/domain/SearchResult';
import { useFilterLabel } from 'features/search/hooks/useFilterLabel';
import useValueTranslation from 'features/search/hooks/useValueTranslation';
import { removeKeywordValue, setKeywordValue } from 'features/search/store';
import { useAppDispatch } from 'store';
import { SearchFacetProps } from '../SearchFilter';
import FilterCollapse from './FilterCollapse';
import SwitchFilter from './SwitchFilter';

const SearchValueFacet = ({ facet, activeFacet }: SearchFacetProps<ValueFacet, ValueFilter>) => {
  const dispatch = useAppDispatch();
  const label = useFilterLabel(facet.field);
  const translateValue = useValueTranslation(facet.field);

  const values = (activeFacet ? activeFacet.facet.values : facet.values).map((v) => ({ ...v, label: translateValue(v.value) }));

  const onSelectKeyword = (value: string, checked: boolean) => {
    if (checked) {
      dispatch(setKeywordValue({ facet: activeFacet ? activeFacet.facet : facet, value }));
    } else {
      dispatch(removeKeywordValue({ facet: activeFacet ? activeFacet.facet : facet, value }));
    }
  };

  return (
    <FilterCollapse hide={facet.values.length === 0 && !activeFacet} defaultExpanded={activeFacet ? true : false} label={label}>
      <SwitchFilter values={values} isSelected={(value) => facet.selected === value} onSelect={onSelectKeyword} />
    </FilterCollapse>
  );
};

export default SearchValueFacet;
