import { ApiServiceBase } from 'services/api/service.base';
import { ChangesetSearchQuery } from '../domain/ChangesetSearchQuery';
import { ChangesetSearchResult } from '../domain/ChangesetSearchResult';

export const getChangesetSearchService = (): ChangesetSearchService => {
  return new ChangesetSearchService(process.env.REACT_APP_CHANGESETSEARCH_API!, process.env.REACT_APP_CHANGESETSEARCH_API_KEY!);
};
export class ChangesetSearchService extends ApiServiceBase {
  async query(query: ChangesetSearchQuery): Promise<ChangesetSearchResult> {
    const client = await this.getClient();
    const response = await client.post('query', query);
    return response.data as ChangesetSearchResult;
  }

  async suggest(query: string): Promise<string[]> {
    const client = await this.getClient();
    const response = await client.get(`suggest?query=${query}`);
    return response.data as string[];
  }
}
